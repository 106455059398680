export const TEST_DATA = `rs9712
rs6357
rs10488682
rs772897
rs2293054
rs841
rs1060826
rs1060822
rs16966563
rs681553
rs4680
rs2597772
rs11575457
rs11575456
rs59827423
rs112209590
rs6264
rs7830
rs77905
rs129882
rs6323`;