import React, { useCallback, useEffect, useState } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton
}
from '@mui/material';

import AddTaskIcon from '@mui/icons-material/AddTask';

import { compareNames } from './medicinesData';

const DEFAULT_WARNING = 'Or Select in List';

const CustomForm = (props) => {
  const { items, onRequested, onEntered } = props;
  const [warning, setWarning] = useState(DEFAULT_WARNING);
  const [inputValue, setInputValue] = useState('');

  const onEnter = useCallback((name) => {
    if (name.trim() === '') {
      setWarning('Enter Medicine name');
      return;
    }

    if (items.find((item) => compareNames(item.name, name))) {
      setWarning('This Medicine name already presents');
      return;
    }

    onEntered({
      _id: null,
      name
    });

    setWarning(DEFAULT_WARNING);
    setInputValue('');
  }, [items, onEntered]);

  const onChange = useCallback((event) => {
    setInputValue(event.target.value);
    setWarning(DEFAULT_WARNING);
  }, []);

  useEffect(() => {
    onRequested();
  }, [onRequested]);

  return (
    <>
      <TextField
        fullWidth
        className="mt-05"
        label="Enter Medicine Title Here"
        name="customTitle"
        value={inputValue}
        onChange={onChange}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            onEnter(inputValue);
          }
        }}
        onBlur={() => {}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => onEnter(inputValue)}>
                <AddTaskIcon />
              </IconButton>  
            </InputAdornment>
          )
        }}
      />
      <div className="help my-05">{warning}</div>
    </>
  );
};

export default CustomForm;