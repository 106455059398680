import React from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';

import Layout from './Layout';


const ConstructionPage = () => {
  return (
    <Layout>
      <h3 style={{ textAlign: 'center' }}>
        <ConstructionIcon /><br />
        The page is under construction.
      </h3>
    </Layout>
  );
}

export default ConstructionPage;
