import React, { useCallback, useEffect } from 'react';
import { Alert, Box, Button, Grid, Divider, Zoom } from '@mui/material';
import { useNavigate } from 'react-router';

import { STORE } from '../../store';
import { saveSurveyResult } from '../../services/survey';
import { useAsyncAppState } from '../../hooks/useAsyncAppState';
import { useAppState } from '../../hooks/useAppState';

import Layout from '../layout/Layout';
import LoaderWrapper from '../common/LoaderWrapper';

import './survey.css';

const SurveyCompletePage = () => {
  const navigate = useNavigate();
  const [surveyComplete, setSurveyComplete] = useAppState(
    STORE.areas.survey.items.complete
  );

  const [surveySave, saveSurveyAsync] = useAsyncAppState(
    STORE.areas.survey.save,
    saveSurveyResult
  );

  const [, setSelectedCategory] = useAppState(
    STORE.areas.survey.category.select
  );

  useEffect(() => {
    setSelectedCategory(null);
    if (surveyComplete) {
      saveSurveyAsync(surveyComplete);
    }
    else {
      navigate('/home');  
    }
  }, [navigate, saveSurveyAsync, surveyComplete]);

  const onExit = useCallback(() => {
    setSurveyComplete(null);
    navigate('/home');
  }, [navigate, setSurveyComplete]);

  return (
    <Layout>
      <LoaderWrapper items={[surveySave]}>
        <Box className="vertical-flex h-100">
          <Box className="vertical-flex-grow">
            <Zoom in>
              <Grid container className="justify-content-center pb-1">
                <Grid item xs={12} md={7} lg={5}>
                  <Alert className="survey-alert">
                    <b>Your results have been saved!</b>
                  </Alert>

                  {surveyComplete && surveyComplete.message && (
                    <Alert className="survey-alert" severity={surveyComplete.totalText}>
                      <b>{surveyComplete.message}</b>
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </Zoom>
          </Box>

          <Box className="survey-footer">
            <Divider />
              <Grid container className="justify-content-center pb-1">
                <Grid item xs={12} md={5} lg={2}>
                  <Button color="primary" size="large"
                    variant="contained"
                    className="w-100"
                    onClick={onExit}>
                    Back to Home Page
                  </Button>
                </Grid>
              </Grid>
          </Box>
        </Box>
      </LoaderWrapper>
    </Layout>
  );
}

export default SurveyCompletePage;
