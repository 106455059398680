import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Autocomplete,
  TextField
}
from '@mui/material';

import { findBrand } from '../../services/nutrient';
import { debounce } from '@mui/material/utils';

const BrandAutocomplete = (props) => {
  const { onLoadRequest, isSupplement } = props;
  
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const fetch = useMemo(() => debounce(async (mask, callback) => {
    setLoading(true);

    const brands = await findBrand(mask, isSupplement);

    callback(brands);
    setLoading(false);
  }, 400), [setLoading, isSupplement]);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    else {
      fetch(inputValue.trim(), (brands) => {
        if (active) {
          setOptions(brands);
        }
      });
    }
 
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, setInputValue]);

  const onSelectValue = useCallback(async (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    onLoadRequest(newValue ? newValue.name : '');
  }, [options, setValue, setOptions, onLoadRequest]);

  return (
    <Autocomplete
      id="selectBrand"
      fullWidth
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      options={options}
      autoComplete
      loading={loading}
      includeInputInList
      value={value}
      noOptionsText="No Brands found"
      onChange={onSelectValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Select Brand here (optional)" fullWidth />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option._id}>
            {option.name}
          </li>
        );
      }}
    />
  );
};

export default BrandAutocomplete;