import React, { useRef, useEffect } from 'react';

import '../../css/index.scss';
import logo from '../../assets/img/albright-logo.svg';
import classNames from 'classnames';

const MapViewerLayout = (props) => {
  const popupRef = useRef(null);
  const { children, viewUrl } = props;

  useEffect(() => {
    if (popupRef.current) {
      popupRef.current.close();
    }
    if (viewUrl) {
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        width=700,height=1000,left=0,top=0`;
      popupRef.current = window.open(viewUrl, '', params)
    }
  }, [viewUrl]);

  return (
    <div className="container-fluid rs-suite">
      <div className="row">
        <div className={classNames('col viewer-side', { 'open': Boolean(viewUrl) })}>
          
        </div>
        <div className="col">
          <nav className="navbar">
            <a className="navbar-brand" href="/home">
              <img src={logo} alt="AlBright"></img>
            </a>
          </nav>
          <main>
            {children}
          </main>
        </div>
      </div>
    </div>
  );
}

export default MapViewerLayout;
