// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.genemap-table th,
.genemap-table td {
  padding: 0.25rem;
  font-size: 0.8rem;
  border: 1px solid gray;
}

.genemap-table button {
  padding: 0;
  text-align: left;
  justify-content: flex-start;
}

.genemap-table-pdf {
  margin-bottom: 1rem;
  border-collapse: collapse;
}

.genemap-table-pdf th,
.genemap-table-pdf td {
  padding: 0.25rem;
  border: 1px solid gray;
  font-size: 0.6rem;
}

`, "",{"version":3,"sources":["webpack://./src/components/genetics/resolveGenePdf.css"],"names":[],"mappings":"AAAA;;EAEE,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;;EAEE,gBAAgB;EAChB,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":[".genemap-table th,\n.genemap-table td {\n  padding: 0.25rem;\n  font-size: 0.8rem;\n  border: 1px solid gray;\n}\n\n.genemap-table button {\n  padding: 0;\n  text-align: left;\n  justify-content: flex-start;\n}\n\n.genemap-table-pdf {\n  margin-bottom: 1rem;\n  border-collapse: collapse;\n}\n\n.genemap-table-pdf th,\n.genemap-table-pdf td {\n  padding: 0.25rem;\n  border: 1px solid gray;\n  font-size: 0.6rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
