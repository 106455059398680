import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
}
from '@mui/material';
import { MED_TYPE } from './medicinesData';

const ResultsHistoryList = (props) => {
  const { items, onRequested, onSelect } = props;

  useEffect(() => {
    onRequested();
  }, [onRequested]);

  return (
    <TableContainer component={Paper} className="search-result-paper">
      <Table sx={{ minWidth: '20rem' }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow hover key={item.id}>
              <TableCell onClick={() => onSelect(item.medData, item.medType)}>
                <div className="nutrient-title">{item.medType === MED_TYPE.CUSTOM ? 'Custom' : ''}</div>
                <div className="nutrient-label">{item.medData.name}</div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultsHistoryList;