import { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import moment from 'moment';

import { 
  DatePicker,
  IconButton, 
  Button,
  ButtonToolbar,
} from 'rsuite';

import { SYMPTOMS_RANGE_TYPE, getChartData, getChartOptions } from './reportData';
import {
  Chart as ChartJS,
  TimeScale,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

const customShadow = {
  id: 'customShadow',
  beforeDraw: (chart, val) => {
    const ctx = chart.ctx;
    ctx.save();

    const originalLineDraw = ctx.stroke;
    ctx.stroke = function () {
      ctx.save();
      ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 4;
      ctx.shadowOffsetY = 4;
      originalLineDraw.apply(this, arguments);
      ctx.restore();
    };
  }
};

const SymptomChart = (props) => {
  const picker = useRef(null);
  const { 
    selected, 
    colors, 
    symptoms, 
    options, 
    onSelectRangeType,
    onMoveLeft,
    onMoveRight,
    onChangeDaily
  } = props;

  return (
    <div>
      <ButtonToolbar className="ms-2">
        <IconButton 
          style={{ opacity: options.rangeType !== SYMPTOMS_RANGE_TYPE.daily ? 0.5 : 1}}
          disabled={options.rangeType !== SYMPTOMS_RANGE_TYPE.daily}
          circle appearance="default" size="sm" 
          onClick={() => picker.current?.open()}
          icon={<span className="material-icons">calendar_month</span>}>
        </IconButton>

        <DatePicker 
          value={moment(options.from).toDate()}
          className="datepicker-hidden"
          onChangeCalendarDate={onChangeDaily}
          ref={(c) => picker.current = c} />

        {Object.values(SYMPTOMS_RANGE_TYPE)
          .filter((rt) => rt !== SYMPTOMS_RANGE_TYPE.none)
          .map((rt) => (
          <Button key={rt}
            disabled={rt === SYMPTOMS_RANGE_TYPE.lastVisit}
            appearance={rt === options.rangeType ? 'default' : 'ghost'} 
            onClick={() => onSelectRangeType(rt)}
            className="btn-gadget">
            {rt}
          </Button>
        ))}

      </ButtonToolbar>
      <div className="chart-wrapper">
        <div className="chart-dates">
          <span className="material-icons">calendar_month</span>
          {moment(options.from).format('DD MMM')} - {moment(options.to).format('DD MMM')}. {moment(options.to).format('YYYY')}
        </div>

        <Line 
          data={getChartData(selected, colors, symptoms, options)} 
          options={getChartOptions()} 
          plugins={[customShadow]}/>

        <IconButton onClick={onMoveLeft}
          circle appearance="subtle" size="xs" 
          className="btn-time-range-less"
          icon={<span className="material-icons">chevron_left</span>} />

        <IconButton onClick={onMoveRight}
          circle appearance="subtle" size="xs" 
          className="btn-time-range-more"
          icon={<span className="material-icons">chevron_right</span>} />  

      </div>
    </div>
  );
};

export default SymptomChart;