import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

const LoaderWrapper = (props) => {
  const { items, show, children, loading } = props;

  let itemsLoaded = false;
  let itemsLoading = false;
  if (items) {
    itemsLoaded = items.every((item) => item.loaded || item.error);
    itemsLoading = items.some((item) => item.loading);
  }
  
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || itemsLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {itemsLoaded || show ? children : null}
    </>
  );
}

export default LoaderWrapper;
