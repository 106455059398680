import { useState } from 'react';
import classNames from 'classnames';

import {
  Button
} from 'rsuite';

const SYMPTOMS_MAP = {
  'Dental': { label: 'Dental', icon: 'dental' },
  'Digestive': { label: 'Digestive', icon: 'digestive' },
  'Ear, nose, and throat': { label: 'Ear, Nose and Throat', icon: 'ear' },
  'Infectious disease': { label: 'Infectious Disease', icon: 'infection' },
  'Miscellaneous': { label: 'Miscellaneous', icon: 'misc' },
  'Musculoskeletal': { label: 'Musculoskeletal', icon: 'muscul' },
  'Neurology and Cognitive Burden': { label: 'Neurology & Cognitive Burden', icon: 'neurology-cognitive' },
  'Opthamology': { label: 'Opthamology', icon: 'eye' },
  'Reproductive system': { label: 'Reproductive System', icon: 'reproductive' },
  'Skin, hair, and nails': { label: 'Hair, Nails & Skin', icon: 'hair' },
  'Weight management': { label: 'Weight Management', icon: 'weight' },
  'Depression Inventory': { label: 'Depression Inventory', icon: 'depression' },
  'Fatigue Inventory': { label: 'Fatigue Inventory', icon: 'fatigue-inventory' },
  'Circulatory': { label: 'Circulatory', icon: 'heart' },
  'Fatigue': { label: 'Fatigue', icon: 'fatigue' },
  'Neurology': { label: 'Neurology', icon: 'neurology' },
  'Pain': { label: 'Pain', icon: 'pain' },
  'Sleep': { label: 'Sleep', icon: 'sleep' },
};

const MAX_VISIBLE = 6;

const SymptomTiles = ({ categories, selected, onSelect, colors }) => {
  const moreMax = categories.length > MAX_VISIBLE;
  const [allVisible, setAllVisible] = useState(!moreMax);

  return (
    <div>
      {moreMax && <div className="title-help-small pb-2">Select up to 5 to compare</div>}
      <div className="symptom-tiles">
        {(allVisible ? categories : categories.slice(0, MAX_VISIBLE)).map((category) => (
          <Button 
            key={category} 
            className={classNames('symptom-tile', {'selected': selected.indexOf(category) >= 0})}
            onClick={() => onSelect(category, selected)}
            style={{ backgroundColor: colors[category]}}>
            <div>
              <span className={`icon ${SYMPTOMS_MAP[category]?.icon}`} />
              <span className="title">{SYMPTOMS_MAP[category]?.label}</span>
            </div>
          </Button>
        ))}
      </div>

      {moreMax && <Button 
        appearance="default" 
        className="btn-gadget-2" 
        onClick={() => setAllVisible(!allVisible)}>
        {allVisible ? 'Hide all' : 'View all'}
      </Button>}
    </div>
  );
};

export default SymptomTiles;