import React, { useState, useEffect, useMemo } from 'react';
import {
  CircularProgress,
  TextField,
  InputAdornment
}
from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import { debounce } from '@mui/material/utils';

const FoodSearch = (props) => {
  const { loading, onSearchRequested, className } = props;
  const [inputValue, setInputValue] = useState('');

  const fetch = useMemo(() => debounce(async (mask, callback) => {
    callback(mask);
  }, 400), []);

  useEffect(() => {
    let active = true;

    fetch(inputValue.trim(), (mask) => {
      if (active) {
        onSearchRequested(mask);
      }
    });
 
    return () => {
      active = false;
    };
  }, [inputValue, fetch, onSearchRequested]);

  return (
    <TextField
      className={className}
      fullWidth
      label="Start Type Here"
      name="search"
      value={inputValue}
      onChange={(event) => {
        setInputValue(event.target.value);
      }}
      onBlur={() => {}}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: loading && <CircularProgress size="1.5rem" />
      }}
    />
  );
};

export default FoodSearch;