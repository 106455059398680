import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';

import { Box, Button, Divider, Grid, LinearProgress, Zoom } from '@mui/material';

import { sleep } from '../../services/survey';
import { TYPE_YESNO, getInitialResult, getSurveyAnswer } from './surveyData';

let onAnswerResolved = null;
const getAnswer = () => {
  return new Promise((resolve) => {
    onAnswerResolved = resolve;
  });
};

const indexSelected = (index, item) => {
  return item.answer && item.answer.value === item.options[index].value;
};

const processItem = async (data, index, setItem, result) => {
  const question = data[index];
  
  if (!question.options || question.options.length !== 2) {
    return;
  }

  let answerText = 'N/A';
  let answerValue = -1;

  const options = [...question.options].sort((a, b) => b.value > a.value);
  const percentStart = Math.floor(100 * index / data.length);
  const percentEnd = Math.floor(100 * (index + 1) / data.length);

  const item = {
    options,
    percent: percentStart,
    ask: question.ask,
    answer: null,
  };

  setItem(item);

  const answerIndex = await getAnswer();
  if (answerIndex >= 0) {
    const optionIndex = answerIndex === 2 ? 0 : 1;
    answerText = options[optionIndex].text;
    answerValue = options[optionIndex].value;
  }

  const answer = getSurveyAnswer(
    question.ask,
    answerText,
    answerValue
  );

  result.answers.push(answer);

  setItem({
    ...item,
    percent: percentEnd,
    answer
  });

  await sleep(300);
};

const processItems = async (data, setItem, result) => {
  for (let index = 0; index < data.length; index++) {
    await processItem(data, index, setItem, result);
  }
};

let surveySwiper = null;
const SurveyYesNo = (props) => {
  const { category, data, onComplete } = props;
  const [item, setItem] = useState(null);

  const disabled = useMemo(() => item === null || item.answer !== null, [item]);

  const onAnswer = useCallback((index) => {
    if (onAnswerResolved) {
      onAnswerResolved(index);
    }
  }, []);

  const onSkip = useCallback(() => {
    if (onAnswerResolved) {
      onAnswerResolved(-1);
    }
  }, []);

  const onAnswerClick = useCallback((index) => {
    if (surveySwiper) {
      surveySwiper.slideTo(index);
      onAnswer(index);
    }
  }, [onAnswer]);

  const onAnswerSwipe = useCallback(() => {
    if (surveySwiper && typeof(surveySwiper.activeIndex) !== 'undefined') {
      onAnswer(surveySwiper.activeIndex);
    }
  }, [onAnswer]);

  const onSwiper = useCallback((swiper) => {
    surveySwiper = swiper;
  }, []);

  useEffect(() => {
    const runSurvey = async() => {
      const result = getInitialResult(category.name, TYPE_YESNO);
      await processItems(data, setItem, result);
      if (surveySwiper) {
        surveySwiper.destroy(true, true);
        surveySwiper = null;
      }

      onComplete(result);
    };

    runSurvey();
  }, [data, category, setItem, onComplete]);

  useEffect(() => {
    return () => {
      if (surveySwiper) {
        surveySwiper.destroy(true, true);
        surveySwiper = null;
      }
    };
  }, []);

  const asking = item && item.answer === null;

  return (
    <Box className="vertical-flex h-100">
      <Box className="survey-swipe-header">
        <Grid container className="justify-content-center pb-1">
          <Grid item xs={12} md={6} lg={4} xl={2}>
            <Button variant="contained" fullWidth
              disabled={disabled}
              onClick={onSkip}>
              Skip Question
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box className="vertical-flex-grow">
        {asking && (
          <Zoom in={asking}>
            <Grid container className="justify-content-center pb-1 h-100">
              <Grid item xs={12} md={7} lg={5} className="h-100">
                <Swiper
                  className="survey-slider"
                  initialSlide={1}
                  onSlideChange={onAnswerSwipe}
                  onSwiper={onSwiper}>
                  <SwiperSlide className="survey-slide" />
                  <SwiperSlide className="survey-slide">
                    {item && item.ask}
                  </SwiperSlide>
                  <SwiperSlide className="survey-slide" />
                </Swiper>
              </Grid>
            </Grid>
          </Zoom>
        )}
      </Box>

      <Box className="survey-footer">
        {item && <LinearProgress variant="determinate" value={item.percent} />}
        <Divider />

        {item && (
          <Box className="survey-swipe-footer">
            <Button color="info" size="large"
              variant="contained"
              className={classNames('survey-button', indexSelected(0, item) && 'answered')}
              disabled={disabled}
              onClick={() => onAnswerClick(2)}>{item.options[0].text}
            </Button>

            <Button color="primary" size="large"
              variant="contained"
              className={classNames('survey-button', indexSelected(1, item) && 'answered')}
              disabled={disabled}
              onClick={() => onAnswerClick(0)}>{item.options[1].text}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default SurveyYesNo;
