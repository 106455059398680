import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROLES } from '../constants/common';
import { STORE } from '../store';
import { useAppState } from './useAppState';
import { endSession } from '../services/session';

export const useGetRole = (role, logout = false) => {
  const [user] = useAppState(STORE.common.user);
  const navigate = useNavigate();

  const result = useMemo(() => {
    const roles = Array.isArray(role) ? role : [role];
    return user.data && roles.some((role) => user.data.role === role);
  }, [role, user]);

  useEffect(() => {
    if (!result && logout) {
      endSession();
      navigate('/');
    }
  }, [result, navigate, logout]);

  return result;
};

export const useResearcher = (logout = false) => useGetRole(ROLES.RESEARCHER, logout);
export const usePatient = (logout = false) => useGetRole(ROLES.PATIENT, logout);
