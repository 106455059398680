import React, { useRef, useCallback, useEffect, useState } from 'react';
import { 
  Box, 
  Grid, 
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

import { useNavigate } from 'react-router';

import { STORE } from '../../store';
import { useAsyncAppState  } from '../../hooks/useAsyncAppState';
import { resolveGene } from '../../services/genetics';

import Layout from '../layout/Layout';
import LoaderWrapper from '../common/LoaderWrapper';

import { TEST_DATA } from './genData';

const ResolveGenePage = () => {
  const textArea = useRef();
  const [result, setResult] = useState(null);
  const [resolveGeneData, resolveGeneAsync] = useAsyncAppState(
    STORE.areas.genetics.resolve,
    resolveGene
  );

  const navigate = useNavigate();

  const onResolve = useCallback(async () => {
    const snippets = textArea.current.value;
    const codes = snippets.split('\n')
      .map((snippet) => snippet.trim())
      .filter((snippet) => Boolean(snippet.length));

    if (codes.length) {
      const data = await resolveGeneAsync(codes);
      setResult(data);
    }
  }, [setResult, resolveGeneAsync]);
  
  const onExit = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  useEffect(() => {
    textArea.current.value = TEST_DATA;
  }, []);

  return (
    <Layout>
      <LoaderWrapper items={[resolveGeneData]} />
      <Box className="vertical-flex h-100">
        <h3>
          Resolve Gene Links
        </h3>
        <Box className="vertical-flex-grow">
          <Grid container className="justify-content-center pb-1">
            <Grid item xs={12} md={5} lg={2}>
              <Button color="primary" size="large"
                variant="contained"
                className="w-100"
                onClick={onResolve}>
                Resolve
              </Button>
            </Grid>
          </Grid>

          <Grid container className="justify-content-center pb-1">  
            <Grid item xs={12} md={8} lg={6}>
              <textarea ref={textArea} rows={15} className="w-100 mb-10" />

              {result && (<>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: '20rem' }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Snippet</TableCell>
                        <TableCell>Gene Code</TableCell>
                        <TableCell>Gene Name</TableCell>
                        <TableCell>Functional Consequence</TableCell>
                        <TableCell>Inheritance</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {result.geneMap.map((item) => (
                        <TableRow hover key={item.snippet}>
                          <TableCell>
                            {item.snippetCode}
                          </TableCell>
                          <TableCell>
                            {item.geneCode}
                          </TableCell>
                          <TableCell>
                            {item.geneName}
                          </TableCell>
                          <TableCell>
                            {item.geneCons}
                          </TableCell>
                          <TableCell>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider className="my-1"/>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: '20rem' }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Gene Left</TableCell>
                        <TableCell>Gene Right</TableCell>
                        <TableCell>Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {result.geneLinks.map((link) => (
                        <TableRow hover key={`${link.left}${link.right}`}>
                          <TableCell>
                            {link.left}
                          </TableCell>
                          <TableCell>
                            {link.right}
                          </TableCell>
                          <TableCell>
                            {link.score}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                </>)}
            </Grid>
          </Grid>
        </Box>

        <Box className="buttons-footer">
          <Divider />
            <Grid container className="justify-content-center pb-1">
              <Grid item xs={12} md={5} lg={2}>
                <Button color="primary" size="large"
                  variant="contained"
                  className="w-100"
                  onClick={onExit}>
                  Back to Home Page
                </Button>
              </Grid>
            </Grid>
        </Box>
      </Box>
    </Layout>
  );
}

export default ResolveGenePage;
