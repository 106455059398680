import React from 'react';
// import Graph from 'vis-react';

class VisNetwork extends React.Component {
    constructor(props) {
        super(props);
        this.graph = {
            nodes: [
                { id: 1, label: '' , color: '#2C383A' },
                { id: 2, label: '', color: '#CB8921' },
                { id: 3, label: '', color: '#2C383A' },
                { id: 4, label: '' , color: '#2C383A' },
                { id: 5, label: '',  color: '#CB8921'}
            ],
            edges: [
                { from: 1, to: 2 },
                { from: 1, to: 3 },
                { from: 2, to: 4 },
                { from: 2, to: 5 }
            ]
        };

        this.options = {
            layout: {
                randomSeed: 5,
            },
            edges: {
                color: '#000000'
            },
            nodes: {
                size: 10
            },
            interaction: { hoverEdges: true, multiselect: true },
            autoResize: true,
            height: '100%',
            width: '100%',
        };

        this.events = {
            select: function(event) {
                var { nodes, edges } = event;
            }
        };
    }

    render() {
        return (
          <div className="m-4"></div>
            // <Graph
            //     graph={this.graph}
            //     options={this.options}
            //     events={this.events}
            //     //style={style}
            //     getNetwork={this.getNetwork}
            //     getEdges={this.getEdges}
            //     getNodes={this.getNodes}
            //     vis={vis => (this.vis = vis)}
            // />
        );
    }
}

export default VisNetwork;