import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Autocomplete,
  TextField
}
from '@mui/material';

import { autocompleteMedicines } from '../../services/medicines';
import { debounce } from '@mui/material/utils';

const MedicinesAutocomplete = (props) => {
  const { onLoadRequest } = props;
  
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const fetch = useMemo(() => debounce(async (mask, callback) => {
    setLoading(true);

    const options = await autocompleteMedicines(mask);

    callback(options);
    setLoading(false);
  }, 400), [setLoading]);

  useEffect(() => {
    let active = true;
    const newValue = inputValue.trim();

    if (newValue.length < 5) {
      setOptions(value ? [value] : []);
      return undefined;
    }
    else if (newValue !== value) {
      fetch(newValue, (options) => {
        if (active) {
          setOptions(options);
        }
      });
    }
 
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, setInputValue]);

  const onSelectValue = useCallback(async (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    onLoadRequest(newValue ? newValue.value : '');
  }, [options, setValue, setOptions, onLoadRequest]);

  return (
    <Autocomplete
      id="selectMedicine"
      fullWidth
      className="mt-05 mb-1"
      getOptionLabel={(option) => option.value || ''}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={options}
      autoComplete
      loading={loading}
      includeInputInList
      value={value}
      noOptionsText="No Records found"
      onChange={onSelectValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Select Medicine here" fullWidth />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.value}
          </li>
        );
      }}
    />
  );
};

export default MedicinesAutocomplete;