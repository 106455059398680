// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--style-tile-primary3);
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.topbar-button {
  background-color: var(--style-tile-primary3) !important; /* overriding the material ui default color */
}

.menu-divider {
  border-color: white !important;
}

.menu-button {
  color: #CB8921 !important;
}

.toolbar-group {
  color: white;
  display: flex;
  align-items: center;
}

.left-menu-button {
  padding-right: 2rem;
}

.left-menu-button svg {
  margin-right: 1rem;
}

.left-menu-button span {
  font-family: inherit;
}

.drawer .MuiPaper-root {
  border-right: 1px solid white;
  min-width: 17rem;
  box-shadow: 153px -118px 5px -146px rgba(0,0,0,0.31) inset;
  -webkit-box-shadow: 153px -118px 5px -146px rgba(0,0,0,0.31) inset;
  -moz-box-shadow: 153px -118px 5px -146px rgba(0,0,0,0.31) inset;
}`, "",{"version":3,"sources":["webpack://./src/components/topBar/TopBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,4CAA4C;EAC5C,6BAA6B;EAC7B,8BAA8B;AAChC;;AAEA;EACE,uDAAuD,EAAE,6CAA6C;AACxG;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,0DAA0D;EAC1D,kEAAkE;EAClE,+DAA+D;AACjE","sourcesContent":[".topbar-toolbar {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  background-color: var(--style-tile-primary3);\n  padding-left: 1rem !important;\n  padding-right: 1rem !important;\n}\n\n.topbar-button {\n  background-color: var(--style-tile-primary3) !important; /* overriding the material ui default color */\n}\n\n.menu-divider {\n  border-color: white !important;\n}\n\n.menu-button {\n  color: #CB8921 !important;\n}\n\n.toolbar-group {\n  color: white;\n  display: flex;\n  align-items: center;\n}\n\n.left-menu-button {\n  padding-right: 2rem;\n}\n\n.left-menu-button svg {\n  margin-right: 1rem;\n}\n\n.left-menu-button span {\n  font-family: inherit;\n}\n\n.drawer .MuiPaper-root {\n  border-right: 1px solid white;\n  min-width: 17rem;\n  box-shadow: 153px -118px 5px -146px rgba(0,0,0,0.31) inset;\n  -webkit-box-shadow: 153px -118px 5px -146px rgba(0,0,0,0.31) inset;\n  -moz-box-shadow: 153px -118px 5px -146px rgba(0,0,0,0.31) inset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
