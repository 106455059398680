import React, { useRef, useCallback, useState } from 'react';
import {
  Box
}
from '@mui/material';

import { findBrandedFood } from '../../services/nutrient';
import BrandAutocomplete from './BrandAutocomplete';
import FoodSearch from './FoodSearch';

const BrandedFetchForm = (props) => {
  const { onItemsLoaded, isSupplement } = props;
  const [loading, setLoading] = useState(false);
  const paramsRef = useRef({
    mask: '',
    brand: ''
  });

  const load = useCallback(async (mask, brand) => {
    paramsRef.current = { mask, brand };

    setLoading(true);
    try {
      const items = await findBrandedFood(mask, brand, isSupplement);
      onItemsLoaded(items);
      setLoading(false);
    }
    catch (ex) {
      setLoading(false);
      console.log(ex);
    }
  }, [isSupplement, onItemsLoaded]);

  const onSearchRequested = useCallback((mask) => {
    const brand = paramsRef.current.brand;
    load(mask, brand);
  }, [load]);

  const onBrandSelected = useCallback((brand) => {
    const mask = paramsRef.current.mask;
    load(mask, brand);
  }, [load]);

  return (
    <Box>
      <FoodSearch loading={loading} className="mb-1 mt-05"
        onSearchRequested={onSearchRequested} />

      <BrandAutocomplete onLoadRequest={onBrandSelected} 
        isSupplement={isSupplement} />
    </Box>
  );
};

export default BrandedFetchForm;