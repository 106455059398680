import React, { useCallback, useEffect } from 'react';

import moment from 'moment';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import LoaderWrapper from '../common/LoaderWrapper';

import { STORE } from '../../store';
import { DATE_FORMAT } from '../../constants/common';
import { useAsyncAppState } from '../../hooks/useAsyncAppState';
import { findProfiles } from '../../services/userProfile';
import { loadFiles, deleteFile } from '../../services/upload';

import ProfileFindForm from './ProfileFindForm';
import UploadDocsForm from './UploadDocsForm';

const ProfileDocsResearcher = () => {
  const [profiles, findProfilesAsync] = useAsyncAppState(STORE.areas.profile.find, findProfiles);
  const [files, loadFilesAsync] = useAsyncAppState(STORE.areas.documents.load, loadFiles);
  const [deletingFile, deleteFileAsync] = useAsyncAppState(STORE.areas.documents.delete, deleteFile);

  const onFind = useCallback((values) => {
    findProfilesAsync(values);
  }, [findProfilesAsync]);

  const onRefresh = useCallback(() => {
    loadFilesAsync();
  }, [loadFilesAsync]);

  useEffect(() => {
    onRefresh();
  }, [onRefresh]);

  const onDeleteDocument = useCallback(async (fileId) => {
    await deleteFileAsync(fileId);
    onRefresh();
  }, [onRefresh, deleteFileAsync]);

  return (
    <LoaderWrapper items={[profiles, files, deletingFile]} show>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <h4>1. Find a Profile</h4>
          <ProfileFindForm onFind={onFind} />
          {profiles.data && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: '20rem' }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>DOB</TableCell>
                    <TableCell>Gender</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {profiles.data.map((profile) => (
                    <TableRow key={profile._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>
                        <Button>
                          {profile.fullName}
                        </Button>
                      </TableCell>
                      <TableCell>{profile.userEmail}</TableCell>
                      <TableCell>{profile.dob ? moment(profile.dob).format(DATE_FORMAT) : ''}</TableCell>
                      <TableCell>{profile.gender}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>)}

          <h4>2. Upload Pdf document(s) for selected profile</h4>
          <UploadDocsForm onRefresh={onRefresh} />
        </Grid>

        <Grid item xs={12} lg={6}>
          <h4>3. Manage document(s) for selected profile</h4>
          {files.data && files.data.length > 0 && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: '20rem' }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Uploaded On</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.data.map((file) => (
                    <TableRow key={file.databaseFilename} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>
                        <Button>
                          {file.originalFilename}
                        </Button>
                      </TableCell>
                      <TableCell>{file.fileSize ? `${Math.round(file.fileSize * 100 / (1024 * 1024)) / 100}Mb` : ''}</TableCell>
                      <TableCell>{file.uploadDate ? moment(file.uploadDate).format(DATE_FORMAT) : ''}</TableCell>
                      <TableCell>
                      <IconButton onClick={(e) => onDeleteDocument(file._id)}>
                        <CloseIcon />
                      </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>)}
        </Grid>
      </Grid>
    </LoaderWrapper>
  );
}

export default ProfileDocsResearcher;
