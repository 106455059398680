import { useEffect } from 'react';

import { STORE } from '../../../store';
import { loadReportData } from '../../../services/medReport';
import { useAsyncAppState  } from '../../../hooks/useAsyncAppState';
import LoaderWrapper from '../../../components/common/LoaderWrapper';

import Layout from '../../layout/Layout';
import ReportHeader from './ReportHeader';
import PatientDetails from './PatientDetails';
import Symptoms from './Symptoms';
import Genetics from './Genetics';
import Medication from './Medication';
import Supplements from './Supplements';
import './report.scss';

const MedicalReportPage = () => {
  const [reportData, loadReportDataAsync] = useAsyncAppState(
    STORE.areas.medReport.load,
    loadReportData
  );

  useEffect(() => {
    loadReportDataAsync('val.martinchik.albright@gmail.com');
  }, [loadReportDataAsync]);

  return (
    <Layout className="rs-suite">
      <LoaderWrapper items={[reportData]}>
        <ReportHeader data={reportData.data} />
        <PatientDetails data={reportData.data} />
        <Symptoms data={reportData.data} />
        <Medication data={reportData.data} />
        <Supplements data={reportData.data} />
        <Genetics data={reportData.data} />
      </LoaderWrapper>
    </Layout>
  )
};

export default MedicalReportPage;