import React, { useCallback, useEffect } from 'react';

import moment from 'moment';

import {
  Grid,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import LoaderWrapper from '../common/LoaderWrapper';

import { STORE } from '../../store';
import { DATE_FORMAT } from '../../constants/common';
import { useAsyncAppState } from '../../hooks/useAsyncAppState';
import { loadFiles, deleteFile, downloadFile } from '../../services/upload';

import UploadDocsForm from './UploadDocsForm';

const ProfileDocsPatient = () => {
  const [files, loadFilesAsync] = useAsyncAppState(STORE.areas.documents.load, loadFiles);
  const [deletingFile, deleteFileAsync] = useAsyncAppState(STORE.areas.documents.delete, deleteFile);
  const [downloadingFile, downloadFileAsync] = useAsyncAppState(STORE.areas.documents.download, downloadFile);

  const onRefresh = useCallback(() => {
    loadFilesAsync();
  }, [loadFilesAsync]);

  const onDownloadDocument = useCallback((fileId, fileName) => {
    downloadFileAsync({ fileId, fileName });
  }, [downloadFileAsync]);  

  useEffect(() => {
    onRefresh();
  }, [onRefresh]);

  const onDeleteDocument = useCallback(async (fileId, fileName) => {
    await deleteFileAsync(fileId, fileName);
    onRefresh();
  }, [onRefresh, deleteFileAsync]);

  return (
    <LoaderWrapper items={[files, deletingFile, downloadingFile]} show>
      <Grid container className="justify-content-center pb-1">
        <Grid item xs={12} sm={8} md={6}>
          <h4>Upload Pdf document(s)</h4>
          <UploadDocsForm onRefresh={onRefresh} />

          <Divider className="my-1" />
          
          {files.data && files.data.length > 0 && (
            <Box>
              {files.data.map((file) => (
                <Card key={file.databaseFilename} className="mb-1">
                  <CardHeader className="file-card-header"
                    action={
                      <IconButton onClick={(e) => onDeleteDocument(file._id)} className="file-delete-button">
                        <CloseIcon />
                      </IconButton>
                    }
                    subheader={file.uploadDate ? moment(file.uploadDate).format(DATE_FORMAT) : ''}
                  />
                  <CardContent>
                    <Button size="small" className="mb-05" 
                      onClick={() => onDownloadDocument(file._id, file.originalFilename)}>
                      {file.originalFilename}
                    </Button>
                    <Box>
                      <span className="label">File Size: </span>
                      {file.fileSize ? `${Math.round(file.fileSize * 100 / (1024 * 1024)) / 100}Mb` : ''}
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
    </LoaderWrapper>
  );
}

export default ProfileDocsPatient;
