import React, { useState, useCallback } from 'react';
import { 
  Box, 
  Grid, 
  Divider,
  MenuItem,
  Select,
  Switch,
  Button,
  Tabs,
  Tab,
} from '@mui/material';

import { useNavigate } from 'react-router';

import { STORE } from '../../../store';
import { useAsyncAppState  } from '../../../hooks/useAsyncAppState';
import { useAppState } from '../../../hooks/useAppState'
import { loadDiaryAverage } from '../../../services/diary';

import Layout from '../../layout/Layout';
import LoaderWrapper from '../../common/LoaderWrapper';
import NutrientsGroup from '../NutrientsGroup';

import { calcAverageNutrients, calcFoodPyramid } from '../nutrientsData';
import '../nutrients.css';
import FoodPyramid from './FoodPyramid';
import IngredientSearch from './IngredientSearch';

const AverageNutrientsPage = () => {
  const [foodType, setFoodType] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [days, setDays] = useState(5);
  const [dataAverage, setDataAverage] = useState(null);
  const [dataPyramid, setDataPyramid] = useState(null);
  const [loadDiaryData, loadDiaryAsync] = useAsyncAppState(
    STORE.areas.nutrient.loadAverage,
    loadDiaryAverage
  );

  const navigate = useNavigate();
  const [profile] = useAppState(STORE.common.profile);
  
  const onTabSelect = useCallback((e, newTabIndex) => {
    setTabIndex(newTabIndex);
  }, []);

  const onGenerate = useCallback(async () => {
    const averageFoodItems = await loadDiaryAsync(days);
    setDataAverage(calcAverageNutrients(averageFoodItems, profile, foodType));
    setDataPyramid(calcFoodPyramid(averageFoodItems));
  }, [days, profile, foodType, loadDiaryAsync, setDataAverage]);
  
  const onExit = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  const onSetDays = useCallback((event) => {
    setDays(parseInt(event.target.value));
  }, [setDays]);

  const onChangeFoodType = useCallback(async (event) => {
    const newFoodType = event.target.checked;
    setFoodType(newFoodType);

    const averageFoodItems = await loadDiaryAsync(days);
    setDataAverage(calcAverageNutrients(averageFoodItems, profile, newFoodType));
  }, [days, profile, setFoodType, setDataAverage]);

  return (
    <Layout>
      <LoaderWrapper items={[loadDiaryData]} />
      <Box className="vertical-flex h-100">
        <h3>
          Nutrition Averages
        </h3>
        <Box className="vertical-flex-grow">
          <Grid container className="justify-content-center pb-1">
            <Grid item xs={12} md={5} lg={2}>
              <Select
                fullWidth
                value={days}
                size="small"
                onChange={onSetDays}
                className="mb-1">
                <MenuItem value="1">Last 1 day</MenuItem>
                <MenuItem value="2">Last 2 days</MenuItem>
                <MenuItem value="3">Last 3 days</MenuItem>
                <MenuItem value="4">Last 4 days</MenuItem>
                <MenuItem value="5">Last 5 days</MenuItem>
                <MenuItem value="6">Last 6 days</MenuItem>
                <MenuItem value="7">Last 7 days</MenuItem>
              </Select>
              
              <Button color="primary" size="large"
                variant="contained"
                className="w-100"
                onClick={onGenerate}>
                Generate
              </Button>  

            </Grid>
          </Grid>

          <Grid container className="justify-content-center pb-1">  
            <Grid item xs={12} md={8} lg={6}>

              <Tabs value={tabIndex} 
                onChange={onTabSelect} 
                variant="scrollable"
                allowScrollButtonsMobile
                className="justify-content-center pb-1 center-tabs">
                <Tab label="Summary"></Tab>
                <Tab label="Food Pyramid"></Tab>
                <Tab label="Nutrients Search"></Tab>
              </Tabs>

              {!loadDiaryData && <>Use Generate to Process Data</>}

              {tabIndex === 0 && dataAverage && (
                <>
                  <h4 className="smaller">
                    Supplement
                    <Switch checked={foodType} onChange={onChangeFoodType} />
                    All Food
                  </h4>
                  {dataAverage.map((group) => 
                    <NutrientsGroup 
                      key={group.category} 
                      category={group.category} 
                      items={group.items} />
                  )}
                </>  
              )}

              {tabIndex === 1 && dataPyramid ? 
                <FoodPyramid data={dataPyramid} /> 
                : null }

              {tabIndex === 2 && <IngredientSearch />}  
            </Grid>
          </Grid>
        </Box>

        <Box className="buttons-footer">
          <Divider />
            <Grid container className="justify-content-center pb-1">
              <Grid item xs={12} md={5} lg={2}>
                <Button color="primary" size="large"
                  variant="contained"
                  className="w-100"
                  onClick={onExit}>
                  Back to Home Page
                </Button>
              </Grid>
            </Grid>
        </Box>
      </Box>
    </Layout>
  );
}

export default AverageNutrientsPage;
