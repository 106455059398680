import React, { useCallback, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
}
from '@mui/material';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import './resolveGenePdf.css';

const generatePDFfromHTML = (name) => {
  const doc = new jsPDF({ orientation: 'l', format: 'letter' });
  autoTable(doc, { html: '#title', useCss: true, margin: [10, 5, 10, 5] });
  autoTable(doc, { html: '#genemap', useCss: true, margin: [10, 5, 10, 5] });
  autoTable(doc, { html: '#genelinks', useCss: true, margin: [10, 50, 10, 50]});
  doc.save(`${name}.pdf`);
};

const ResolveGenePdfDialog = (props) => {
  const contentRef = useRef();
  const {
    visible,
    handleClose,
    result,
    name
  } = props;

  const onGenerate = useCallback(() => {
    generatePDFfromHTML(name);
  }, [name]);

  return (
    <Dialog open={visible} 
      maxWidth="lg" 
      onClose={() => handleClose(null)}>

      <DialogTitle>
        Generate PDF
      </DialogTitle>
      
      <DialogContent>
        {result && visible && (
          <div ref={contentRef}>
            <table id="#title">
              <tr>
                <td>{name}</td>
              </tr>
            </table>
            <table id="genemap" className="genemap-table-pdf">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Snippet</th>
                  <th>Gene Code</th>
                  <th>Gene Name</th>
                  <th>Alt. Allele</th>
                  <th>Frequency</th>
                  <th>CADD</th>
                  <th>Significant</th>
                  <th>Variants</th>
                  <th>Position</th>
                  <th>Chromosome</th>
                  <th>Inherit</th>
                </tr>
              </thead>
              <tbody>
                {result.geneMap.map((item) => (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.snippetCode}</td>
                    <td>{item.geneCode}</td>
                    <td>{item.geneName}</td>
                    <td>{item.altAllele}</td>
                    <td>{item.frequency}</td>
                    <td>{item.cadd}</td>
                    <td>{item.significants}</td>
                    <td>{item.variants}</td>
                    <td>{item.position}</td>
                    <td>{item.chromosome}</td>
                    <td style={{ 
                      backgroundColor: (item.inheritance === 'Hetero' ? 'white' : '#f33')
                    }}>{item.inheritance}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <table id="genelinks" className="genemap-table-pdf">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Gene Left</th>
                  <th>Gene Right</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {result.geneLinks.map((link) => (
                  <tr>
                    <td>{link.name}</td>
                    <td>{link.left}</td>
                    <td>{link.right}</td>
                    <td>{link.score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </DialogContent>
      
      <DialogActions>
        <Button onClick={() => onGenerate()}>
          Generate
        </Button>
        <Button onClick={() => handleClose(null)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResolveGenePdfDialog;