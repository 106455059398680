// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/nurse2.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nurse {
  height: 100%;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  background-size: auto 100%;
  background-color: #E6E6E6;
}

.home {
  height: 100%;
  text-align: left;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
}

.home-header {
  padding: 1rem 1rem 0 1rem;
  flex-grow: 0 !important;
  flex-basis: inherit !important;
}

.home-content {
  padding: 1rem;
  flex-grow: 1 !important;
  flex-basis: inherit !important;
}

.home-content > .home-paper {
  display: flex;
  flex-direction: column;
}

.home-paper {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.home-paper .home-paper-nested {
  padding: 1rem;
  margin: 0;
}

.home-menu button {
  justify-content: left !important;
}

.home-menu button svg {
  margin-right: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/home/home.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,6DAAoD;EACpD,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,iCAAiC;EACjC,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,qDAAqD;AACvD;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".nurse {\n  height: 100%;\n  background: url('../../images/nurse2.jpg') no-repeat;\n  background-size: auto 100%;\n  background-color: #E6E6E6;\n}\n\n.home {\n  height: 100%;\n  text-align: left;\n  flex-direction: column !important;\n  flex-wrap: nowrap !important;\n}\n\n.home-header {\n  padding: 1rem 1rem 0 1rem;\n  flex-grow: 0 !important;\n  flex-basis: inherit !important;\n}\n\n.home-content {\n  padding: 1rem;\n  flex-grow: 1 !important;\n  flex-basis: inherit !important;\n}\n\n.home-content > .home-paper {\n  display: flex;\n  flex-direction: column;\n}\n\n.home-paper {\n  background-color: rgba(255, 255, 255, 0.8) !important;\n}\n\n.home-paper .home-paper-nested {\n  padding: 1rem;\n  margin: 0;\n}\n\n.home-menu button {\n  justify-content: left !important;\n}\n\n.home-menu button svg {\n  margin-right: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
