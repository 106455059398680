import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Divider } from '@mui/material';

import { loadByAnswerId, saveSymptom } from '../../services/symptom';
import { loadSurveyResultById } from '../../services/survey';
import { useDialog } from '../../hooks/useDialog';
import { useAsyncAppState } from '../../hooks/useAsyncAppState';
import { STORE } from '../../store';
import LoaderWrapper from '../common/LoaderWrapper';
import { useState } from 'react';

const SurveyResultBubbles = (props) => {
  const { survey } = props;
  const [currentSurvey, setCurrentSurvey] = useState(survey);
  const [surveyLoading, loadSurveyAsync] = useAsyncAppState(STORE.areas.survey.load, loadSurveyResultById);
  const [symptomLoading, loadSymptomAsync] = useAsyncAppState(STORE.areas.symptom.load, loadByAnswerId);
  const [symptomSaving, saveSymptomAsync] = useAsyncAppState(STORE.areas.symptom.save, saveSymptom);

  const [openSymptomDialog] = useDialog(STORE.areas.symptom.dialog);
  const answers = useMemo(() => 
    (currentSurvey.answers || []).filter((answer) => answer.value === '1'), 
    [currentSurvey]);

  const onBubbleClick = useCallback(async (answer) => {
    const symptomStored = await loadSymptomAsync(answer._id);
    const symptom = await openSymptomDialog({
      symptomId: symptomStored ? symptomStored._id : null,
      answerId: answer._id,
      symptomDate: null,
      extended: true,
      name: survey.category,
      description: answer.ask
    });

    if (symptom) {
      await saveSymptomAsync(symptom);
      const newSurvey = await loadSurveyAsync(currentSurvey._id);
      setCurrentSurvey(newSurvey);
    }
  }, [
    survey, 
    currentSurvey, 
    loadSymptomAsync, 
    loadSurveyAsync, 
    openSymptomDialog, 
    saveSymptomAsync
  ]);  

  if (!answers.length) {
    return null;
  }

  return (
    <LoaderWrapper items={[symptomLoading, symptomSaving, surveyLoading]} show>
      <div className="survey-graph-bubbles-container">
        <h4>{currentSurvey.category}</h4>
        <Divider />
        {answers.map((answer) => (
          <div key={answer._id} className="survey-graph-bubble" onClick={() => onBubbleClick(answer)}>
            <div className={classNames('ball', answer.severityScale > 0 && `s-${answer.severityScale}`)}></div>
            <div className="title">{answer.ask}</div>
          </div>
        ))}
      </div>
    </LoaderWrapper>
  );
}

export default SurveyResultBubbles;
