import React, { useCallback, useState } from 'react';

import { findCommon } from '../../services/nutrient';
import FoodSearch from './FoodSearch';

const CommonFetchForm = (props) => {
  const { onItemsLoaded } = props;
  const [loading, setLoading] = useState(false);

  const load = useCallback(async (mask) => {
    setLoading(true);
    try {
      const items = await findCommon(mask);
      onItemsLoaded(items);
      setLoading(false);
    }
    catch (ex) {
      setLoading(false);
      console.log(ex);
    }
  }, [onItemsLoaded]);

  const onSearchRequested = useCallback((mask) => {
    load(mask);
  }, [load]);

  return (
    <FoodSearch loading={loading} className="my-05"
      onSearchRequested={onSearchRequested} />
  );
};

export default CommonFetchForm;