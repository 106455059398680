import React from 'react';
import EditSymptomDialog from '../dialogs/editSymptomDialog';
import SelectSymptom from '../dialogs/SelectSymptom';
import { useDialog } from '../../hooks/useDialog';
import { STORE } from '../../store';

const Addons = () => {
  const [, closeSymptomDialog, symptomDialogData] = useDialog(STORE.areas.symptom.dialog);
  const [, closeSelectSymptomDialog, selectSymptomData] = useDialog(STORE.areas.survey.category.dialog);
  
  return (
    <>
      <SelectSymptom 
        category={selectSymptomData.data ? selectSymptomData.data.category : null}
        handleClose={closeSelectSymptomDialog}
        visible={selectSymptomData.visible}
      />
      <EditSymptomDialog
        handleClose={closeSymptomDialog}
        symptomId={symptomDialogData.data ? symptomDialogData.data.symptomId : null}
        symptomDate={symptomDialogData.data ? symptomDialogData.data.symptomDate : null}
        extended={symptomDialogData.data ? symptomDialogData.data.extended : false}
        answerId={symptomDialogData.data ? symptomDialogData.data.answerId : null}
        name={symptomDialogData.data ? symptomDialogData.data.name : null}
        description={symptomDialogData.data ? symptomDialogData.data.description : null}
        visible={symptomDialogData.visible} />
    </>  
  );
}

export default Addons;
