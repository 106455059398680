import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
}
from '@mui/material';

import { FOOD_NAME } from './nutrientsData';

const HistoryTimeList = (props) => {
  const { foodTime, items, onSelect } = props;
  return (
    <TableContainer component={Paper} className="search-result-time">
      <Table sx={{ minWidth: '20rem' }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>{foodTime}</TableCell>
            <TableCell className="food-portion">Serving(s)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={`${item.foodId}_${index}`} hover>
              <TableCell className="nutrient-option" onClick={() => onSelect(item)}>
                <div className="nutrient-title">{FOOD_NAME[item.foodType]}</div>
                <div className="nutrient-label">{item.description}</div>
              </TableCell>
              <TableCell className="food-portion">
                <div className="nutrient-title">{item.portion}</div>
                <div className="nutrient-label">{item.quantity}</div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const HistoryResultsList = (props) => {
  const { items, onSelect } = props;
  return (items ? (
    <>
      {items.breakfast && <HistoryTimeList foodTime="Breakfast" items={items.breakfast} onSelect={onSelect} />}
      {items.lunch && <HistoryTimeList foodTime="Lunch" items={items.lunch} onSelect={onSelect} />}
      {items.dinner && <HistoryTimeList foodTime="Dinner" items={items.dinner} onSelect={onSelect} />}
    </>) : null
  );
};

export default HistoryResultsList;