import moment from 'moment';

const PatientDetails = ({ data }) => {
  const { patient } = data;

  return (
    <div>
      <h2>
        Patient Details
      </h2>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="row">
              <div className="col-4 cell-header">Full Name</div>
              <div className="col-8 cell-content">{patient.fullName}</div>
            </div>
            <div className="row">
              <div className="col-4 cell-header">Birth Date</div>
              <div className="col-8 cell-content">{patient.dob ? moment(patient.dob).format('DD MMMM yyyy') : '-' }</div>
            </div>
            <div className="row">
              <div className="col-4 cell-header">Age</div>
              <div className="col-8 cell-content">{patient.dob ? moment().diff(patient.dob, 'years') : '-'}</div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="row">
              <div className="col-4 cell-header">Gender</div>
              <div className="col-8 cell-content">{patient.gender ? (patient.gender === 'M' ? 'Male' : 'Female') : '-'}</div>
            </div>
            <div className="row">
              <div className="col-4 cell-header">Ethnicity</div>
              <div className="col-8 cell-content">-</div>
            </div>
            <div className="row">
              <div className="col-4 cell-header">Location</div>
              <div className="col-8 cell-content">{patient.location ? patient.location : '-'}</div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="row">
              <div className="col cell-header">Allergies</div>
            </div>
            <div className="row">
              <div className="col cell-content">
                No Data
                {/* <ul>
                  <li className="mb-2">
                    <div>
                      <b>Allergy One</b><br />
                      Causes this
                    </div>
                  </li>
                  <li className="mb-2">
                    <div>
                      <b>Allergy Two</b><br />
                      Causes this
                    </div>
                  </li>
                  <li className="mb-2">
                    <div>
                      <b>Allergy Three</b><br />
                      Causes this
                    </div>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  );
};

export default PatientDetails;