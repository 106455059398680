import { useCallback } from 'react';
import { useAppState } from './useAppState';

const getDefault = (visible = false, data = null) => ({
  visible,
  data
});

const resolvers = {};

export const useDialog = (storeKey, visible, data) => {
  const [dialogData, setDialogData] = useAppState(storeKey, getDefault(visible, data));

  const open = useCallback((data) => {
    setDialogData({ visible: true, data });
    return new Promise((resolve) => {
      resolvers[storeKey] = resolve;
    });
  }, [storeKey, setDialogData]);

  const close = useCallback((data) => {
    setDialogData({ visible: false, data });
    resolvers[storeKey](data);
  }, [storeKey, setDialogData]);

  return [open, close, dialogData];
};

