import axios from 'axios';
import moment from 'moment';

export const autocompleteMedicines = async(mask) => {
  const url = `${process.env.REACT_APP_API_URL}/medicines/autocomplete?mask=${mask}`;
  const response = await axios.get(url);
  
  return response.data;
};

export const findMedicines = async(mask) => {
  const url = `${process.env.REACT_APP_API_URL}/medicines/find?mask=${mask}`;
  const response = await axios.get(url);
  
  return response.data;
};

export const saveMedDiary = async(args) => {
  const { medItems, targetDate } = args;
  const targetDateUtc = moment(targetDate).format('YYYY-MM-DD');
  const url = `${process.env.REACT_APP_API_URL}/medicines/saveMedDiary/${targetDateUtc}`;
  const response = await axios.post(url, medItems);
  
  return response.data;
};

export const loadMedDiary = async(onDate = null) => {
  const targetDate = (onDate || moment()).format('YYYY-MM-DD');
  const targetDateUtc = moment(targetDate).format('YYYY-MM-DD');
  const url = `${process.env.REACT_APP_API_URL}/medicines/loadMedDiary/${targetDateUtc}`;
  const response = await axios.get(url);
  
  return response.data;
};

export const loadMedDiaryDays = async(args) => {
  const fromDate = (args.onDate || moment()).format('YYYY-MM-DD');
  const url = `${process.env.REACT_APP_API_URL}/medicines/loadMedDiaryDays/${fromDate}`;
  const response = await axios.get(url, {
    signal: args.controller.signal
  });

  return response.data ? response.data.map((date) => moment(date).format('YYYY-MM-DD')) : [];
};

export const loadCustomMedicines = async() => {
  const url = `${process.env.REACT_APP_API_URL}/medicines/custom`;
  const response = await axios.get(url);
  
  return response.data;
};

export const loadHistoryMedicines = async() => {
  const url = `${process.env.REACT_APP_API_URL}/medicines/history`;
  const response = await axios.get(url);
  
  return response.data;
};