import moment from 'moment';

import { FOOD_TYPE } from '../../services/nutrient';
export { FOOD_TYPE };

export const validInt = (value) => value && parseInt(value) > 0;

export const FOOD_NAME = {
  [FOOD_TYPE.BRANDED]: 'Branded',
  [FOOD_TYPE.COMMON]: 'Common',
  [FOOD_TYPE.SUPPLEMENT]: 'Supplement'
};

const CATEGORIES = [
  'Carbohydrates',
  'Lipids',
  'Protein',
  'Vitamins',
  'Minerals',
  'General',
  'Ignore'
];

const calcNutrientNorm = (nutrient, profile) => {
  if (!nutrient || !profile) {
    return null;
  }
  const age = profile.dob ? moment().diff(profile.dob, 'years') : 0;
  const gender = (profile.gender || 'm').toLowerCase();
  let field = gender;
  if (age > 0) {
    if (age <= 3) {
      field = 'a3';
    }
    else if (age <= 8) {
      field = 'a8';
    }
    else if (age <= 13) {
      field = 'a13';
    }
    else if (age <= 18) {
      field = `${gender}18`;
    }
    else if (age >= 50 && nutrient.m50 && nutrient.f50 ) {
      field = `${gender}50`;
    }
  }
  return nutrient[field] || null;
};

export const roundAmount = (total) => {
  if (total < 1) {
    return Math.round(100 * total) / 100;
  }
  else if (total < 10) {
    return Math.round(10 * total) / 10;
  }
  else {
    return Math.round(total);
  }
};

const addNutrient = (items, nutrient, foodItem, profile, calcNorm = true) => {
  const foodName = foodItem.foodData.description;
  const foodType = foodItem.foodType;
  const amount = foodType === FOOD_TYPE.SUPPLEMENT ? 
    nutrient.amount :
    nutrient.amount * foodItem.weight / 100; // 100g -> amount, weight => total

  const item = items.find((item) => item.nutrient._id === nutrient._id);

  if (item) {
    const food = item.foods.find((food) => food.foodId === foodItem.foodId);
    if (food) {
      food.amount += amount;
    }
    else {
      item.foods.push({ 
        foodId: foodItem.foodId, 
        foodName,
        foodType,
        amount
      });
    }
    item.total += amount;
  }
  else {
    items.push({
      nutrient,
      norm: calcNorm ? calcNutrientNorm(nutrient, profile) : null,
      foods: [
        { foodId: foodItem.foodId, foodName, foodType, amount }
      ],
      total: amount
    });
  }
};

/**
  foodData -> description, 
  portion -> description, gramWeight
  quantity
*/
const calcNutrients = (foodItems, profile, showFood) => {
  const result = {};
  foodItems.forEach((foodItem) => {
    if (showFood || foodItem.foodType === FOOD_TYPE.SUPPLEMENT) {
      const nutrients = foodItem.foodData?.nutrients;
      if (nutrients && nutrients.length > 0) {
        nutrients.forEach((nutrient) => {
          if (nutrient.amount > 0) {
            const category = nutrient.category || 'Ignore';
            let items = result[category];
            if (!items) {
              items = result[category] = [];
            }
            
            addNutrient(items, nutrient, foodItem, profile);
          }
        });
      }
    }
  });

  const nutrients = [];
  CATEGORIES.forEach((category) => {
    if (category === 'Ignore') {
      return;
    }

    const items = result[category];
    if (items) {
      nutrients.push({
        category,
        items: items.sort((a, b) => a.nutrient.name.localeCompare(b.nutrient.name.localeCompare))  
      })
    }
  });

  return nutrients;
};

const calcDailyNutrients = (foodItems, profile, showFood) => {
  const items = [];
  foodItems.forEach((foodItem) => {
    if (showFood || foodItem.foodType === FOOD_TYPE.SUPPLEMENT) {
      const nutrients = foodItem.foodData?.nutrients;
      if (nutrients && nutrients.length > 0) {
        nutrients.forEach((nutrient) => {
          if (nutrient.amount > 0) {
            addNutrient(items, nutrient, foodItem, profile, false);
          }
        });
      }
    }
  });

  return items;
};

// [foodId, foodName, amount]
const mergeFoods = (foodsLeft, foodsRight) => {
  const uniqueRight = foodsRight.filter((right) => 
    !foodsLeft.find((left) => left.foodId === right.foodId));

  foodsLeft.forEach((left) => {
    const sameRight = foodsRight.find((right) => left.foodId === right.foodId);
    if (sameRight) {
      left.amount += sameRight.amount;
    }
  });

  return foodsLeft.concat(uniqueRight);
};

export const calcAverageNutrients = (diaryFoodItems, profile, showFood) => {
  const nutrientsItems = {};
  const days = [];
  Object.values(diaryFoodItems).forEach((foodItems, index) => {
    days[index] = calcDailyNutrients(foodItems, profile, showFood);
  });

  if (!days.length) {
    return [];
  }

  days.forEach((dailyNutrientItems) => {
    dailyNutrientItems.forEach((dailyNutrientItem) => {
      const nutrientItem = nutrientsItems[dailyNutrientItem.nutrient._id];
      if (!nutrientItem) {
        nutrientsItems[dailyNutrientItem.nutrient._id] = dailyNutrientItem;
      }
      else {
        nutrientItem.foods = mergeFoods(nutrientItem.foods, dailyNutrientItem.foods);
        nutrientItem.total += dailyNutrientItem.total;
      }
    });
  });

  const itemsRaw = Object.values(nutrientsItems);
  itemsRaw.forEach((nutrientsItem) => {
    nutrientsItem.norm = calcNutrientNorm(nutrientsItem.nutrient, profile);
    nutrientsItem.total = nutrientsItem.total / days.length;
    nutrientsItem.foods.forEach((food) => (food.amount = food.amount / days.length));
  });

  const nutrients = [];
  CATEGORIES.forEach((category) => {
    if (category === 'Ignore') {
      return;
    }

    const items = itemsRaw
      .filter((itemRaw) => itemRaw.nutrient.category === category)
      .sort((a, b) => a.nutrient.name.localeCompare(b.nutrient.name.localeCompare));

    if (items) {
      nutrients.push({
        category,
        items
      })
    }
  });
  
  return nutrients;
};

const FOOR_GROUPS = {
  Fats: { order: 1, name: 'Fats, Oils and Sweets', group: 'fats' },
  Milk: { order: 11, name: 'Milk, Yogurt & Cheese', group: 'milk' },
  Meat: { order: 12, name: 'Meat, Poultry, Fish, Dry Beans, Eggs & Nuts', group: 'meat' },
  Fruits: { order: 21, name: 'Fruits', group: 'fruits' },
  Vegetables: { order: 22, name: 'Vegetables', group: 'vegetables' },
  Bread: { order: 41, name: 'Bread, Cereal, Rice & Pasta', group: 'bread' }
};

export const calcFoodPyramid = (diaryFoodItems) => {
  const raw = [];
  Object.values(diaryFoodItems).forEach((foodItems, index) => {
    foodItems.forEach((foodItem) => {
      if (foodItem.foodData.groups?.length) {
        foodItem.foodData.groups.forEach((groupName) => {
          const group = FOOR_GROUPS[groupName];
          raw.push({...group, quantity: foodItem.quantity });
        });
      }
    });
  });

  const result = [];
  const addLevelItem = (levelRow, item) => {
    const found = levelRow.find((found) => found.order === item.order);
    if (found) {
      found.quantity += item.quantity;
    }
    else {
      levelRow.push(item);
    }
  };

  const addLevelRow = (levelRow) => {
    if (levelRow) {
      result.push(levelRow.sort((left, right) => left.order - right.order))
    }
  };

  let level = -1;
  let levelRow = null;

  raw.sort((left, right) => left.order - right.order).forEach((item) => {
    const currentLevel = Math.round(item.order / 10);
    if (level !== currentLevel) {
      addLevelRow(levelRow);
      level = currentLevel;
      levelRow = [];
    }
    addLevelItem(levelRow, item);
  });

  addLevelRow(levelRow);

  return result;
};

export const calcWeight = (foodData, foodType, portionId, quantity) => {
  if (!foodData || !validInt(quantity)) {
    return 0;
  }

  const portion = foodData.portions && validInt(portionId) ?
    foodData.portions.find((portion) => String(portion._id) === String(portionId)) 
    : null;

  let portionWeight = 0; 
  if (portion) { 
    portionWeight = portion.gramWeight;
  }
  else if (foodType === FOOD_TYPE.SUPPLEMENT) {
    portionWeight = 0;
  }
  else {
    portionWeight = foodData.servingSize;
  }
  return portionWeight ? portionWeight * quantity : 0;
};

export const calcEnergy = (foodData, weight) => {
  if (!foodData || !foodData.nutrients || foodData.nutrients.length === 0) {
    return 0;
  }
  const energyNutrient = foodData.nutrients.find((nutrient) => nutrient.unitName === 'kcal');
  return energyNutrient ? Math.round(energyNutrient.amount * weight / 100, 1) : 0;
};

const FOOD_TYPE_BREAKFAST = 'breakfast';
const FOOD_TYPE_LUNCH = 'lunch';
const FOOD_TYPE_DINNER = 'dinner';

const getInitialFoodItems = () => {
  return {
    breakfast: { items: [], totals: { weight: 0, energy: 0 } },
    lunch: { items: [], totals: { weight: 0, energy: 0 } },
    dinner: { items: [], totals: { weight: 0, energy: 0 } },
    totals: { weight: 0, energy: 0 }
  };
};

export const calcFoodGroups = (foodItems, showFood) => {
  const result = getInitialFoodItems();

  let totalWeight = 0, totalEnergy = 0;
  foodItems.forEach((foodItem, index) => {
    if (showFood || foodItem.foodType === FOOD_TYPE.SUPPLEMENT) {
      const weight = foodItem.weight || 0;
      const energy = foodItem.energy || 0;
      if (foodItem.foodTime === FOOD_TYPE_BREAKFAST) {
        result.breakfast.items.push({ ...foodItem, index });
        result.breakfast.totals.weight += weight;
        result.breakfast.totals.energy += energy;
      }
      else if (foodItem.foodTime === FOOD_TYPE_LUNCH) {
        result.lunch.items.push({ ...foodItem, index });
        result.lunch.totals.weight += weight;
        result.lunch.totals.energy += energy;
      }
      else if (foodItem.foodTime === FOOD_TYPE_DINNER) {
        result.dinner.items.push({ ...foodItem, index });
        result.dinner.totals.weight += weight;
        result.dinner.totals.energy += energy;
      }

      totalWeight += weight;
      totalEnergy += energy;
    }
  });

  result.totals.weight = totalWeight;
  result.totals.energy = totalEnergy;

  return result;
};

const createDailyResult = (foodItems, iteration, profile, showFood) => {
  const nutrients = calcNutrients(foodItems, profile, showFood);
  return {
    foodItems,
    nutrients,
    iteration,
  }
};

export const initDaily = (foodItems, profile, showFood) => {
  return createDailyResult([...foodItems], 0, profile, showFood);
};

export const addDailyRecord = (foodItem, data, profile, showFood) => {
  const iteration = data.iteration + 1;
  const foodItems = data.foodItems;
  foodItems.push(foodItem);

  return createDailyResult([...foodItems], iteration, profile, showFood);
};

export const updateDailyRecord = (foodItem, index, data, profile, showFood) => {
  const iteration = data.iteration + 1;
  const foodItems = data.foodItems;
  foodItems[index] = foodItem;
  
  return createDailyResult([...foodItems], iteration, profile, showFood);
};

export const removeDailyRecord = (index, data, profile, showFood) => {
  const iteration = data.iteration + 1;
  const foodItems = data.foodItems;
  foodItems.splice(index, 1);
  
  return createDailyResult([...foodItems], iteration, profile, showFood);
};

export const refreshDailyRecord = (data, profile, showFood) => {
  const foodItems = data.foodItems;
  
  return createDailyResult(foodItems, data.iteration, profile, showFood);
};

export const getSaveFoodItems = (foodItems) => {
  return foodItems.map((foodItem) => ({
    foodId: foodItem.foodData._id,
    foodType: foodItem.foodType,
    foodTime: foodItem.foodTime,
    description: foodItem.foodData.description,
    portionId: foodItem.portion?._id,
    quantity: foodItem.quantity
  }));
};

export const nutrientNormColor = (value, hasSupplement) => {
  if (!value) {
    return 'white';
  }

  let result = '#56AC69'; 

  if (hasSupplement && value > 200) {
    return result;
  }
  
  if (value < 20) {
    result = '#667C89'
  }
  else if (value > 80) {
    result = '#CF4B17'
  }
  return result;
};

export const nutrientTitle = (nutrientItem) => {
  const norm = nutrientItem.norm && nutrientItem.norm > 0 ? `/ ${nutrientItem.norm}` : '';
  return `${roundAmount(nutrientItem.total)} ${norm}${nutrientItem.nutrient.unitName}`;
};

export const FOOD_ACTIONS = {
  INIT: 'init_food',
  ADD: 'add_food',
  UPDATE: 'update_food',
  REMOVE: 'remove_food',
  SHOW_MODE: 'show_mode',
  SHOW_FOOD_TYPE: 'show_food_type',
};

export const dataReducer = (state, action) => {
  if (action.type === FOOD_ACTIONS.INIT) {
    const foodItems = action.foodItems;
    const profile = action.profile.data;
    const foodData = initDaily(foodItems, profile, state.showFoodType);
    return { ...state, ...foodData };
  }
  else if (action.type === FOOD_ACTIONS.ADD) {
    const foodItem = action.foodItem;
    const profile = action.profile.data;
    const foodData = addDailyRecord(foodItem, state, profile, state.showFoodType);
    return { ...state, ...foodData };
  }
  else if (action.type === FOOD_ACTIONS.UPDATE) {
    const foodItem = action.foodItem;
    const foodIndex = action.foodIndex;
    const profile = action.profile.data;
    const foodData = updateDailyRecord(foodItem, foodIndex, state, profile, state.showFoodType);
    return { ...state, ...foodData };
  }
  else if (action.type === FOOD_ACTIONS.REMOVE) {
    const foodIndex = action.foodIndex;
    const profile = action.profile.data;
    const foodData = removeDailyRecord(foodIndex, state, profile, state.showFoodType);
    return { ...state, ...foodData };
  }
  else if (action.type === FOOD_ACTIONS.SHOW_MODE) {
    const showEnergy = action.showEnergy;
    return { ...state, showEnergy };
  }
  else if (action.type === FOOD_ACTIONS.SHOW_FOOD_TYPE) {
    const showFoodType = action.showFoodType;
    const profile = action.profile.data;
    const foodData = refreshDailyRecord(state, profile, showFoodType);
    return { ...state, ...foodData, showFoodType };
  }

  throw Error('Unknown action.');
};