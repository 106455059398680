import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
}
from '@mui/material';

const ResultsCustomList = (props) => {
  const { items, onSelect } = props;
  return (
    <TableContainer component={Paper} className="search-result-paper">
      <Table sx={{ minWidth: '20rem' }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow hover key={item._id}>
              <TableCell onClick={() => onSelect(item)}>
                {item.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultsCustomList;