import moment from 'moment';

import { validateString } from '../../services/validation';

export const SEVERITY_DATA = [
  { value: '', text: 'N/A' },
  { value: '1', text: '1' },
  { value: '2', text: '2' },
  { value: '3', text: '3' },
  { value: '4', text: '4' },
  { value: '5', text: '5' },
  { value: '6', text: '6' },
  { value: '7', text: '7' },
  { value: '8', text: '8' },
  { value: '9', text: '9' },
  { value: '10', text: '10' },
];

export const DURATION_DATA = [
  { value: '', text: 'N/A' },
  { value: '1m', text: '1 minute' },
  { value: '3m', text: '3 minutes' },
  { value: '8m', text: '8 minutes' },
  { value: '15m', text: '15 minutes' },
  { value: '30m', text: '30 minutes' },
  { value: '1h', text: '1 hour' },
  { value: '2h', text: '2 hour' },
  { value: '3h', text: '3 hours' },
  { value: '6h', text: '6 hours' },
  { value: '12h', text: '12 hours' },
  { value: '1d', text: '1 day' },
  { value: '2d', text: '2 days' },
  { value: '3d', text: '3 days' },
  { value: '5d', text: '5 days' },
  { value: '6d', text: '6 days' },
  { value: '1w', text: '1 week' },
  { value: '2w', text: '2 weeks' },
  { value: '3w', text: '3 weeks' },
  { value: '1M', text: '1 month' },
];

export const PERIOD_DATA = [
  { value: '', text: 'N/A' },
  { value: '1m', text: '1 minute' },
  { value: '3m', text: '3 minutes' },
  { value: '8m', text: '8 minutes' },
  { value: '15m', text: '15 minutes' },
  { value: '30m', text: '30 minutes' },
  { value: '1h', text: '1 hour' },
  { value: '2h', text: '2 hour' },
  { value: '3h', text: '3 hour' },
  { value: '6h', text: '6 hours' },
  { value: '12h', text: '12 hours' },
  { value: '1d', text: '1 day' },
  { value: '2d', text: '2 days' },
  { value: '3d', text: '3 days' },
  { value: '5d', text: '5 days' },
  { value: '6d', text: '6 days' },
  { value: '1w', text: '1 week' },
  { value: '2w', text: '2 weeks' },
  { value: '3w', text: '3 weeks' },
  { value: '1M', text: '1 month' },
];

const SYMBOL_DATE_MAP = {
  'm': 1,
  'h': 60,
  'd': 24 * 60,
  'w': 24 * 60 * 7,
  'M': 24 * 60 * 30,
};

export const translateSymbolToMin = (value) => {
  if (!value || value === '') {
    return 0;
  }
  const values = /([\d]+)([\w])/.exec(value);
  return parseInt(values[1]) * SYMBOL_DATE_MAP[values[2]];
};

export const getPeriodData = (duration = '') => {
  const result = PERIOD_DATA;
  if (!duration || duration === '') {
    return result;
  }

  const durationMin = translateSymbolToMin(duration) / 4;
  return PERIOD_DATA.filter((period) =>
    period.value === '' || translateSymbolToMin(period.value) >= durationMin);
};

export const daysRange = (start, end) => {
  if (!end) {
    return 1;
  }

  return moment(end).diff(moment(start), 'days');
};

export const maxIterationCount = (duration, period) => {
  const pair = translateSymbolToMin(duration) + translateSymbolToMin(period);
  return pair > 0 ? Math.ceil(SYMBOL_DATE_MAP['d'] / pair) : -1;
};

export const showEndDate = (duration, period) => {
  if (!period || period === '') {
    return false;
  }
  return maxIterationCount(duration, period) < 10;
};

export const getInitialValues = (symptomDate = null, answerId = null, name = null, description = null) => {
  return {
    name: name || '',
    start: symptomDate ? moment(symptomDate) : null,
    description: description || '',
    severity: '',
    duration: '',
    period: '',
    periodEnd: null,
    amongRelatives: '',
    amongHousehold: '',
    trigger: '',
    managing: '',
    misc: '',
    answerId,

    showEndDate: false,
    periodData: getPeriodData(),
  }
};

export const mapValues = (symptomData, symptomDate = null) => {
  const start = symptomDate ? moment(symptomDate) : (
    symptomData.start ? moment(symptomData.start) : null
  );

  const periodEnd = symptomData.periodEnd ? moment(symptomData.periodEnd) : null;

  return {
    name: symptomData.content,
    start,
    description: symptomData.description,
    severity: String(symptomData.severityScale),
    amongRelatives: symptomData.symptomAmongRelatives || '',
    amongHousehold: symptomData.symptomAmongHousehold || '',
    duration: symptomData.duration || '',
    period: symptomData.period || '',
    periodEnd,
    trigger: symptomData.trigger || '',
    managing: symptomData.managing || '',
    misc: symptomData.misc || '',
    answerId: symptomData.answerId || null,

    showEndDate: showEndDate(symptomData.duration, symptomData.period),
    periodData: getPeriodData(symptomData.duration),
  }
};

export const validate = (values, props) => {
  const errors = {};

  let message = validateString(values.name, 'Please enter symptom name');
  if (message) errors.name =  message;

  message = validateString(values.description, 'Please enter symptom description');
  if (message) errors.description =  message;

  if (props.extended) {
    message = validateString(values.severity, 'Give a grade how strong is the feeling at it worst moment');
    if (message) errors.severity =  message;

    if (values.start) {
      if (!values.start.isValid()) {
        errors.start = 'Please enter valid symptom Start date';
      }
      else if (values.start.isAfter()) {
        errors.start = 'Symptom date could not be in future';
      }
    }
    else if (!values.answerId) {
      errors.start = 'Please enter valid symptom Start date';
    }

    if (values.showEndDate) {
      if (!values.periodEnd || !values.periodEnd.isValid()) {
        errors.periodEnd = 'Please enter valid symptom End date';
      }
      else if (values.periodEnd.isAfter()) {
        errors.periodEnd = 'End date could not be in future';
      }
      else if (values.start && values.start.isValid() &&
        values.start.startOf('day').toDate() > values.periodEnd.toDate()) {
          message = 'End date should be less than Start date';
          errors.start = message;
          errors.periodEnd = message;
      }
    }
  }

  return errors;
};

export const saveValues = (values, props) => {
  const { symptomId, handleClose } = props;
  const start = values.start;
  let periodEnd = null;

  if (start && values.showEndDate) {
    periodEnd = values.periodEnd;
    periodEnd.set(start.hours, 'hours');
    periodEnd.set(start.minutes, 'minutes');
    periodEnd.set(start.seconds, 'seconds');
  }

  const symptom = {
    _id: symptomId,
    start: start ? start.toDate() : null,
    answerId: values.answerId,
    content: values.name,
    description: values.description,
    severityScale: values.severity,
    duration: values.duration,
    period: values.period,
    periodEnd: periodEnd ? periodEnd.toDate() : null,
    symptomAmongRelatives: values.amongRelatives,
    symptomAmongHousehold: values.amongHousehold,
    trigger: values.trigger,
    managing: values.managing,
    misc: values.misc
  };

   handleClose(symptom);
};
