import React, { useEffect, useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';

import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Grid,
  Zoom
} from '@mui/material';

import { sleep } from '../../services/survey';
import { TYPE_MULTI, getInitialResult, getSurveyAnswer } from './surveyData';
import SwipeSelect from '../common/SwipeSelect';

let onAnswerResolved = null;
const getAnswer = () => {
  return new Promise((resolve) => {
    onAnswerResolved = resolve;
  });
};

const processItem = async (data, index, setItem, setValue, result) => {
  const question = data[index];
  if (!question.options) {
    return;
  }

  const options = question.options;
  const percentStart = Math.floor(100 * index / data.length);
  const percentEnd = Math.floor(100 * (index + 1) / data.length);

  let answerText = 'N/A';
  let answerValue = -1;

  const item = {
    options: [
      { value: answerValue, text: answerText },
      ...options
    ],
    percent: percentStart,
    ask: question.ask,
    answer: null
  };

  setItem(item);

  answerValue = await getAnswer();

  const option = options.find((option) => option.value === answerValue);
  answerText = option.text;

  const answer = getSurveyAnswer(
    question.ask,
    answerText,
    answerValue
  );

  result.answers.push(answer);

  setItem({
    ...item,
    answer,
    percent: percentEnd
  });

  await sleep(300);

  setValue(-1);
};

const processItems = async (data, setItem, setValue, result) => {
  for (let index = 0; index < data.length; index++) {
    await processItem(data, index, setItem, setValue, result);
  }
};

const SurveyMulti = (props) => {
  const { category, data, onComplete } = props;
  const [item, setItem] = useState(null);
  const [value, setValue] = useState(-1);
  const disabled = useMemo(() => (value < 0), [value]);

  useEffect(() => {
    const runSurvey = async() => {
      const result = getInitialResult(category.name, TYPE_MULTI);
      await processItems(data, setItem, setValue, result);
      onComplete(result);
    };

    runSurvey();
  }, [data, category, setItem, onComplete]);

  const onChange = useCallback((event) => {
    setValue(event.target.value);
  }, [setValue]);

  const onNext = useCallback(() => {
    onAnswerResolved(value);
  }, [value]);

  const asking = item && item.answer === null;
  return (
    <Box className="vertical-flex h-100">
      <Box className="vertical-flex-grow">
        {asking && (
          <Zoom in={asking}>
            <Grid container className="justify-content-center pb-1">
              <Grid item xs={12} md={7} lg={5}>
                <Box>
                  <Divider />
                  <h2 className="survey-ask">{item.ask}</h2>  
                </Box>
                <SwipeSelect
                  name="options"
                  data={item.options}
                  textField="text"
                  dataField="value"
                  value={value}
                  onChange={onChange}
                  height="25rem"
                  width="100%"
                />
              </Grid>
            </Grid>
          </Zoom>
        )}
      </Box>

      <Box className="survey-footer">
        {item && <LinearProgress variant="determinate" value={item.percent} />}
        <Divider />

        {item && (
          <Grid container className="justify-content-center pb-1">
            <Grid item xs={12} md={5} lg={2}>
              <Button color="primary" size="large"
                variant="contained"
                className={classNames('survey-button w-100', item.answer && 'answered')}
                disabled={disabled}
                onClick={onNext}>
                Next
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

export default SurveyMulti;
