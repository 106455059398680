import axios from 'axios';
import moment from 'moment';

import { calcWeight, calcEnergy } from '../components/nutrients/nutrientsData';

export const loadDiary = async(onDate = null) => {
  const targetDate = (onDate || moment()).format('YYYY-MM-DD');
  const url = `${process.env.REACT_APP_API_URL}/diary/${targetDate}`;
  const response = await axios.get(url);
  const foodItems = response.data;

  if (foodItems && foodItems.length) {
    foodItems.forEach((foodItem) => {
      foodItem.weight = calcWeight(
        foodItem.foodData,
        foodItem.foodType, 
        foodItem.portionId, 
        foodItem.quantity);
        
      foodItem.energy = calcEnergy(foodItem.foodData, foodItem.weight);
      
      if (foodItem.portionId && foodItem.foodData?.portions) {
        foodItem.portion = foodItem.foodData.portions
          .find((portion) => portion._id === foodItem.portionId);
      }
    });
  }
  
  return foodItems;
};

export const loadDiaryDays = async(args) => {
  const targetDate = (args.onDate || moment()).format('YYYY-MM-DD');
  const url = `${process.env.REACT_APP_API_URL}/diary/days/${targetDate}`;
  const response = await axios.get(url, {
    signal: args.controller.signal
  });

  return response.data ? response.data.map((date) => moment(date).format('YYYY-MM-DD')) : [];
};

export const loadDiaryAverage = async(days) => {
  const url = `${process.env.REACT_APP_API_URL}/diary/average/${days}`;
  const response = await axios.get(url);
  const foodAllItems = response.data;

  if (foodAllItems) {
    Object.keys(foodAllItems).forEach((targetDate) => {
      const foodItems = foodAllItems[targetDate];
      foodItems.forEach((foodItem) => {
        foodItem.weight = calcWeight(
          foodItem.foodData, 
          foodItem.foodType, 
          foodItem.portionId, 
          foodItem.quantity);
        
        if (foodItem.portionId && foodItem.foodData?.portions) {
          foodItem.portion = foodItem.foodData.portions
            .find((portion) => portion._id === foodItem.portionId);
        }
      });
    });
  }
  
  return foodAllItems;
};

export const loadDiaryIngredients = async(mask) => {
  const url = `${process.env.REACT_APP_API_URL}/diary/ingredients/${mask}`;
  const response = await axios.get(url);
  return response.data;
}

export const saveDiary = async(args) => {
  const { foodItems, targetDate } = args;
  const targetDateUtc = moment(targetDate).format('YYYY-MM-DD');
  const url = `${process.env.REACT_APP_API_URL}/diary/${targetDateUtc}`;
  const response = await axios.post(url, foodItems);
  
  return response.data;
};