import axios from 'axios';

export const sleep = (timeout) => new Promise((onTimeout) => setTimeout(onTimeout, timeout));

export const loadSurveyCategories = async () => {
  const url = `${process.env.REACT_APP_API_URL}/server/survey-categories`;
  const response = await axios.get(url);
  const data = (response.data || []).sort((a, b) => a.name.localeCompare(b.name));

  return data;
};

export const loadSurvey = async (category) => {
  const url = `${process.env.REACT_APP_API_URL}/server/survey/${category}`;
  const response = await axios.get(url)
  const data = response.data || [];
  
  return data
};

export const loadSurveyResultById = async (surveyId) => {
  const url = `${process.env.REACT_APP_API_URL}/server/survey-result-item/${surveyId}`;
  const response = await axios.get(url)
  const data = response.data;
  
  return data
};

export const loadSurveyResult = async () => {
  const url = `${process.env.REACT_APP_API_URL}/server/survey-result`;
  const response = await axios.get(url)
  const data = response.data || [];
  
  return data
};

export const saveSurveyResult = async (result) => {
  const url = `${process.env.REACT_APP_API_URL}/server/survey`;
  await axios.post(url, result);
};

