import React, { useEffect, useMemo } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  Button,
  FormHelperText,
  FormControl,
  TextField
}
from '@mui/material';

import SwipeSelect from '../common/SwipeSelect';
import { withFormik } from 'formik';

import { QUANTITY_LIST, MED_TYPE } from './medicinesData';
import { validInt } from '../nutrients/nutrientsData';

const INITIAL_VALUES = { name: null, quantity: 1 };

const MedicineDetailsForm = (props) => {
  const {
    visible,
    medType,
    medData,
    quantity,
    handleClose,
    
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm
  } = props;

  const isEdit = useMemo(() => validInt(quantity), [quantity]);

  useEffect(() => {
    const initialValues = { ...INITIAL_VALUES };

    if (quantity) {
      initialValues.quantity = quantity;
    }
    
    if (medData) {
      initialValues.name = medData.name || '';
    }

    resetForm({ values: initialValues });
  }, 
  [quantity, medData, resetForm]);

  return (
    <Dialog open={visible} fullWidth onClose={() => handleClose(null)}>
      <DialogTitle>
        Medicine Details
        <Divider />
      </DialogTitle>
      
      {medData && <DialogContent>
        {medType === MED_TYPE.DAILYMED && <iframe className="med-iframe" src={medData.href}></iframe>}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth className="pb-05"
              error={errors.quantity && touched.quantity}>
              <FormHelperText className="label">
                Quantity
              </FormHelperText>
              <SwipeSelect
                name="quantity"
                data={QUANTITY_LIST}
                textField="text"
                dataField="value"
                value={values.quantity}
                onChange={handleChange}
                onBlur={handleBlur}
                height="10rem"
                width="100%"
                slidesPerView={5}
              />
              <FormHelperText>{touched.quantity && errors.quantity}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {medType === MED_TYPE.CUSTOM && (
              <FormControl variant="standard" fullWidth>
                <FormHelperText className="label">
                  My Custom Name
                </FormHelperText>
                <TextField
                  fullWidth
                  name="name"
                  variant="standard"
                  value={values.name || ''}
                  disabled
                />
                <FormHelperText>{touched.quantity && errors.quantity}</FormHelperText>
              </FormControl>)}
          </Grid>
        </Grid>
      </DialogContent>}
      
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" onClick={handleSubmit}>
          {isEdit ? 'Update' : 'Add'}
        </Button>
        <Button onClick={() => handleClose(null)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MedicineDetailsDialog = withFormik({
  mapPropsToValues: () => INITIAL_VALUES,
  validate: (values, props) => {
    const errors = {};
    const quantity = parseFloat(values.quantity);
    if (isNaN(quantity)) {
      errors.quantity = 'Please enter the Quantity';
    }
    return errors;
  },

  handleSubmit: (values, formik) => {
    const { quantity } = values;
    const { medType, medData, handleClose } = formik.props;

    handleClose({
      medType,
      medData,
      quantity: parseFloat(quantity),
    })
  },
})(MedicineDetailsForm);

export default MedicineDetailsDialog;