// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#barChartContainer {
    box-sizing: border-box;
    padding: 60px;
    width: 80%;
    height: 640px;
    background-color: #fff;
  }`, "",{"version":3,"sources":["webpack://./src/components/casePage/metabolomicsPage/MetabolomicsPage.css"],"names":[],"mappings":"AAAA;IAGI,sBAAsB;IACtB,aAAa;IACb,UAAU;IACV,aAAa;IACb,sBAAsB;EACxB","sourcesContent":["#barChartContainer {\n    -webkit-box-sizing: border-box;\n    -moz-box-sizing: border-box;\n    box-sizing: border-box;\n    padding: 60px;\n    width: 80%;\n    height: 640px;\n    background-color: #fff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
