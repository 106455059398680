import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';

import {
  Box,
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Divider,
} from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useOAuth2 } from '@tasoskakour/react-use-oauth2';

import Layout from '../layout/Layout';
import LoaderWrapper from '../common/LoaderWrapper';

import { STORE } from '../../store';
import { useAsyncAppState } from '../../hooks/useAsyncAppState';
import { authUser, startSession, endSession, isSignedIn } from '../../services/session';
import { useSignedIn } from '../../hooks/useSignedIn';

import VisNetwork from '../visNetwork/visNetwork';
import './Login.css';

const paperStyle = {
  padding: 20,
  height: '50%',
  width: 280,
  margin: '0 auto',
};

const avatarStyle = {
  backgroundColor: '#2C383A',
  color: '#CB8921',
  margin: '30px auto',
};

const btnStyle = {
  margin: '30px 0',
  color: '#CB8921',
  backgroundColor: '#2C383A',
  fontWeight: 'bold',
};

const btnGoogleStyle = {
  margin: '15px 0',
  color: '#CB8921',
  backgroundColor: '#2C383A',
  fontWeight: 'bold',
};

const Login = () => {
  const [signedIn, setSignedIn ] = useSignedIn();
  const [user, authUserAsync] = useAsyncAppState(STORE.common.user, authUser);

  const navigate = useNavigate();

  useEffect(() => {
    if (signedIn && isSignedIn()) {
      navigate('/home');
    }
    else {
      setSignedIn(false);
      endSession();
    }
  }, [signedIn, setSignedIn, navigate])

  const [email, setEmail] = useState();
  const [emailSent, setEmailSent] = useState(false);
  const [label, setLabel] = useState('Sending request...');

  const sendRequestEmail = () => {
    if (!emailSent) {
      setEmailSent(true);
      axios
      .get(
        `${process.env.REACT_APP_API_URL}/sendaceessrequest/${email}`
      )
      .then(() => {
        setLabel('Completed');
        console.log('request sent for ' + email);
      })
      .catch((error) => {
        setLabel('Something went wrong');
        console.log(error);
      });
    }
  };

  const { loading, error, getAuth } = useOAuth2({
    authorizeUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirectUri: `${document.location.origin}/callback`,
    scope: 'https://www.googleapis.com/auth/gmail.metadata https://www.googleapis.com/auth/userinfo.email',
    responseType: 'code',
    exchangeCodeForTokenServerURL: `${process.env.REACT_APP_API_URL}/token`,
    exchangeCodeForTokenMethod: 'POST',
    onSuccess: async (payload) => {
      try {
        const user = await authUserAsync({
          authSecret: payload.access_token,
          authType: 'google'
        });

        startSession(user.token);
        setSignedIn(true);
        navigate('/home');
      }
      catch (error) {
        console.error('Error getting user info', error);
        endSession();
        setSignedIn(false);
      };
    },
    onError: (error) => console.log('Error', error),
  });

  return (
    <Layout>
      <LoaderWrapper items={[user]} show>
        <Box>
          <VisNetwork />
          <Box sx={{ marginBottom: '1rem'}}>
            {error && 'Error occurred...'}
            {loading && 'Loading...'}
          </Box>

          <Paper style={paperStyle}>
            <Grid align="center">
              <Avatar style={avatarStyle}>
                <LockOutlinedIcon />
              </Avatar>
            </Grid>

            {!emailSent ? (
              <TextField
                label="Email"
                placeholder="Enter Email"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                required
              />
            ) : (
              <TextField
                label={label}
                fullWidth
                disabled
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{
                  style: {
                    fontSize: 15,
                    paddingLeft: '70px',
                    fontWeight: 'bold',
                  },
                }}
              />
            )}

            <Button
              onClick={() => sendRequestEmail()}
              type="submit"
              color="primary"
              variant="contained"
              style={btnStyle}
              fullWidth
              disabled={loading}
            >
              Request Access
            </Button>

            <Divider />

            <Button
              onClick={getAuth}
              type="button"
              style={btnGoogleStyle}
              fullWidth
              className="login-with-google-btn"
              disabled={loading}
            >
              Sign in with Google
            </Button>
          </Paper>
        </Box>
      </LoaderWrapper>
    </Layout>
  );
};

export default Login;
