import { useState, useCallback, useRef } from 'react';

import { STORE } from '../../store';
import { useAsyncAppState  } from '../../hooks/useAsyncAppState';
import { resolveMapFile } from '../../services/mapEditor';

import LoaderWrapper from '../../components/common/LoaderWrapper';
import UploadDocsForm from '../../components/uploadDocs/UploadDocsForm';
import MapViewerLayout from './MapViewerLayout';

import MyTree from './treeData';
import './tree.css';

const MapViewerPage = () => {
  const refCount = useRef(0);
  const [, setResult] = useState(null);
  const [viewUrl, setViewUrl] = useState('');
  const [resolveFile, resolveFileAsync] = useAsyncAppState(
    STORE.areas.genetics.resolveFile,
    resolveMapFile
  );

  const onShowUrl = useCallback((url) => {
    setViewUrl(url);
  }, [setViewUrl]);

  const onHideUrl = useCallback(() => {
    setViewUrl(null);
  }, [setViewUrl]);
  
  const onUpload = useCallback(async (uploadResult) => {
    const fileResult = await resolveFileAsync(uploadResult.name);
    setResult(fileResult);
    
    refCount.current = refCount.current + 1;

    let myTree = new MyTree();
    myTree.$onInit(fileResult, refCount.current, onShowUrl, onHideUrl);

  }, [resolveFileAsync, onShowUrl, onHideUrl]);

  return (
    <MapViewerLayout viewUrl={viewUrl}>
      <LoaderWrapper items={[resolveFile]} show>
        <div>
          <div className='title-help-large text-uppercase d-flex align-items-center mb-2'>
            <span>Map Viewer</span>
          </div>
          <UploadDocsForm onRefresh={onUpload} isUnique />
        </div>
        <div className='gradient-divider w-100 my-2' />
        <div className='hierarchy-container'></div>
      </LoaderWrapper>
    </MapViewerLayout>
  )
};

export default MapViewerPage;