import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
}
from '@mui/material';

const BrandedResultsList = (props) => {
  const { items, onSelect } = props;
  return (
    <TableContainer component={Paper} className="search-result-paper">
      <Table sx={{ minWidth: '20rem' }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item._id} hover>
              <TableCell className="nutrient-option" onClick={() => onSelect(item._id)}>
                <div className="nutrient-title">{item.brandOwner}</div>
                <div className="nutrient-label">{item.description}</div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BrandedResultsList;