import React, { useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  Button,
  ButtonGroup,
}
from '@mui/material';

import { STORE } from '../../store';
import { loadSurvey } from '../../services/survey';
import { useAsyncAppState } from '../../hooks/useAsyncAppState';
import LoaderWrapper from '../common/LoaderWrapper';

const SelectSymptom = (props) => {
  const { 
    category,
    visible,
    handleClose 
  } = props;

  const [survey, loadSurveyAsync] = useAsyncAppState(
    STORE.areas.survey.items.load,
    loadSurvey
  );

  useEffect(() => {
    if (category && category.length) {
      loadSurveyAsync(category);
    }
  }, [category, loadSurveyAsync]);

  const onClick = useCallback((symptom) => {
    handleClose(symptom);
  }, [handleClose]);

  return (
    <Dialog open={visible} fullWidth onClose={() => handleClose(null)}>
      <DialogTitle>
        Please Select Predefined Symptom for: <b>{category}</b>
        <Divider />
      </DialogTitle>
      <LoaderWrapper items={[survey]}>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '0' }}>
          {survey.data && (
            <ButtonGroup orientation="vertical" fullWidth className="select-menu">
              {survey.data.map((question) => (
                <Button key={question._id} 
                  variant="contained" 
                  onClick={() => onClick(question.ask)}>
                  {question.ask}
                </Button>
              ))}
            </ButtonGroup>
          )}
        </DialogContent>
      </LoaderWrapper>
      <DialogActions>
        <Button onClick={() => handleClose(null)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectSymptom;