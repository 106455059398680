// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.severity-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.severity-label button {
  padding: 0 !important;
}

.select-menu button {
  justify-content: left !important;
  text-transform: none !important;
  text-align: left;
}

.select-menu button:nth-child(odd) {
  opacity: 0.8;
}

.nutrient-option {
  flex-direction: column;
  justify-content: left;
  border-bottom: 1px solid var(--style-tile-bordercolor);
}

.nutrient-title {
  width: 100%;
  font-size: smaller;
  text-align: left;
}

.nutrient-label {
  width: 100%;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/dialogs/dialogs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gCAAgC;EAChC,+BAA+B;EAC/B,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,sDAAsD;AACxD;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".severity-label {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.severity-label button {\n  padding: 0 !important;\n}\n\n.select-menu button {\n  justify-content: left !important;\n  text-transform: none !important;\n  text-align: left;\n}\n\n.select-menu button:nth-child(odd) {\n  opacity: 0.8;\n}\n\n.nutrient-option {\n  flex-direction: column;\n  justify-content: left;\n  border-bottom: 1px solid var(--style-tile-bordercolor);\n}\n\n.nutrient-title {\n  width: 100%;\n  font-size: smaller;\n  text-align: left;\n}\n\n.nutrient-label {\n  width: 100%;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
