import React, { useRef, useState, useEffect, useReducer, useCallback, useMemo } from 'react';
import moment from 'moment';

import { 
  Box, 
  Button,
  ButtonGroup,
  Collapse,
  Grid, 
  Divider,
  Switch,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
} from '@mui/material';

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useConfirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router';

import { STORE } from '../../store';
import { useDialog } from '../../hooks/useDialog';
import { useAsyncAppState  } from '../../hooks/useAsyncAppState';
import { useAppState } from '../../hooks/useAppState'
import { loadDiary, loadDiaryDays, saveDiary } from '../../services/diary';

import Layout from '../layout/Layout';
import LoaderWrapper from '../common/LoaderWrapper';
import FoodSelectDialog from './FoodSelectDialog';
import NutrientsGroup from './NutrientsGroup';
import FoodGroup from './FoodGroup';

import { ExpandMore, DiaryDay } from './DiaryDay';
import {
  FOOD_ACTIONS,
  dataReducer,
  getSaveFoodItems,
  calcFoodGroups,
} 
from './nutrientsData';

import './nutrients.css';

const NutrientsTrackPage = () => {
  const abortController = useRef(null);

  const [
    openFoodSelectDialog, 
    closeSelectFoodDialog, 
    foodSelectData
  ] = useDialog(STORE.areas.nutrient.dialog);

  const [loadDiaryData, loadDiaryAsync] = useAsyncAppState(
    STORE.areas.nutrient.load,
    loadDiary
  );

  const [diaryDays, loadDiaryDaysAsync] = useAsyncAppState(
    STORE.areas.nutrient.loadDates,
    loadDiaryDays
  );

  const [saveDiaryData, saveDiaryAsync] = useAsyncAppState(
    STORE.areas.nutrient.save,
    saveDiary
  );

  const [profile] = useAppState(STORE.common.profile);
  const [expanded, setExpanded] = useState(true);
  const [targetDate, setTargetDate] = useState(moment());

  const confirm = useConfirm();
  const navigate = useNavigate();

  const [openFoodDetailsDialog] = useDialog(STORE.areas.nutrient.dialogDetails);

  const [data, dataDispatch] = useReducer(dataReducer, {
    foodItems: [],
    nutrients: [],
    totalWeight: 0,
    totalEnergy: 0,
    showEnergy: true,
    showFoodType: true,
    iteration: 0,
  });

  const foodGroups = useMemo(() => calcFoodGroups(data.foodItems, data.showFoodType), 
    [data.foodItems, data.showFoodType]);

  useEffect(() => {
    const save = async () => {
      await saveDiaryAsync({
        targetDate,
        foodItems: getSaveFoodItems(data.foodItems) 
      });
      
      abortController.current = new AbortController();
      await loadDiaryDaysAsync({
        controller: abortController.current, 
        onDate: moment(targetDate).startOf('month')
      });
    };
    
    if (data.iteration > 0) {
      save();
    }
  }, [targetDate, data.iteration, data.foodItems, saveDiaryAsync, loadDiaryDaysAsync]);

  const onLoadDiary = useCallback(async (onDate) => {
    const foodItems = await loadDiaryAsync(onDate);
    dataDispatch({
      type: FOOD_ACTIONS.INIT,
      foodItems: foodItems || [],
      profile,
    });

  }, [profile, loadDiaryAsync, dataDispatch]);

  const onMonthChange = useCallback((onDate) => {
    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();
    loadDiaryDaysAsync({
      controller: abortController.current, 
      onDate
    });
  }, [loadDiaryDaysAsync]);

  useEffect(() => {
    onLoadDiary(null);
    onMonthChange(moment().startOf('month'));
  }, [profile, loadDiaryAsync, onMonthChange, onLoadDiary, dataDispatch]);

  const onSetTargetDate = useCallback((onDate) => {
    onLoadDiary(onDate);
    setTargetDate(onDate);
  }, [onLoadDiary, setTargetDate]);

  const onAddFood = useCallback(async (foodTime) => {
    const foodItem = await openFoodSelectDialog({ foodTime });

    if (foodItem) {
      dataDispatch({
        type: FOOD_ACTIONS.ADD,
        foodItem,
        profile,
      });
    }
  }, [profile, openFoodSelectDialog, dataDispatch]);

  const onEditFood = useCallback(async (foodItem) => {
    const foodIndex = foodItem.index;
    const updatedFoodItem = await openFoodDetailsDialog({
      foodData: foodItem.foodData,
      foodType: foodItem.foodType,
      foodTime: foodItem.foodTime,
      portionId: foodItem.portion ? foodItem.portion._id : null,
      quantity: foodItem.quantity
    });

    if (updatedFoodItem) {
      dataDispatch({
        type: FOOD_ACTIONS.UPDATE,
        foodItem: updatedFoodItem,
        foodIndex, 
        profile,
      });
    }
  }, [profile, openFoodDetailsDialog, dataDispatch]);

  const onDeleteFood = useCallback(async (foodItem) => {
    let valid = true;
    try {
      await confirm({
        title: 'Please confirm',
        description: 'Delete Food from Diary?' 
      });
    }
    catch {
      valid = false;
    }

    if (valid) {
      const foodIndex = foodItem.index;
      dataDispatch({
        type: FOOD_ACTIONS.REMOVE,
        foodIndex,
        profile,
      });
   }
  }, [profile, confirm, dataDispatch]);

  const onChangeShowMode = useCallback((event) => {
    dataDispatch({
      type: FOOD_ACTIONS.SHOW_MODE, 
      showEnergy: event.target.checked
    });
  }, [dataDispatch]);

  const onChangeFoodType = useCallback((event) => {
    dataDispatch({
      type: FOOD_ACTIONS.SHOW_FOOD_TYPE, 
      showFoodType: event.target.checked,
      profile,
    });
  }, [profile, dataDispatch]);

  const onExit = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  return (
    <Layout>
      <Box className="vertical-flex h-100">
        <h3>
          Track your nutrition on
        </h3>
        <LoaderWrapper items={[loadDiaryData, saveDiaryData]} />
        <Box className="vertical-flex-grow">
          <Grid container>
            <Grid item xs={12} lg={3} className="placeholder-cell" />
            <Grid item xs={12} lg={6} className="main-cell">
              <Grid container className="justify-content-center pb-1">
                <Grid item>
                  <ButtonGroup variant="contained">
                    <Button color="primary" size="large"
                      onClick={() => onAddFood('breakfast')}>
                      Breakfast
                    </Button>
                    <Button color="primary" size="large"
                      onClick={() => onAddFood('lunch')}>
                      Lunch
                    </Button>
                    <Button color="primary" size="large"
                      onClick={() => onAddFood('dinner')}>
                      Dinner
                    </Button>
                  </ButtonGroup>
                </Grid>
                
                <Grid container>
                  <Grid xs={12} item className="d-flex justify-content-center">
                    <h4 className="smaller">
                      Weight   
                      <Switch checked={data.showEnergy} onChange={onChangeShowMode} />
                      Energy
                      <span className="mx-1">|</span>
                      Supplement
                      <Switch checked={data.showFoodType} onChange={onChangeFoodType} />
                      All Food
                    </h4>
                  </Grid>
                </Grid>
                
                {foodGroups.breakfast.items.length > 0 && (
                  <FoodGroup foodTime="Breakfast" 
                    items={foodGroups.breakfast.items}
                    totals={foodGroups.breakfast.totals}
                    showEnergy={data.showEnergy}
                    onEditFood={onEditFood}
                    onDeleteFood={onDeleteFood} />
                )}

                {foodGroups.lunch.items.length > 0 && (
                  <FoodGroup foodTime="Lunch" 
                    items={foodGroups.lunch.items}
                    totals={foodGroups.lunch.totals}
                    showEnergy={data.showEnergy}
                    onEditFood={onEditFood}
                    onDeleteFood={onDeleteFood} />
                )}
              
                {foodGroups.dinner.items.length > 0 && (
                  <FoodGroup foodTime="Dinner" 
                    items={foodGroups.dinner.items}
                    totals={foodGroups.dinner.totals}
                    showEnergy={data.showEnergy}
                    onEditFood={onEditFood}
                    onDeleteFood={onDeleteFood} />
                )}

                {foodGroups.totals.weight > 0 && (
                  <TableContainer component={Paper} sx={{ marginBottom: '1rem' }}>
                    <Table sx={{ minWidth: '20rem' }} size="small" className="food-table">
                      <TableFooter className="food-totals" >
                        <TableRow>
                          <TableCell sx={{ width: '70%' }}>
                            Total:
                          </TableCell>
                          <TableCell colSpan={2}>
                            {data.showEnergy ? `${foodGroups.totals.energy} kсal` : `${foodGroups.totals.weight} g`}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                )}

                {data.nutrients.length > 0 && (
                  <>
                    <h4>Nutrients Summary</h4>
                    {data.nutrients.map((group) => 
                      <NutrientsGroup 
                        key={group.category} 
                        category={group.category} 
                        items={group.items} />)}
                  </>    
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={3} className="calendar-cell">
              <div className="d-flex justify-content-between">
                <ExpandMore
                  expanded={expanded}
                  onClick={() => setExpanded(!expanded)}
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </div>

              <Divider className="mb-1" />
              
              <Collapse in={expanded} timeout="auto">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateCalendar
                    onMonthChange={onMonthChange}
                    loading={diaryDays.loading}
                    value={targetDate} 
                    onChange={onSetTargetDate}
                    slots={{
                      day: DiaryDay,
                    }}
                    slotProps={{
                      day: {
                        diaryDays: diaryDays.data || []
                      },
                    }}
                  />
                </LocalizationProvider>
              </Collapse>
            </Grid>
          </Grid>
        </Box>

        <Box className="buttons-footer">
          <Divider />
            <Grid container className="justify-content-center pb-1">
              <Grid item xs={12} md={5} lg={2}>
                <Button color="primary" size="large"
                  variant="contained"
                  className="w-100"
                  onClick={onExit}>
                  Back to Home Page
                </Button>
              </Grid>
            </Grid>
        </Box>
      </Box>

      <FoodSelectDialog
        handleClose={closeSelectFoodDialog}
        visible={foodSelectData.visible}
        foodTime={foodSelectData.data?.foodTime}
      />
    </Layout>
  );
}

export default NutrientsTrackPage;
