import React, { useMemo } from 'react';
import classNames from 'classnames';

import { FOOD_TYPE, nutrientNormColor } from './nutrientsData';

const NutrientProgressBar = ({ current, max, foods }) => {
  const isNA = !max && max <= 0;
  const hasSupplement = foods.some(((foodItem) => foodItem.foodType === FOOD_TYPE.SUPPLEMENT));

  const progress = useMemo(() => {
    return  isNA ? null : Math.round(100 * current / max)
  }, 
  [current, max]);

  const title = useMemo(() => {
    const result = isNA ? 'N / A' : `${progress}%`;
    return hasSupplement && progress > 200 ? '' : result;
  },
  [progress, hasSupplement, isNA]);

  return (
    <div className={classNames('nutrient-progress-bar', current <= 0 && 'zero')}>
      <div className="nutrient-progress-bar-inner" 
        style={{
          left: `${progress <= 100 ? progress - 100 : 0}%`, 
          backgroundColor: nutrientNormColor(progress, hasSupplement)
        }} />
      <div className="nutrient-progress-value">{title}</div>
    </div>
  );
};

export default NutrientProgressBar;