import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Grid, Divider, Alert } from '@mui/material';
import { useNavigate } from 'react-router';

import { STORE } from '../../store';
import { useAsyncAppState } from '../../hooks/useAsyncAppState';
import { loadSurveyResult } from '../../services/survey';

import Layout from '../layout/Layout';
import LoaderWrapper from '../common/LoaderWrapper';

import SurveyResultBubbles from './SurveyResultBubbles';

import './survey.css';

const SurveyResultPage = () => {
  const navigate = useNavigate();
  const [surveyResult, loadServeyResultAsync] = useAsyncAppState(
    STORE.areas.survey.result,
    loadSurveyResult
  );

  const surveys = useMemo(
    () => (surveyResult.data || []),
    [surveyResult.data]
  );

  useEffect(() => {
    loadServeyResultAsync();
  }, [loadServeyResultAsync]);

  const onExit = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  return (
    <Layout>
      <LoaderWrapper items={[surveyResult]}>
        <Box className="vertical-flex h-100">
          <h3>
            Survey Results
          </h3>
          <Box className="vertical-flex-grow">
            <Grid container className="justify-content-center h-100">
              <Grid item xs={12} md={7} lg={5} className="survey-graph-cell">
                <Box className="survey-graph-container">
                  {surveys.map((survey) => <SurveyResultBubbles key={survey._id} survey={survey} />)} 
                  {!surveys.length && (
                    <Alert>
                      No Surveys Found
                    </Alert>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box className="survey-footer">
            <Divider />
            <Grid container className="justify-content-center pb-1">
              <Grid item xs={12} md={5} lg={2}>
                <Button color="primary" size="large"
                  variant="contained"
                  className="w-100"
                  onClick={onExit}>
                  Back to Home Page
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </LoaderWrapper>
    </Layout>
  );
}

export default SurveyResultPage;
