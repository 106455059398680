import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { STORE } from '../store';
import { useAppState } from './useAppState';
import { endSession, getCapability } from '../services/session';

export const useCapability = (capability) => {
  const [user] = useAppState(STORE.common.user);
  const navigate = useNavigate();

  useEffect(() => {
    const capabilities = Array.isArray(capability) ? capability : [capability];
    const valid = capabilities.every((capability) => getCapability(user, capability) !== null);
    if (!valid) {
      endSession();
      navigate('/');
    }
  }, [user, capability, navigate]);
};
