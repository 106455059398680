import React, { useEffect, useCallback } from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Select,
  IconButton,
  InputLabel,
  InputAdornment,
  FormControl,
  MenuItem,
  FormHelperText,
  Grid,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import { withFormik } from 'formik';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DATE_FORMAT, ROLES } from '../../constants/common';
import { detectLocation } from '../../services/userProfile';
import { validateString, validateNumber } from '../../services/validation';

const initValues = () => {
  return {
    fullName: '',
    birthDate: null,
    gender: '',
    feet: '',
    inches: '',
    weight: '',
    location: '',
    role: ROLES.PATIENT
  }
};

const mapValues = (profileData) => {
  let feet = 0;
  let inches = 0;

  if (!profileData) {
    return initValues();
  }

  if (profileData.height) {
    const height = parseInt(profileData.height, 10);
    if (!isNaN(height)) {
      feet = Math.floor(height / 12);
      inches = height % 12;
    }
  }

  return {
    fullName: profileData.fullName,
    birthDate: moment(profileData.dob),
    gender: profileData.gender,
    location: profileData.location,
    weight:  profileData.weight,
    feet: feet > 0 ? String(feet) : '',
    inches: inches > 0 ? String(inches) : '',
    role: profileData.role
  }
};

const saveValues = async(values, handleSignUpClose) => {
  const profileData = {
    fullName: values.fullName,
    dob: values.birthDate.toDate(),
    gender: values.gender,
    height: parseInt(values.feet) * 12 + parseInt(values.inches),
    weight: parseInt(values.weight),
    location: values.location
  };

  handleSignUpClose(profileData);
};

const EditPersonalForm = (props) => {
  const {
    visible,
    profile,
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    handleSignUpClose,
  } = props;

  const setLocation = useCallback(async () => {
    const locationData = await detectLocation();
    if (locationData) {
      setFieldValue('location', `${locationData.city}, ${locationData.region}`);
    }
  }, [setFieldValue]);

  useEffect(() => {
    const load = async() => {
      const values = mapValues(profile);
      resetForm({ values });
    };

    if (visible) {
      load();
    }
  }, [visible, profile, resetForm]);

  const onClose = useCallback(() => {
    if (profile) {
      handleSignUpClose(null);
    }
  }, [profile, handleSignUpClose]);

  return (
    <Dialog open={visible} fullWidth onClose={onClose}>
      <DialogTitle>
        {profile === null ? 'Sign Up' : 'Edit Personal Info'}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          fullWidth
          variant="standard"
          name="fullName"
          label="Full Name"
          value={values.fullName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.fullName && touched.fullName}
          helperText={errors.fullName}
        />

        <br />
        <br />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            name="birthDate"
            label="Date Of Birth"
            value={values.birthDate}
            onChange={(momentDate) => {
              setFieldValue('birthDate', momentDate)
            }}
            error={Boolean(errors.birthDate && touched.birthDate)}
            format={DATE_FORMAT}
            slotProps={{ textField: {
              name: "birthDate",
              variant: 'standard',
              fullWidth: true,
              error: Boolean(errors.birthDate && touched.birthDate),
              helperText: touched.birthDate && errors.birthDate,
              onBlur: handleBlur
            }}}
          />
        </LocalizationProvider>

        <br />
        <br />
        <FormControl variant="standard" fullWidth error={errors.gender && touched.gender}>
          <InputLabel id="age-label">Gender</InputLabel>
          <Select
            // className="select-text-left"
            name="gender"
            labelId="age-label"
            value={values.gender}
            onChange={handleChange}
            onBlur={handleBlur}>
            <MenuItem value="F">F</MenuItem>
            <MenuItem value="M">M</MenuItem>
          </Select>
          <FormHelperText>{errors.gender}</FormHelperText>
        </FormControl>

        <br />
        <br />
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <TextField
              fullWidth
              variant="standard"
              name="feet"
              label="Height feet, '"
              type="number"
              value={values.feet}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.feet && touched.feet}
              helperText={errors.feet}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              fullWidth
              name="inches"
              variant="standard"
              label="Height inches, ''"
              type="number"
              value={values.inches}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.inches && touched.inches}
              helperText={errors.inches}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              fullWidth
              name="weight"
              variant="standard"
              label="Weight, lbs"
              type="number"
              value={values.weight}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.weight && touched.weight}
              helperText={errors.weight}
            />
          </Grid>
        </Grid>

        <br />
        <br />

        <TextField
          fullWidth
          variant="standard"
          name="location"
          label="Location"
          value={values.location}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.location && touched.location}
          helperText={errors.location}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton
                onClick={setLocation}
                edge="end"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit}>
          {profile === null ? 'Continue' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const EditPersonalDialog = withFormik({
  mapPropsToValues: initValues,

  validate: values => {
    const errors = {};

    let message = validateString(values.fullName, 'Please enter your name');
    if (message) errors.fullName =  message;

    if (!values.birthDate || !values.birthDate.isValid()) {
      errors.birthDate =  message;
    }

    message = validateString(values.gender, 'Please select your gender');
    if (message) errors.gender =  message;

    message = validateNumber(values.feet, 'Please enter correct height feet');
    if (message) errors.feet =  message;

    if (values.inches) {
      message = validateNumber(values.inches, 'Please enter correct height inches');
      if (message) errors.inches =  message;
    }

    message = validateNumber(values.weight, 'Please enter correct weight');
    if (message) errors.weight =  message;

    message = validateString(values.location,
      'Please enter a valid location  (e.g., Stanford, CA)',
      /^[a-zA-Z\s-]+,\s*[a-zA-Z]{2}$/);
    if (message) errors.location =  message;

    return errors;
  },

  handleSubmit: (values, formik) => {
    saveValues(values, formik.props.handleSignUpClose);
  },
})(EditPersonalForm);

export default EditPersonalDialog;