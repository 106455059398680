import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Popover
} from '@mui/material';

import { nutrientTitle, roundAmount } from './nutrientsData';
import NutrientProgressBar from './NutrientProgressBar';

const NutrientsGroup = ({ category, items }) => {
  const [popoverData, setPopoverData] = useState(null);

  const handlePopoverOpen = (event, nutrientItem) => {
    setPopoverData({
      anchor: event.currentTarget,
      nutrientItem
    });
  };

  const handlePopoverClose = () => {
    setPopoverData(null);
  };
  
  const popoverFoods = popoverData?.nutrientItem?.foods || [];
  
  if (!items.length) {
    return null;
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ marginBottom: '1rem' }}>
        <Table sx={{ minWidth: '20rem' }} size="small" className="food-table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '70%' }}>
                {category}
              </TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((nutrientItem, index) => (
              <TableRow key={`${nutrientItem.nutrient._id}${index}`}>
                <TableCell>
                  {nutrientItem.nutrient.name}
                </TableCell>
                <TableCell
                  sx={{ cursor: 'pointer' }}
                  onMouseEnter={(event) => handlePopoverOpen(event, nutrientItem)} 
                  onMouseLeave={handlePopoverClose}>
                  <Grid container>
                    <Grid item>
                      <b>{nutrientTitle(nutrientItem)}</b>
                    </Grid>
                    <Grid item className="col-progress">
                      <NutrientProgressBar 
                        max={nutrientItem.norm}
                        foods={nutrientItem.foods}
                        current={nutrientItem.total} />
                    </Grid>
                  </Grid> 
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Popover
        anchorEl={popoverData?.anchor}
        open={Boolean(popoverData)}
        sx={{ pointerEvents: 'none' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Card>
          <CardHeader className="nutrient-card-header"
            title={popoverData?.nutrientItem.nutrient.name}
          />
          <CardContent className="nutrient-card-content">
            <Table size="small" className="food-table">
              <TableBody>
              {popoverFoods.map((food) => (
                <TableRow key={food.foodId}>
                  <TableCell>
                    {food._id}{food.foodName}
                  </TableCell>
                  <TableCell>
                    {roundAmount(food.amount)} {popoverData?.nutrientItem.nutrient.unitName}
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>  
            </Table>  
          </CardContent>
        </Card>  
      </Popover>
    </>
  );
};

export default NutrientsGroup;