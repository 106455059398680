import { Button, IconButton, Modal } from 'rsuite';
import icon from '../../../assets/img/supplement.png';
import { useState } from 'react';

const Supplements = ({ data }) => {
  const { supplements } = data;
  const [modalData, setModalData] = useState(null);
  return (
    <div>
      <h2>
        Supplements
      </h2>

      <div className="row">
        {supplements.map((supplementItem) => (
          <div key={supplementItem.foodId} className="col-md-6 col-lg-3 d-flex">
            <div className="supplement-icon">
              <IconButton
                onClick={() => setModalData(supplementItem)} 
                circle appearance="default"
                icon={<img src={icon} alt="Supplement" /> } />
            </div>
            <div className="med-item">
              <Button 
                onClick={() => setModalData(supplementItem)} 
                className="med-name" appearance="link">
                {supplementItem.description}
              </Button>
              <div className="med-producer">
                {supplementItem.brand}
              </div>
              <div className="med-quantity">
                Quantity: {supplementItem.quantity}
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal className="med-modal"
        open={Boolean(modalData)} 
        onClose={() => setModalData(null)}>
        <Modal.Header />
        {modalData && (
          <Modal.Body className="supplement">
            <h3>
              <img src={icon} alt="Supplement" />SUPPLEMENT
            </h3>
            <h2>
              {modalData.description}
            </h2>
            <div><b>Brand:</b> {modalData.brand}</div>
            <div><b>Note:</b> {modalData.note}</div>
            <div><b>Unit:</b> {modalData.unit}</div>
            <div><b>Daily Max:</b> {modalData.daily}</div>
            <div><b>Quantity:</b> {modalData.quantity}</div>
            <hr />
            {modalData.nutrients.length && (
              <div className="container">
                {modalData.nutrients.map((nutrient) => (
                  <div key={nutrient._id} className="row">
                    <div className="col-6 cell-header">
                      {nutrient.name}
                    </div>
                    <div className="col-6 cell-content">
                      {nutrient.amount} {nutrient.unit}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Modal.Body>)}
      </Modal>
    </div>
  );
};

export default Supplements;