import React from 'react';

import {
  Grid,
  Button,
  TextField
} from '@mui/material';

import { withFormik } from 'formik';
import './upload.css';

const ProfileFindFormContent = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm
  } = props;

  return (
    <Grid container spacing={2} className="mb-1">
      <Grid item xs={6} md={5}>
        <TextField
          fullWidth
          name="name"
          label="Full Name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.name && touched.name}
          helperText={errors.name}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          fullWidth
          name="email"
          label="Email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email && touched.email}
          helperText={errors.email}
        />
      </Grid>
      <Grid item xs={12} md={3} className="find-form-buttons">
        <Button color="primary" size="large"
          variant="contained"
          onClick={handleSubmit}>
          Find
        </Button>
        <Button color="primary" size="large"
          variant="outlined"
          onClick={resetForm}>
          Reset
        </Button>
      </Grid>
    </Grid>
  );
}

const ProfileFindForm = withFormik({
  mapPropsToValues: () => ({
    name: '',
    email: ''
  }),

  validate: () => {
    return {};
  },

  handleSubmit: (values, formik) => {
    formik.props.onFind(values);
  },
})(ProfileFindFormContent);

export default ProfileFindForm;
