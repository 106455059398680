import axios from 'axios';

import { SESSION_KEYS } from '../constants/common';

export const authUser = async (authData) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth`, authData, {
    withCredentials: true
  });
  return response.data;
};

export const loadUser = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`);
  return response.data;
};

export const startSession = (accessToken) => {
  localStorage.setItem(SESSION_KEYS.ACCESS_TOKEN, accessToken);
};

export const endSession = () => {
  localStorage.removeItem(SESSION_KEYS.ACCESS_TOKEN);
};

export const isSignedIn = () => {
  const accessToken = localStorage.getItem(SESSION_KEYS.ACCESS_TOKEN);
  return typeof (accessToken) !== 'undefined' && accessToken !== null;
}

export const getToken = () => {
  return localStorage.getItem(SESSION_KEYS.ACCESS_TOKEN);
}

export const getCapability = (user, settingName) => {
  const userData = user?.data;
  return userData ? userData[settingName] : null;
};
