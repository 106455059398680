import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
}
from '@mui/material';

import { useConfirm } from 'material-ui-confirm';

import BackupIcon from '@mui/icons-material/Backup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { saveGeneProfile, loadGeneProfiles, deleteGeneProfile } from '../../services/genetics';
import { CRUD_LOAD, CRUD_SAVE } from './ResolveGeneUploadProfilePage';
import LoaderWrapper from '../common/LoaderWrapper';

const SaveContent = (props) => {
  const [name, setName] = useState('');
  const { saveHandle } = props;
  
  return (
    <TextField
      fullWidth
      className="mt-05"
      label="Enter Profile Name"
      name="profileName"
      value={name}
      onChange={(event) => {
        setName(event.target.value);
      }}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          saveHandle(event.target.value);
        }
      }}
      onBlur={() => {}}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => saveHandle(name)}>
              <BackupIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

const LoadContent = (props) => {
  const { items, onSelect, deleteHandle } = props;

  return items && (
    <TableContainer component={Paper} className="w-100">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="10%"></TableCell>
            <TableCell width="89%">Name</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow hover key={item._id}>
              <TableCell>
                <Checkbox 
                  onChange={(event) => onSelect(item._id, event.target.checked)}
                />
              </TableCell>
              <TableCell>
                {item.name}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => deleteHandle(item._id, item.name)}>
                  <DeleteForeverIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const GeneCrudDialog = (props) => {
  const {
    visible,
    handleClose,
    mode
  } = props;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState(null);
  const [ids, setIds] = useState([]);
  
  const onSelect = useCallback((id, checked) => {
    const index = ids.indexOf(id);
    if (checked) {
      if (index < 0) setIds([...ids, id]);
    }
    else {
      if (index >= 0) setIds([...ids].filter((cid) => cid !== id));
    }
  }, [ids, setIds]);

  const onLoad = useCallback(async() => {
    setLoading(true);
    try {
      const loadedItems = await loadGeneProfiles();
      setItems(loadedItems)
      setLoading(false);
    }
    catch (ex) {
      setLoading(false);
    }
  }, [setLoading, setItems]);

  const onSaveProfile = useCallback(async(name) => {
    if (name && name.trim() !== '') {
      setLoading(true);
      try {
        await saveGeneProfile(name);
        handleClose();
        setLoading(false);
      }
      catch (ex) {
        setLoading(false);
      }
    }
  }, [setLoading, handleClose]);

  const confirm = useConfirm();

  const onDeleteProfile = useCallback(async (id, name) => {
    let valid = true;
    try {
      await confirm({
        title: 'Please confirm',
        description: `Delete Profile "${name}"?` 
      });
    }
    catch {
      valid = false;
    }

    if (valid) {
      try {
        await deleteGeneProfile(id);
        await onLoad();
        setLoading(false);
      }
      catch (ex) {
        setLoading(false);
      }
    }
  }, [confirm, setLoading, onLoad]);

  useEffect(() => {
    if (visible && mode === CRUD_LOAD) {
      setIds([]);
      onLoad();
    }
  }, [visible, mode, onLoad]);

  return (
    <Dialog open={visible} fullWidth maxWidth="sm" 
      keepMounted onClose={() => handleClose(null)}>
      <DialogTitle>
        {mode === CRUD_LOAD && 'Load'}{mode === CRUD_SAVE && 'Save'} Gene Report
        <Divider />
      </DialogTitle>
      
      <DialogContent>
        <LoaderWrapper loading={loading} show>
          {mode === CRUD_SAVE && <SaveContent saveHandle={onSaveProfile} />}
          {mode === CRUD_LOAD && (
            <LoadContent items={items} 
              onSelect={onSelect}
              deleteHandle={onDeleteProfile}
            />
          )}
        </LoaderWrapper>
      </DialogContent>
      
      <DialogActions>
        {mode === CRUD_LOAD && (
          <Button onClick={() => handleClose(ids)} disabled={!ids.length}>
            Load Profiles
          </Button>
        )}
        <Button onClick={() => handleClose(null)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GeneCrudDialog;