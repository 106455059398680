// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-select {
  background: #D2D2D2;
  box-shadow: 0px 0px 10px 0px rgba(140, 140, 140, 0.6);
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid #BBB;
  min-width: 8rem;
}

.swiper-select-item {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: var(--style-tile-primary2);
  color: white;
  border-radius: 0.25rem;
}

.swiper-select-item span {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.swiper-slide-active {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/common/swipeSelect.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qDAAqD;EACrD,gBAAgB;EAChB,qBAAqB;EACrB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,mBAAmB;EACnB,sCAAsC;EACtC,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".swiper-select {\n  background: #D2D2D2;\n  box-shadow: 0px 0px 10px 0px rgba(140, 140, 140, 0.6);\n  overflow: hidden;\n  border-radius: 0.5rem;\n  border: 1px solid #BBB;\n  min-width: 8rem;\n}\n\n.swiper-select-item {\n  display: flex !important;\n  justify-content: center;\n  align-items: center;\n  background: var(--style-tile-primary2);\n  color: white;\n  border-radius: 0.25rem;\n}\n\n.swiper-select-item span {\n  margin-left: 0.5rem;\n  margin-right: 0.5rem;\n}\n\n.swiper-slide-active {\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
