import React, { useEffect, useCallback, useMemo } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Zoom,
} from '@mui/material';

import classNames from 'classnames';
import { useNavigate } from 'react-router';

import Layout from '../layout/Layout';
import LoaderWrapper from '../common/LoaderWrapper';

import { STORE } from '../../store';
import { loadSurveyResult } from '../../services/survey';
import { useAsyncAppState } from '../../hooks/useAsyncAppState';
import { useAppState } from '../../hooks/useAppState';
import './survey.css';

const SurveyCategoryPage = () => {
  const navigate = useNavigate();
  const [surveyCategories] = useAppState(STORE.areas.survey.category.load);
  const [surveyResult, loadServeyResultAsync] = useAsyncAppState(
    STORE.areas.survey.result,
    loadSurveyResult
  );

  const [, setSelectedCategory] = useAppState(
    STORE.areas.survey.category.select
  );

  useEffect(() => {
    setSelectedCategory(null);
    loadServeyResultAsync();
  }, [loadServeyResultAsync, setSelectedCategory]);

  const onStart = useCallback((category) => {
    setSelectedCategory(category);
    navigate('/survey');
  }, [navigate, setSelectedCategory]);

  const categoriesData = useMemo(() => {
    if (!surveyCategories.data) {
      return {
        categories: [],
        completedCategories: []
      };
    }

    let categories = [...surveyCategories.data];
    let completedCategories = [];

    if (surveyResult.data && surveyResult.data.length) {
      const surveys = surveyResult.data;
      categories = categories.filter((category) => {
        const found = surveys.find((survey) => survey.category === category.name);
        if (found) {
          completedCategories.push(category);
        }
        return !found;
      });
    }

    return {
      categories: categories.sort((a, b) => b.type.localeCompare(a.type)),
      completedCategories: completedCategories.sort((a, b) => b.type.localeCompare(a.type))
    }
  }, [surveyCategories.data, surveyResult.data]);

  return (
    <Layout className="survey-background">
      <LoaderWrapper items={[surveyCategories, surveyResult]}>
        <Box className="vertical-flex h-100">
          <h3>
            Please, select a Survey
          </h3>

          <Box className="vertical-flex-grow">
            <Box className="vertical-flex h-100">
              <Box className="vertical-flex-grow">
                <Zoom in>
                  <Grid container className="justify-content-center pb-1">
                    <Grid item xs={12} sm={8} md={6} lg={3}>
                      <ButtonGroup orientation="vertical" fullWidth>
                        {categoriesData.categories.map((category) => (
                          <Button key={category.code} 
                            variant="contained" 
                            onClick={() => onStart(category)} 
                            className="survey-select-button">
                            <Box className={classNames('survey-img', `survey-img-${category.code}`)}></Box>
                            {category.name}
                          </Button>
                        ))}
                      </ButtonGroup>

                      {categoriesData.completedCategories && (
                      <>
                        <h3>
                          Completed Surveys
                        </h3>
                        <Divider />
                        <ButtonGroup orientation="vertical" fullWidth>  
                          {categoriesData.completedCategories.map((category) => (
                            <Button key={category.code} 
                              variant="contained" disabled
                              className="survey-select-button completed">
                              <Box className={classNames('survey-img', `survey-img-${category.code}`)}></Box>
                              {category.name}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </>)}  
                    </Grid>
                  </Grid>
                </Zoom>
              </Box>
            </Box>  

          </Box>
        </Box>
      </LoaderWrapper>
    </Layout>
  );
}

export default SurveyCategoryPage;
