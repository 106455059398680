// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 1px;
}

.node text {
  font-size: 14px;
}

.selected {
  font-weight: bold;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 1px;
}

.url-link {
  text-decoration: underline;
}

.viewer-side {
  max-width: 0;
  transition: max-width 400ms;
}

.viewer-side.open {
  max-width: 40%;
}`, "",{"version":3,"sources":["webpack://./src/rs-components/map-viewer/tree.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".node circle {\n  fill: #fff;\n  stroke: steelblue;\n  stroke-width: 1px;\n}\n\n.node text {\n  font-size: 14px;\n}\n\n.selected {\n  font-weight: bold;\n}\n\n.link {\n  fill: none;\n  stroke: #ccc;\n  stroke-width: 1px;\n}\n\n.url-link {\n  text-decoration: underline;\n}\n\n.viewer-side {\n  max-width: 0;\n  transition: max-width 400ms;\n}\n\n.viewer-side.open {\n  max-width: 40%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
