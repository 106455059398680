import React from 'react';
import { Grid } from '@mui/material';
import classNames from 'classnames';

import TopBar from '../topBar/TopBar';

const Layout = (props) => {
  const { children, className, noPadding } = props;

  return (
    <Grid container
      className={classNames('main-container', className)}>
      <Grid item xs={12} className="header">
        <TopBar />
      </Grid>

      <Grid item sm={12}
        className={classNames('content', { 'no-padding': noPadding })}>
        {children}
      </Grid>
    </Grid>
  );
}

export default Layout;
