import axios from 'axios';

export const FOOD_TYPE = {
  COMMON: 'common',
  BRANDED: 'branded',
  SUPPLEMENT: 'supplement',
  FAVORITE: 'favorite'
};

export const findBrandedFood = async(mask, brand, isSupplement) => {
  const query = `mask=${mask}&brand=${brand}&isSupplement=${isSupplement}`;
  const url = `${process.env.REACT_APP_API_URL}/server/nutrient/branded?${query}`;
  const response = await axios.get(url);
  
  return response.data;
};

export const findBrand = async(mask, isSupplement) => {
  const query = `mask=${mask}&isSupplement=${isSupplement}`;
  const url = `${process.env.REACT_APP_API_URL}/server/nutrient/brand?${query}`;
  const response = await axios.get(url);
  
  return response.data;
};

export const loadBranded = async(_id) => {
  const url = `${process.env.REACT_APP_API_URL}/nutrient/branded/${_id}`;
  const response = await axios.get(url);
  
  return response.data;
};

export const loadSupplement = async(_id) => {
  const url = `${process.env.REACT_APP_API_URL}/nutrient/supplement/${_id}`;
  const response = await axios.get(url);
  
  return response.data;
};

export const findCommon = async(mask) => {
  const url = `${process.env.REACT_APP_API_URL}/server/nutrient/common?mask=${mask}`;
  const response = await axios.get(url);
  
  return response.data;
};

export const loadCommon = async(_id) => {
  const url = `${process.env.REACT_APP_API_URL}/nutrient/common/${_id}`;
  const response = await axios.get(url);
  
  return response.data;
};

export const findFavorite = async(mask) => {
  const url = `${process.env.REACT_APP_API_URL}/server/nutrient/favorite?mask=${mask}`;
  const response = await axios.get(url);
  
  return response.data;
};

export const findHistory = async(mask) => {
  const url = `${process.env.REACT_APP_API_URL}/server/nutrient/history?mask=${mask}`;
  const response = await axios.get(url);
  
  return response.data;
};

export const loadFavorite = async(_id, type) => {
  if (type === FOOD_TYPE.COMMON) {
    return await loadCommon(_id);
  }
  else if (type === FOOD_TYPE.BRANDED) {
    return await loadBranded(_id);
  }
  
  return null;
};

