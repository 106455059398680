import React, { useState, useEffect, useCallback } from "react";
import {
  //Button,
  Grid,
  LinearProgress,
  Paper,
  // Stack,
  Switch,
  Typography,
  Tooltip,
  MenuItem,
} from "@mui/material";
import * as d3 from "d3";
import "./MetabolomicsPage.css";
import Select from "react-select";
import axios from "axios";
import glycineNetWorkSample from "../../../images/glycine-network-sample.png";
import Tree from "react-d3-tree";
import { std, mean, abs } from "mathjs";
import { zscore_prob } from "../../../constants/zscore_const";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip as BarChartTooltip,
  XAxis,
  YAxis,
  Legend,
} from "recharts";

export const useCenteredTree = () => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 4, y: height / 2 });
    }
  }, []);
  return [translate, containerRef];
};

const containerStyles = {
  width: "80vw",
  height: "56vh",
};

const calculateZScore = (data) => {
  let finalZScore = 0;
  let dataValues = data.map((a) => a.value);
  let patients = data.filter((el) => el.isHealthy !== true);
  if (patients.length === 0 || dataValues.length === 0) {
    return null;
  }
  let meanValue = mean(dataValues);
  let stdDev = std(dataValues);
  let zScoreArr = [];
  for (var i = 0; i < patients.length; i++) {
    zScoreArr.push((patients[i].value - meanValue) / stdDev);
  }

  finalZScore = mean(zScoreArr);

  return finalZScore;
};

const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
  dataViz,
  scaleFunction,
}) => {
  const data = dataViz[nodeDatum.name];
  const zScore = data
    ? calculateZScore(data)
      ? calculateZScore(data).toFixed(2)
      : null
    : null;
  let scalingFactor = 10;
  if (zScore) {
    scalingFactor = 22 * Number(zscore_prob[abs(zScore).toString()]);
  }

  return (
    <g>
      <Tooltip
        title={
          <h3>
            z score: {zScore}
            <br></br>probability:{" "}
            {zScore ? zscore_prob[zScore.toString()] : null}
          </h3>
        }
        arrow
      >
        <circle
          style={{
            fill:
              data && zScore
                ? zScore > 0.15
                  ? "#BE3539"
                  : zScore < -0.15
                  ? "#2163A2"
                  : "grey"
                : "white",
          }}
          onClick={toggleNode}
          r={scalingFactor}
        ></circle>
      </Tooltip>

      {/* `foreignObject` requires width & height to be explicitly set. */}
      <foreignObject {...foreignObjectProps}>
        <div>{nodeDatum.name}</div>
        {/* <UnivariateChart
            label={nodeDatum.name}
            key={`chart${nodeDatum.id}`}
            id={`uni${nodeDatum.id}`}
            data={data}
            scaleFunction={scaleFunction}
          /> */}
      </foreignObject>
    </g>
  );
};

// ******** in label format ********************************
const Pathways = [
  { label: "Amino Acid", value: "Amino Acid" },
  { label: "Peptide", value: "Peptide" },
  { label: "Carbohydrate", value: "Carbohydrate" },
  { label: "Energy", value: "Energy" },
  { label: "Lipid", value: "Lipid" },
  { label: "Nucleotide", value: "Nucleotide" },
  { label: "Cofactors and Vitamins", value: "Cofactors and Vitamins" },
  { label: "Xenobiotics", value: "Xenobiotics" },
];

const SubPathwaysMap = {
  "Amino Acid": [
    {
      label: "Glycine, Serine and Threonine Metabolism",
      value: "Glycine, Serine and Threonine Metabolism",
    },
    {
      label: "Alanine and Aspartate Metabolism",
      value: "Alanine and Aspartate Metabolism",
    },
    { label: "Glutamate Metabolism", value: "Glutamate Metabolism" },
    { label: "Histidine Metabolism", value: "Histidine Metabolism" },
    { label: "Lysine Metabolism", value: "Lysine Metabolism" },
    {
      label: "Phenylalanine and Tyrosine Metabolism",
      value: "Phenylalanine and Tyrosine Metabolism",
    },
    { label: "Tryptophan Metabolism", value: "Tryptophan Metabolism" },
    {
      label: "Leucine, Isoleucine and Valine Metabolism",
      value: "Leucine, Isoleucine and Valine Metabolism",
    },
    {
      label: "Methionine, Cysteine, SAM and Taurine Metabolism",
      value: "Methionine, Cysteine, SAM and Taurine Metabolism",
    },
    {
      label: "Urea cycle; Arginine and Proline Metabolism",
      value: "Urea cycle; Arginine and Proline Metabolism",
    },
    { label: "Creatine Metabolism", value: "Creatine Metabolism" },
    { label: "Polyamine Metabolism", value: "Polyamine Metabolism" },
    {
      label: "Guanidino and Acetamido Metabolism",
      value: "Guanidino and Acetamido Metabolism",
    },
    { label: "Glutathione Metabolism", value: "Glutathione Metabolism" },
  ],
  Peptide: [
    { label: "Gamma-glutamyl Amino Acid", value: "Gamma-glutamyl Amino Acid" },
    { label: "Dipeptide Derivative", value: "Dipeptide Derivative" },
    { label: "Dipeptide", value: "Dipeptide" },
    { label: "Polypeptide", value: "Polypeptide" },
    {
      label: "Fibrinogen Cleavage Peptide",
      value: "Fibrinogen Cleavage Peptide",
    },
    { label: "Acetylated Peptides", value: "Acetylated Peptides" },
  ],
  Carbohydrate: [
    {
      label: "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
      value: "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
    },
    { label: "Pentose Metabolism", value: "Pentose Metabolism" },
    { label: "Glycogen Metabolism", value: "Glycogen Metabolism" },
    {
      label: "Disaccharides and Oligosaccharides",
      value: "Disaccharides and Oligosaccharides",
    },
    {
      label: "Fructose, Mannose and Galactose Metabolism",
      value: "Fructose, Mannose and Galactose Metabolism",
    },
    { label: "Aminosugar Metabolism", value: "Aminosugar Metabolism" },
  ],
  Energy: [
    { label: "TCA Cycle", value: "TCA Cycle" },
    { label: "Oxidative Phosphorylation", value: "Oxidative Phosphorylation" },
  ],
  Lipid: [
    { label: "Medium Chain Fatty Acid", value: "Medium Chain Fatty Acid" },
    { label: "Long Chain Fatty Acid", value: "Long Chain Fatty Acid" },
    {
      label: "Polyunsaturated Fatty Acid (n3 and n6)",
      value: "Polyunsaturated Fatty Acid (n3 and n6)",
    },
    { label: "Fatty Acid, Branched", value: "Fatty Acid, Branched" },
    { label: "Fatty Acid, Dicarboxylate", value: "Fatty Acid, Dicarboxylate" },
    { label: "Fatty Acid, Amide", value: "Fatty Acid, Amide" },
    { label: "Fatty Acid, Amino", value: "Fatty Acid, Amino" },
    { label: "Fatty Acid Synthesis", value: "Fatty Acid Synthesis" },
    {
      label: "Fatty Acid Metabolism (also BCAA Metabolism)",
      value: "Fatty Acid Metabolism (also BCAA Metabolism)",
    },
    {
      label: "Fatty Acid Metabolism(Acyl Glycine)",
      value: "Fatty Acid Metabolism(Acyl Glycine)",
    },
    {
      label: "Fatty Acid Metabolism(Acyl Carnitine)",
      value: "Fatty Acid Metabolism(Acyl Carnitine)",
    },
    { label: "Carnitine Metabolism", value: "Carnitine Metabolism" },
    { label: "Ketone Bodies", value: "Ketone Bodies" },
    { label: "Fatty Acid, Monohydroxy", value: "Fatty Acid, Monohydroxy" },
    { label: "Fatty Acid, Dihydroxy", value: "Fatty Acid, Dihydroxy" },
    { label: "Fatty Acid, Oxidized", value: "Fatty Acid, Oxidized" },
    { label: "Eicosanoid", value: "Eicosanoid" },
    { label: "Endocannabinoid", value: "Endocannabinoid" },
    { label: "Inositol Metabolism", value: "Inositol Metabolism" },
    { label: "Phospholipid Metabolism", value: "Phospholipid Metabolism" },
    { label: "Phosphatidylserine (PS)", value: "Phosphatidylserine (PS)" },
    { label: "Lysolipid", value: "Lysolipid" },
    { label: "Plasmalogen", value: "Plasmalogen" },
    { label: "Lysoplasmalogen", value: "Lysoplasmalogen" },
    { label: "Glycerolipid Metabolism", value: "Glycerolipid Metabolism" },
    { label: "Monoacylglycerol", value: "Monoacylglycerol" },
    { label: "Diacylglycerol", value: "Diacylglycerol" },
    { label: "Sphingolipid Metabolism", value: "Sphingolipid Metabolism" },
    { label: "Mevalonate Metabolism", value: "Mevalonate Metabolism" },
    { label: "Sterol", value: "Sterol" },
    { label: "Steroid", value: "Steroid" },
    {
      label: "Primary Bile Acid Metabolism",
      value: "Primary Bile Acid Metabolism",
    },
    {
      label: "Secondary Bile Acid Metabolism",
      value: "Secondary Bile Acid Metabolism",
    },
    {
      label: "Fatty Acid Metabolism (Acyl Choline)",
      value: "Fatty Acid Metabolism (Acyl Choline)",
    },
    { label: "Lyso-phospho-ether", value: "Lyso-phospho-ether" },
  ],
  Nucleotide: [
    // {label: 'Purine Metabolism, (Hypo)Xanthine/Inosine containing',
    // value: 'Purine Metabolism, (Hypo)Xanthine/Inosine containing'},
    {
      label: "Purine Metabolism, Adenine containing",
      value: "Purine Metabolism, Adenine containing",
    },
    {
      label: "Purine Metabolism, Guanine containing",
      value: "Purine Metabolism, Guanine containing",
    },
    {
      label: "Pyrimidine Metabolism, Orotate containing",
      value: "Pyrimidine Metabolism, Orotate containing",
    },
    {
      label: "Pyrimidine Metabolism, Uracil containing",
      value: "Pyrimidine Metabolism, Uracil containing",
    },
    {
      label: "Pyrimidine Metabolism, Cytidine containing",
      value: "Pyrimidine Metabolism, Cytidine containing",
    },
    {
      label: "Pyrimidine Metabolism, Thymine containing",
      value: "Pyrimidine Metabolism, Thymine containing",
    },
  ],
  "Cofactors and Vitamins": [
    {
      label: "Nicotinate and Nicotinamide Metabolism",
      value: "Nicotinate and Nicotinamide Metabolism",
    },
    { label: "Riboflavin Metabolism", value: "Riboflavin Metabolism" },
    {
      label: "Pantothenate and CoA Metabolism",
      value: "Pantothenate and CoA Metabolism",
    },
    {
      label: "Ascorbate and Aldarate Metabolism",
      value: "Ascorbate and Aldarate Metabolism",
    },
    { label: "Tocopherol Metabolism", value: "Tocopherol Metabolism" },
    { label: "Biotin Metabolism", value: "Biotin Metabolism" },
    {
      label: "Hemoglobin and Porphyrin Metabolism",
      value: "Hemoglobin and Porphyrin Metabolism",
    },
    { label: "Lipoate Metabolism", value: "Lipoate Metabolism" },
    { label: "Vitamin A Metabolism", value: "Vitamin A Metabolism" },
    { label: "Vitamin B6 Metabolism", value: "Vitamin B6 Metabolism" },
  ],
  Xenobiotics: [
    { label: "Benzoate Metabolism", value: "Benzoate Metabolism" },
    { label: "Xanthine Metabolism", value: "Xanthine Metabolism" },
    { label: "Tobacco Metabolite", value: "Tobacco Metabolite" },
    //{label: 'Food Component/Plant', value: 'Food Component/Plant'},
    //{label: 'Bacterial/Fungal', value: 'Bacterial/Fungal'},
    { label: "Drug", value: "Drug" },
    { label: "Chemical", value: "Chemical" },
  ],
};

const SubPathwaysMap2 = {
  "Amino Acid": [
    {
      label: "Glycine Metabolism",
      value: "Glycine",
    },
    {
      label: "Alanine Metabolism",
      value: "Alanine",
    },
    {
      label: "Arginine Metabolism",
      value: "Arginine",
    },
    {
      label: "Asparagine Metabolism",
      value: "Asparagine",
    },
    {
      label: "Aspartate Metabolism",
      value: "Aspartate",
    },
    {
      label: "Cysteine Metabolism",
      value: "Cysteine",
    },
    {
      label: "Glutamate Metabolism",
      value: "Glutamate",
    },
    {
      label: "Glutamine Metabolism",
      value: "Glutamine",
    },
    {
      label: "Histidine Metabolism",
      value: "Histidine",
    },
    {
      label: "Isoleucine Metabolism",
      value: "Isoleucine",
    },
    {
      label: "Leucine Metabolism",
      value: "Leucine",
    },
    {
      label: "Lysine Metabolism",
      value: "Lysine",
    },
    {
      label: "Methionine Metabolism",
      value: "Methionine",
    },
    {
      label: "Phenylalanine Metabolism",
      value: "Phenylalanine",
    },
    {
      label: "Proline Metabolism",
      value: "Proline",
    },
    {
      label: "Serine Metabolism",
      value: "Serine",
    },
    {
      label: "Taurine Metabolism",
      value: "Taurine",
    },
    {
      label: "Threonine Metabolism",
      value: "Threonine",
    },
    {
      label: "Tryptophan Metabolism",
      value: "Tryptophan",
    },
    {
      label: "Tyrosine Metabolism",
      value: "Tyrosine",
    },
    {
      label: "Valine Metabolism",
      value: "Valine",
    },
  ],
};

const batchNames = [{ label: "SIPS Study", value: "SIPS Study" }];

const patients = [
  { label: "All", value: "All" },
  { label: "SIPS1-001", value: "SIPS1-001" },
  { label: "SIPS1-003", value: "SIPS1-003" },
  { label: "SIPS1-004", value: "SIPS1-004" },
  { label: "SIPS1-006", value: "SIPS1-006" },
  { label: "SIPS1-008", value: "SIPS1-008" },
  { label: "SIPS1-009", value: "SIPS1-009" },
  { label: "SIPS1-010", value: "SIPS1-010" },
  { label: "SIPS1-012", value: "SIPS1-012" },
  { label: "SIPS1-013", value: "SIPS1-013" },
  { label: "SIPS1-014", value: "SIPS1-014" },
  { label: "SIPS1-015", value: "SIPS1-015" },
  { label: "SIPS1-016", value: "SIPS1-016" },
  { label: "SIPS1-018", value: "SIPS1-018" },
  { label: "SIPS1-020", value: "SIPS1-020" },
  { label: "SIPS1-022", value: "SIPS1-022" },
  { label: "SIPS1-023", value: "SIPS1-023" },
  { label: "SIPS1-026", value: "SIPS1-026" },
  { label: "SIPS1-027", value: "SIPS1-027" },
  { label: "SIPS1-028", value: "SIPS1-028" },
  { label: "SIPS1-030", value: "SIPS1-030" },
];

const controls = [
  { label: "All", value: "All" },
  { label: "SIPSHC-100", value: "SIPSHC-100" },
  { label: "SIPSHC-101", value: "SIPSHC-101" },
  { label: "SIPSHC-103", value: "SIPSHC-103" },
  { label: "SIPSHC-105", value: "SIPSHC-105" },
  { label: "SIPSHC-107", value: "SIPSHC-107" },
  { label: "SIPSHC-108", value: "SIPSHC-108" },
  { label: "SIPSHC-109", value: "SIPSHC-109" },
  { label: "SIPSHC-110", value: "SIPSHC-110" },
  { label: "SIPSHC-111", value: "SIPSHC-111" },
  { label: "SIPSHC-112", value: "SIPSHC-112" },
];

const scales = [
  { label: "Linear", value: "linear" },
  { label: "Log", value: "log" },
  { label: "Power", value: "power" },
  { label: "Square Root", value: "sqrt" },
];
const orientations = [
  { label: "Horizontal", value: "horizontal" },
  { label: "Vertical", value: "vertical" },
];
// ****************************************

const getPathways = (name) => {
  // takes in string, returns an object
  return SubPathwaysMap[name];
};

const getPathways2 = (name) => {
  // takes in string, returns an object
  return SubPathwaysMap2[name];
};
// import { withStyles } from "@material-ui/core/styles";
// const HeaderTypography = withStyles({
//   root: {
//     backgroundColor: "yellow"
//   }
// })(Typography);

class MetabolomicsPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ backgroundColor: "#F7F6F2" }}
      >
        <Grid item xs={12}>
          <Paper style={{ width: "100vw" }}>
            <Typography
              variant="h5"
              component="div"
              style={{ padding: "20px" }}
            >
              Network
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TreeViewChartContainer />
        </Grid>
        <Grid item xs={12}>
          <UnivariateChartContainer />
        </Grid>
        <Grid item xs={12}>
          <BivariateChartContainer />
        </Grid>
      </Grid>
    );
  }
}

function UnivTree({ dataViz, scaleFunction, orientation, hierarchyMap }) {
  const [translate, containerRef] = useCenteredTree();
  const nodeSize = { x: 130, y: 70 };
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: 0,
    y: 10,
    strokeWidth: 1,
  };
  return (
    <div>
      {dataViz !== null && Object.keys(hierarchyMap).length !== 0 ? (
        <div style={containerStyles} ref={containerRef}>
          <Tree
            data={hierarchyMap}
            translate={translate}
            nodeSize={nodeSize}
            renderCustomNodeElement={(rd3tProps) =>
              renderForeignObjectNode({
                ...rd3tProps,
                foreignObjectProps,
                dataViz,
                scaleFunction,
              })
            }
            orientation={orientation}
          />
        </div>
      ) : null}
    </div>
  );
}

class TreeViewChartContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // stores objects
      pathway: Pathways[0],
      subPathway: getPathways2(Pathways[0].value)[0],
      batchName: batchNames[0],
      patients: [patients[14]],
      controls: [controls[0]],
      dataset: null,
      dataForViz: null,
      scaleInfo: { scale: scales[0], function: d3.scaleLinear },
      orientation: orientations[0],
      hierarchyMap: {},
    };
    this.mounted = false;
    this.counter = 0; // increments every time we fetch data;
    // ^^ need it so we have different key props when rendering Univariate
  }

  render() {
    // console.log("rerendering with state", this.state);
    return (
      <div style={{ overflow: "hidden" }}>
        <Paper>
          <Typography variant="h5" component="div" style={{ padding: "20px" }}>
            Tree View Metabolic Flow
          </Typography>
          <div
            style={{
              display: "flex",
              direction: "row",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "14vw" }}>
              <Typography>Pathway:</Typography>
              <Select
                options={Pathways}
                value={this.state.pathway}
                onChange={this.handlePathway}
              />
            </div>
            <div style={{ width: "14vw" }}>
              <Typography>Subpathway:</Typography>
              <Select
                options={getPathways2(this.state.pathway.value)}
                value={this.state.subPathway}
                onChange={this.handleSubPathway}
              />
            </div>
            <div style={{ width: "10vw" }}>
              <Typography>Batch Name:</Typography>
              <Select
                options={batchNames}
                value={this.state.batchName}
                onChange={this.handleBatchName}
              />
            </div>
            <div style={{ width: "10vw" }}>
              <Typography>Patient(s):</Typography>
              <Select
                options={patients}
                value={this.state.patients}
                onChange={this.handlePatients}
                isMulti
                closeMenuOnSelect={false}
              />
            </div>
            <div style={{ width: "10vw" }}>
              <Typography>Control(s):</Typography>
              <Select
                options={controls}
                value={this.state.controls}
                onChange={this.handleControls}
                isMulti
                closeMenuOnSelect={false}
              />
            </div>
            {/* <div style={{ width: "10vw" }}>
              <Typography>Scale:</Typography>
              <Select
                options={scales}
                value={this.state.scaleInfo.scale}
                onChange={this.handleScale}
              />
            </div> */}
            <div style={{ width: "10vw" }}>
              <Typography>Orientation:</Typography>
              <Select
                options={orientations}
                value={this.state.orientation}
                onChange={this.changeOrientation}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            {Object.keys(this.state.hierarchyMap).length !== 0 ? (
              <UnivTree
                dataViz={this.state.dataForViz}
                hierarchyMap={this.state.hierarchyMap}
                scaleFunction={this.state.scaleInfo.function}
                orientation={this.state.orientation.value}
              />
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <div id="treeview-legend"></div>
          </div>
        </Paper>
      </div>
    );
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchData();
    this.drawLegend();
  }

  changeOrientation = (orientation) => {
    this.setState({ orientation: orientation });
  };

  handlePathway = (pathwayObj) => {
    this.setState({ pathway: pathwayObj, subPathway: undefined }, () => {
      // console.log("pathway done;", this.state);
    });
  };

  handleSubPathway = (subPathwayObj) => {
    this.setState({ subPathway: subPathwayObj }, () => {
      // console.log("subPathway done;", this.state);
      if (this.state.batchName !== undefined) {
        this.fetchData();
      }
    });
  };

  handleBatchName = (batchNameObj) => {
    this.setState({ batchName: batchNameObj.value }, () => {
      if (this.state.subPathway !== undefined) {
        this.fetchData();
      }
    });
  };

  handlePatients = (patientObj) => {
    this.setState({ patients: patientObj }, () => {
      // console.log(patientObj);
      this.generateDataForViz();
    });
  };

  handleControls = (controlObj) => {
    this.setState({ controls: controlObj }, () => {
      // console.log(controlObj);
      this.generateDataForViz();
    });
  };

  handleScale = (scaleOption) => {
    if (scaleOption !== this.state.scale) {
      this.counter += 1;
      //{value: "linear", function: d3.scaleLinear}
      let myScaleFunction;
      switch (scaleOption.value) {
        case "linear":
          myScaleFunction = d3.scaleLinear;
          break;
        case "log":
          myScaleFunction = d3.scaleLog;
          break;
        case "power":
          myScaleFunction = d3.scalePow;
          break;
        case "sqrt":
          myScaleFunction = d3.scaleSqrt;
          break;
        default:
          return;
      }
      this.setState({
        scaleInfo: { scale: scaleOption, function: myScaleFunction },
      });
    }
  };

  fetchData() {
    const url = `${process.env.REACT_APP_API_URL}/server/v2/${this.state.pathway.value}/${this.state.subPathway.value}/${this.state.batchName.value}`;
    console.log(url);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/server/v2/${this.state.pathway.value}/${this.state.subPathway.value}/${this.state.batchName.value}`
      )
      .then((dataObj) => {
        if (this.mounted) {
          const dataset = this.createDataDict(dataObj.data.data);
          this.setState({
            dataset,
            hierarchyMap: dataObj.data.map,
          });
          // console.log("dataset:", this.state.dataset);
          // console.log("map:", this.state.hierarchyMap);
          this.generateDataForViz(dataset);
        }
      })
      .catch((error) => {
        alert("Couldn't access the database. Try again.");
        console.log(error);
      });
  }

  generateDataForViz(dataset) {
    const fromDataset = dataset || this.state.dataset;
    console.log(fromDataset);
    // console.log("called gen data for viz");
    this.counter += 1;

    // ******** strings ********************************
    const patientsNames = this.state.patients.map((obj) => obj.value);
    const controlsNames = this.state.controls.map((obj) => obj.value);
    if (this.state.patients.length !== 0 && this.state.controls.length !== 0) {
      let newDataForViz = {};
      Object.entries(fromDataset).map(([key, oldDataArr]) => {
        let newDataArr = oldDataArr.filter(
          (obj) =>
            (!obj.isHealthy &&
              (patientsNames.includes("All") ||
                patientsNames.includes(obj.clientName))) ||
            (obj.isHealthy &&
              (controlsNames.includes("All") ||
                controlsNames.includes(obj.clientName)))
        );
        newDataForViz[key] = newDataArr;
      });
      this.setState({ dataForViz: newDataForViz });
    } // else keep the old value
  }

  createDataDict(arrOfObjs) {
    // key by biochemical name
    let dict = {};
    arrOfObjs.forEach((obj) => {
      let key_ = obj.biochemicalName;
      if (dict[key_] === undefined) {
        dict[key_] = [];
      }
      dict[key_].push(obj);
    });
    //sort so patients are last --> will appear at the top of charts
    Object.values(dict).map((dataArr) => {
      dataArr.sort((obj1, obj2) => +obj2.isHealthy - +obj1.isHealthy);
    });
    return dict;
  }

  drawLegend() {
    // this is grossly hardcoded :(
    var legendDiv = d3.select("#treeview-legend");
    let r = 10;
    const svg = d3.create("svg").attr("width", 650).attr("height", 70);
    svg
      .append("circle")
      .attr("cx", r + 2)
      .attr("cy", r + 2)
      .attr("r", r)
      .style("fill", "grey")
      .style("fill-opacity", 0.9)
      .style("stroke", "darkred")
      .style("stroke-width", 2);
    svg
      .append("text")
      .attr("x", 2 * r + 10)
      .attr("y", r + 5 + 2)
      .style("font-size", "20px")
      .text(" - normal ");
    svg
      .append("circle")
      .attr("cx", 2 * r + 10 + 100 + 2 * r + 2)
      .attr("cy", r + 2)
      .attr("r", r)
      .style("fill", "#2163A2")
      .style("fill-opacity", 0.9)
      .style("stroke", "darkred")
      .style("stroke-width", 2);
    svg
      .append("text")
      .attr("x", 2 * r + 10 + 100 + 2 * r + r + 10)
      .attr("y", r + 5 + 2)
      .style("font-size", "20px")
      .text(" - decreased ");
    svg
      .append("circle")
      .attr("cx", 3 * r + 45 + 200 + 3 * r + 3)
      .attr("cy", r + 2)
      .attr("r", r)
      .style("fill", "#BE3539")
      .style("fill-opacity", 0.9)
      .style("stroke", "darkred")
      .style("stroke-width", 2);
    svg
      .append("text")
      .attr("x", 3 * r + 45 + 200 + 3 * r + r + 10)
      .attr("y", r + 5 + 2)
      .style("font-size", "20px")
      .text(" - increased ");

    svg
      .append("circle")
      .attr("cx", 4 * r + 70 + 300 + 4 * r + 4)
      .attr("cy", r + 2)
      .attr("r", r)
      .style("fill", "white")
      .style("stroke", "grey")
      .style("stroke-width", 2);
    svg
      .append("text")
      .attr("x", 4 * r + 70 + 300 + 4 * r + r + 10)
      .attr("y", r + 5 + 2)
      .style("font-size", "20px")
      .text(" - no data");

    legendDiv.append(() => svg.node());
  }
}

class UnivariateChartContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // stores objects
      pathway: Pathways[0],
      subPathway: getPathways2(Pathways[0].value)[0],
      batchName: batchNames[0],
      patients: [patients[14]],
      controls: [controls[0]],
      dataset: null,
      dataForViz: null,
      scaleInfo: { scale: scales[0], function: d3.scaleLinear },
      orientation: orientations[0],
      hierarchyMap: {},
    };
    this.mounted = false;
    this.counter = 0; // increments every time we fetch data;
    // ^^ need it so we have different key props when rendering Univariate
  }

  render() {
    // console.log("rerendering with state", this.state);
    return (
      <div style={{ overflow: "hidden" }}>
        <Paper>
          <Typography variant="h5" component="div" style={{ padding: "20px" }}>
            Univariate Charts
          </Typography>
          <div
            style={{
              display: "flex",
              direction: "row",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "14vw" }}>
              <Typography>Pathway:</Typography>
              <Select
                options={Pathways}
                value={this.state.pathway}
                onChange={this.handlePathway}
              />
            </div>
            <div style={{ width: "14vw" }}>
              <Typography>Subpathway:</Typography>
              <Select
                options={getPathways2(this.state.pathway.value)}
                value={this.state.subPathway}
                onChange={this.handleSubPathway}
              />
            </div>
            <div style={{ width: "10vw" }}>
              <Typography>Batch Name:</Typography>
              <Select
                options={batchNames}
                value={this.state.batchName}
                onChange={this.handleBatchName}
              />
            </div>
            <div style={{ width: "10vw" }}>
              <Typography>Patient(s):</Typography>
              <Select
                options={patients}
                value={this.state.patients}
                onChange={this.handlePatients}
                isMulti
                closeMenuOnSelect={false}
              />
            </div>
            <div style={{ width: "10vw" }}>
              <Typography>Control(s):</Typography>
              <Select
                options={controls}
                value={this.state.controls}
                onChange={this.handleControls}
                isMulti
                closeMenuOnSelect={false}
              />
            </div>
            <div style={{ width: "10vw" }}>
              <Typography>Scale:</Typography>
              <Select
                options={scales}
                value={this.state.scaleInfo.scale}
                onChange={this.handleScale}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            {this.state.dataForViz !== null
              ? Object.entries(this.state.dataForViz).map(
                  ([key, value], index) => (
                    <UnivariateChart
                      key={`iter${this.counter}-chart${index}`}
                      id={`uni${index}`}
                      label={key}
                      data={value}
                      scaleFunction={this.state.scaleInfo.function}
                    />
                  )
                )
              : null}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <div id="univariate-legend"></div>
          </div>
        </Paper>
      </div>
    );
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchData();
    this.drawLegend();
  }

  handlePathway = (pathwayObj) => {
    this.setState({ pathway: pathwayObj, subPathway: undefined }, () => {
      // console.log("pathway done;", this.state);
    });
  };

  handleSubPathway = (subPathwayObj) => {
    this.setState({ subPathway: subPathwayObj }, () => {
      // console.log("subPathway done;", this.state);
      if (this.state.batchName !== undefined) {
        this.fetchData();
      }
    });
  };

  handleBatchName = (batchNameObj) => {
    this.setState({ batchName: batchNameObj.value }, () => {
      if (this.state.subPathway !== undefined) {
        this.fetchData();
      }
    });
  };

  handlePatients = (patientObj) => {
    this.setState({ patients: patientObj }, () => {
      // console.log(patientObj);
      this.generateDataForViz();
    });
  };

  handleControls = (controlObj) => {
    this.setState({ controls: controlObj }, () => {
      // console.log(controlObj);
      this.generateDataForViz();
    });
  };

  handleScale = (scaleOption) => {
    if (scaleOption !== this.state.scale) {
      this.counter += 1;
      //{value: "linear", function: d3.scaleLinear}
      let myScaleFunction;
      switch (scaleOption.value) {
        case "linear":
          myScaleFunction = d3.scaleLinear;
          break;
        case "log":
          myScaleFunction = d3.scaleLog;
          break;
        case "power":
          myScaleFunction = d3.scalePow;
          break;
        case "sqrt":
          myScaleFunction = d3.scaleSqrt;
          break;
        default:
          return;
      }
      this.setState({
        scaleInfo: { scale: scaleOption, function: myScaleFunction },
      });
    }
  };

  fetchData() {
    const url = `${process.env.REACT_APP_API_URL}/server/v2/${this.state.pathway.value}/${this.state.subPathway.value}/${this.state.batchName.value}`;
    console.log(url);
    axios
      .get(
        url
      )
      .then((dataObj) => {
        if (this.mounted) {
          const dataset = this.createDataDict(dataObj.data.data); 
          this.setState({
            dataset,
            hierarchyMap: dataObj.data.map,
          });
          // console.log("dataset:", this.state.dataset);
          // console.log("map:", this.state.hierarchyMap);
          this.generateDataForViz(dataset);
        }
      })
      .catch((error) => {
        alert("Couldn't access the database. Try again.");
        console.log(error);
      });
  }

  generateDataForViz(dataset) {
    const fromDataset = dataset || this.state.dataset;
    // console.log("called gen data for viz");
    this.counter += 1;

    // ******** strings ********************************
    const patientsNames = this.state.patients.map((obj) => obj.value);
    const controlsNames = this.state.controls.map((obj) => obj.value);
    if (this.state.patients.length !== 0 && this.state.controls.length !== 0) {
      let newDataForViz = {};
      Object.entries(fromDataset).map(([key, oldDataArr]) => {
        let newDataArr = oldDataArr.filter(
          (obj) =>
            (!obj.isHealthy &&
              (patientsNames.includes("All") ||
                patientsNames.includes(obj.clientName))) ||
            (obj.isHealthy &&
              (controlsNames.includes("All") ||
                controlsNames.includes(obj.clientName)))
        );
        newDataForViz[key] = newDataArr;
      });
      this.setState({ dataForViz: newDataForViz });
    } // else keep the old value
  }

  createDataDict(arrOfObjs) {
    // key by biochemical name
    let dict = {};
    arrOfObjs.forEach((obj) => {
      let key_ = obj.biochemicalName;
      if (dict[key_] === undefined) {
        dict[key_] = [];
      }
      dict[key_].push(obj);
    });
    //sort so patients are last --> will appear at the top of charts
    Object.values(dict).map((dataArr) => {
      dataArr.sort((obj1, obj2) => +obj2.isHealthy - +obj1.isHealthy);
    });
    return dict;
  }

  drawLegend() {
    // this is grossly hardcoded :(
    var legendDiv = d3.select("#univariate-legend");
    let r = 10;
    const svg = d3.create("svg").attr("width", 250).attr("height", 70);
    svg
      .append("circle")
      .attr("cx", r + 2)
      .attr("cy", r + 2)
      .attr("r", r)
      .style("fill", "red")
      .style("fill-opacity", 0.9)
      .style("stroke", "darkred")
      .style("stroke-width", 2);
    svg
      .append("text")
      .attr("x", 2 * r + 10)
      .attr("y", r + 5 + 2)
      .style("font-size", "20px")
      .text(" - patient");

    svg
      .append("circle")
      .attr("cx", 2 * r + 10 + 100 + 2 * r + 2)
      .attr("cy", r + 2)
      .attr("r", r)
      .style("fill", "white")
      .style("stroke", "grey")
      .style("stroke-width", 2);
    svg
      .append("text")
      .attr("x", 2 * r + 10 + 100 + 2 * r + r + 10)
      .attr("y", r + 5 + 2)
      .style("font-size", "20px")
      .text(" - control");

    legendDiv.append(() => svg.node());
  }
}

class UnivariateChart extends React.Component {
  constructor(props) {
    super(props);
    this.id = props.id;
    this.label = props.label;
    this.data = props.data;
    this.scaleFunction = props.scaleFunction;
    this.globals = this.getGlobals();
    this.state = {};
  }

  render() {
    return (
      <div>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {this.label}
        </Typography>
        <div id={this.id}></div>
      </div>
    );
  }

  componentDidMount() {
    // console.log("uni mounted");
    this.drawViz();
  }

  drawViz() {
    const container = this.drawContainer();
    var viz = d3.select("#" + this.id);
    viz.selectAll("*").remove();
    viz.append(() => container.node());
    let stateInfo = this.createScaleFunctions(this.data);
    this.setState(stateInfo, () => {
      this.drawAxes();
      this.drawBoxPlot(this.data);
      this.drawData(this.data);
      // this.drawLabel();
    });
  }

  getGlobals() {
    const globals = {
      plotCircleRadius: 10,
      plotCircleStrokeWidth: 2,
      mapWidth: 450,
      mapHeight: 70,
      boxHeight: 40,
      parentBoxHeight: 60,
    };
    globals.margin = { top: 25, right: 10, bottom: 50, left: 10 };
    return globals;
  }

  drawContainer() {
    // set the dimensions and margins of the graph
    const margin = this.globals.margin,
      width = this.globals.mapWidth,
      height = this.globals.mapHeight;
    // append the svg object to the body of the page
    const svg = d3
      .create("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    svg
      .append("g")
      .attr("id", "container-" + this.id)
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    return svg;
  }

  createScaleFunctions(data) {
    let maxVal = d3.max(data, (d) => d.value);
    let minVal = d3.min(data, (d) => d.value);
    let scaleX = this.scaleFunction()
      .domain([minVal, maxVal])
      .range([50, this.globals.mapWidth - 50]);
    return { scaleX: scaleX };
  }

  drawAxes() {
    let container = d3.select("#container-" + this.id);
    let scaleX = this.state.scaleX;
    let parentBoxHeight = this.globals.parentBoxHeight;
    container
      .append("g")
      .attr("class", "axes-" + this.id)
      .attr(
        "transform",
        `translate(0, ${this.globals.mapHeight / 2 + parentBoxHeight / 2})`
      )
      .call(d3.axisBottom(scaleX).ticks(5, "~s"));
  }

  drawData(data) {
    let container = d3.select("#container-" + this.id);
    container
      .append("g")
      .attr("id", this.getCircleGroupId())
      .selectAll("dot")
      .data(data)
      .join("circle")
      .attr("id", (d) => this.getCircleId(d))
      .attr("transform", `translate(0, ${this.globals.mapHeight / 2})`)
      .attr("cx", (d) => this.state.scaleX(d.value))
      .attr("r", this.globals.plotCircleRadius)
      .style("fill", (d) => (d.isHealthy ? "white" : "red"))
      .style("fill-opacity", (d) => (d.isHealthy ? 0 : 0.9))
      .style("stroke", (d) => this.getStrokeColor(d))
      .style("stroke-width", `${this.globals.plotCircleStrokeWidth}px`)
      .on("mouseover", (event, d) => this.handleMouseOver(event, d))
      .on("mouseout", (event, d) => this.handleMouseOut(event, d));
  }

  handleMouseOver(event, d) {
    const thisElem = event.currentTarget; //d3.select(this);
    thisElem.style.stroke = "blue";
    this.addPopUp(event, d);
  }

  addPopUp(event, d) {
    let elem = d3.select("#" + this.getCircleId(d));
    const cx = elem.attr("cx");
    const cy = this.globals.mapHeight / 2;
    let bar = d3
      .select("#" + this.getCircleGroupId(d))
      .append("g")
      .attr("id", this.getCirclePopupId(d));

    let header = d.clientName;
    const formatNumber = (num) =>
      Number.parseFloat(num).toLocaleString("en-US");
    let body = `value: ${formatNumber(d.value)}`;
    createPopUp(bar, cx, cy, header, body, this.globals.mapWidth);
  }

  handleMouseOut(event, d) {
    //console.log("mouseout event currTarget:", event.currentTarget)
    const thisElem = event.currentTarget; //d3.select(this);
    thisElem.style.stroke = this.getStrokeColor(d);

    d3.select("#" + this.getCirclePopupId(d)).remove();
  }

  drawBoxPlot(data) {
    let container = d3.select("#container-" + this.id);
    let boxHeight = this.globals.boxHeight;
    let mapHeight = this.globals.mapHeight;
    let scaleX = this.state.scaleX;

    let sortedValues = data.map((g) => g.value).sort(d3.ascending);
    //console.log(sortedValues);
    let q1 = d3.quantile(sortedValues, 0.25);
    let median = d3.quantile(sortedValues, 0.5);
    let q3 = d3.quantile(sortedValues, 0.75);
    let interQuantileRange = q3 - q1;
    let min = sortedValues.at(0);
    let max = sortedValues.at(-1);
    let sumstat = {
      q1: q1,
      median: median,
      q3: q3,
      interQuantileRange: interQuantileRange,
      min: min,
      max: max,
    };
    //console.log("sunstat:", sumstat);
    let boxPlot = container.append("g").attr("id", "boxPlot-" + this.id);

    // Box
    boxPlot
      .append("rect")
      .attr("x", scaleX(sumstat.q1)) // console.log(x(d.value.q1)) ;
      .attr("width", scaleX(sumstat.q3) - scaleX(sumstat.q1))
      .attr("y", mapHeight / 2 - boxHeight / 2)
      .attr("height", boxHeight)
      .attr("stroke", "blue")
      .style("fill-opacity", 0);
    //.style("opacity", 0.3)

    // Show the median
    boxPlot
      .append("line")
      .attr("y1", mapHeight / 2 - boxHeight / 2)
      .attr("y2", mapHeight / 2 + boxHeight / 2)
      .attr("x1", scaleX(sumstat.median))
      .attr("x2", scaleX(sumstat.median))
      .attr("stroke", "blue");
    //.style("width", 80)

    // Whiskers
    boxPlot
      .append("line")
      .attr("y1", mapHeight / 2)
      .attr("y2", mapHeight / 2)
      .attr("x1", scaleX(sumstat.min))
      .attr("x2", scaleX(sumstat.q1))
      .attr("stroke", "blue")
      .style("stroke-dasharray", "3, 3");
    boxPlot
      .append("line")
      .attr("y1", mapHeight / 2)
      .attr("y2", mapHeight / 2)
      .attr("x1", scaleX(sumstat.q3))
      .attr("x2", scaleX(sumstat.max))
      .attr("stroke", "blue")
      .style("stroke-dasharray", "3, 3");
  }

  // drawLabel() {
  //   let container = d3.select("#container-"+this.id);
  //   container.append("text") //simple way to add a new line
  //   .attr('x', 10)
  //   .attr('y', 0)
  //   .attr("text-anchor", "start")
  //   .style("font", "20px times")
  //   .text("Metabolite Name");
  // }

  getCircleId(d) {
    return `id-${d.biochemicalId}-${d.clientName}`; // TODO: CHANGE THISs
  }

  getCircleGroupId() {
    return "dots-" + this.id;
  }

  getCirclePopupId(d) {
    return `popup-${this.getCircleId(d)}`;
  }

  getStrokeColor(d) {
    return d.isHealthy ? "grey" : "darkred";
  }
}

class BivariateChartContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Paper>
        <Typography variant="h5" component="div" style={{ padding: "20px" }}>
          Substrate:Product Ratios
        </Typography>
        <BivariateChart id="viz1" />
      </Paper>
    );
  }
}

class BivariateChart extends React.Component {
  constructor(props) {
    super(props);
    this.id = props.id;
    this.globals = this.getGlobals();
    this.state = {
      pathway: Pathways[0],
      subPathway: getPathways(Pathways[0].value)[0],
      batchName: batchNames[0],
      patients: [patients[0]],
      controls: [controls[0]],
      metabolicName: "",
      metabolicNameDemominatorSet: [{label: "All", value: "All"}],
      dataset: null,
      fullDataSet: null,
      scale: scales[1],
      scaleFunction: d3.scaleLog,
      scaleSeqFunction: d3.scaleSequentialLog,
      scaleText: "linear",
      typeOfChart: "Matrix",
    };
    this.mounted = false;
  }

  render() {
    return (
      <div>
        <div
          style={{
            display: "flex",
            direction: "row",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "14vw" }}>
            <Typography>Pathway:</Typography>
            <Select
              options={Pathways}
              value={this.state.pathway}
              onChange={this.handlePathway}
            />
          </div>
          <div style={{ width: "14vw" }}>
            <Typography>Subpathway:</Typography>
            <Select
              options={getPathways(this.state.pathway.value)}
              value={this.state.subPathway}
              onChange={this.handleSubPathway}
            />
          </div>

          {this.state.typeOfChart !== "Bar" ? (
            <div style={{ width: "14vw" }}>
              <Typography>Metabolites</Typography>
              <Select
                options={this.getMetabolites(this.state.metabolicNames)}
                value={this.state.metabolicName}
                onChange={this.handleMetabolites}
              />{" "}
            </div>
          ) : (
            <div style={{ width: "14vw" }}>
              {" "}
              <Typography>Substrate:</Typography>
              <Select
                options={this.getMetabolites(this.state.metabolicNames)}
                value={this.state.metabolicName}
                onChange={this.handleMetabolites}
              />
              <Typography>Product:</Typography>
              <Select
                options={this.getMetabolitesDenominator(this.state.metabolicNames)}
                value={this.state.metabolicNameDemominatorSet}
                onChange={this.handleMetabolitesDenominator}
                isMulti
                closeMenuOnSelect={false}
              />
            </div>
          )}

          <div style={{ width: "14vw" }}>
            <Typography>Batch Name:</Typography>
            <Select
              options={batchNames}
              value={this.state.batchName}
              onChange={this.handleBatchName}
            />
          </div>
          <div style={{ width: "14vw" }}>
            <Typography>Patient(s):</Typography>
            <Select
              options={patients}
              value={this.state.patients}
              onChange={this.handlePatients}
              isMulti
              closeMenuOnSelect={false}
            />
          </div>
          <div style={{ width: "14vw" }}>
            <Typography>Control(s):</Typography>
            <Select
              options={controls}
              value={this.state.controls}
              onChange={this.handleControls}
              isMulti
              closeMenuOnSelect={false}
            />
          </div>
          <div style={{ width: "14vw" }}>
            <Typography>Scale(s):</Typography>
            <Select
              options={scales}
              value={this.state.scale}
              onChange={this.handleScale}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            direction: "row",
            justifyContent: "center",
          }}
        >
          {this.state.typeOfChart === "Bar" ? (
            <span id="barChartContainer">
              <ResponsiveContainer>
                <BarChart
                  style={{ margin: "50px" }}
                  data={this.state.barChartData}
                >
                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis dataKey="name" />
                  <YAxis />
                  <BarChartTooltip />
                  <Legend />
                  <Bar dataKey="patient" fill="#8884d8" />
                  <Bar dataKey="control" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </span>
          ) : (
            <div style={{height: "650px"}}><div style={{marginTop: "250px"}}><h1>Under Construction</h1></div></div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    // will later get this from a database
    this.mounted = true;
    // this.setState({dataset: tempData}, () => {
    //   this.drawViz();
    // });
    this.fetchData();
  }

  handlePathway = (pathwayObj) => {
    this.setState({ pathway: pathwayObj, subPathway: undefined }, () => {
      // console.log("pathway done;", this.state);
    });
  };

  handleSubPathway = (subPathwayObj) => {
    this.setState(
      { subPathway: subPathwayObj, typeOfChart: "Bivariate" },
      () => {
        // console.log("subPathway done;", this.state);
        if (this.state.batchName !== undefined) {
          this.fetchData();
        }
      }
    );
  };

  handleMetabolites = (metabolitesObj) => {
    this.setState({
      metabolicName: metabolitesObj,
      barChartData: this.getBarChartData(metabolitesObj),
      metabolicNameDemominatorSet: [{label: "All", value: "All"}],
    },() => {
      this.setState({typeOfChart: "Bar"});
    });
  };

  getBarChartData = (metabolitesObj) => {
    let barChartData = [];
    let metabolArr = [...this.state.metabolicNames];
    metabolArr.splice(metabolArr.indexOf(metabolitesObj.value), 1);
    console.log(metabolArr);
    for (var i = 0; i < metabolArr.length; i++) {
      var dataObj = this.state.fullDataSet.filter(
        (el) => el.r2 === metabolArr[i] && el.r1 === metabolitesObj.value
      );
      let barChartObj = {
        name: metabolArr[i].toString(),
        patient: dataObj[0] ? dataObj[0].value.toString() : 0,
        control: dataObj[1] ? dataObj[1].value.toString() : 0,
      };
      barChartData.push(barChartObj);
    }
    // console.log(barChartData);
    return barChartData;
  };

  getBarChartDataIncludingDemonenator = () => {
    let barChartData = [];
    let metabolArr;
    let isAll = this.state.metabolicNameDemominatorSet.findIndex(e => e.value === 'All') > -1 ;
    console.log('is All - ' + isAll);
    if (isAll) {
      metabolArr = [...this.state.metabolicNames];
      metabolArr.splice(metabolArr.indexOf(this.state.metabolicName.value), 1);
    } else {
      let copy = [...this.state.metabolicNameDemominatorSet];
      metabolArr = copy.map(a => a.value);
    }
    for (var i = 0; i < metabolArr.length; i++) {
      var dataObj = this.state.fullDataSet.filter(
        (el) => el.r2 === metabolArr[i] && el.r1 === this.state.metabolicName.value
      );
      let barChartObj = {
        name: metabolArr[i].toString(),
        patient: dataObj[0] ? dataObj[0].value.toString() : 0,
        control: dataObj[1] ? dataObj[1].value.toString() : 0,
      };
      barChartData.push(barChartObj);
    }
    // console.log(barChartData);
    return barChartData;
  };

  handleMetabolitesDenominator = (mObj) => {
    if (mObj.length === 0) mObj.push({label: "All", value: "All"});
    this.setState({metabolicNameDemominatorSet: mObj}, () => {
      this.setState({barChartData: this.getBarChartDataIncludingDemonenator()});
    }); 
  }

  getMetabolites = (array) => {
    if (!array) return [];
    let temp = [];
    for (var i = 0; i < array.length; i++) {
      let t = {
        label: array[i],
        value: array[i],
      };
      temp.push(t);
    }
    return temp;
  }; 

  getMetabolitesDenominator = (array) => {
    if (!array) return [];
    let temp = [];
    for (var i = 0; i < array.length; i++) {
      if(array[i] !== this.state.metabolicName.value) {
        let t = {
          label: array[i],
          value: array[i],
        };
        temp.push(t);
      }
    }
    return temp;
  }

  handleBatchName = (batchNameObj) => {
    this.setState({ batchName: batchNameObj.value }, () => {
      if (this.state.subPathway !== undefined) {
        this.fetchData();
      }
    });
  };

  handlePatients = (patientObj) => {
    this.setState({ patients: patientObj }, () => {
      // console.log(patientObj);
      const dataset = this.createDataDict(this.state.originalData);
      this.setState({ dataset: dataset }, () => {
        this.drawViz();
      });
      // this.generateDataForViz(); //GERMAN -- maybe this needs to be updated!!
    });
  };

  handleControls = (controlObj) => {
    this.setState({ controls: controlObj }, () => {
      // console.log(controlObj);
      const dataset = this.createDataDict(this.state.originalData);
      this.setState({ dataset: dataset }, () => {
        this.drawViz();
      });
      // this.generateDataForViz(); //GERMAN -- maybe this needs to be updated!!
    });
  };

  handleScale = (scaleOption) => {
    // console.log(JSON.stringify(scaleOption));
    if (scaleOption !== this.state.scale) {
      let myScaleFunction;
      let myScaleSeqFunction;
      let myScaleText;
      switch (scaleOption.value) {
        case "linear":
          myScaleFunction = d3.scaleLinear;
          myScaleSeqFunction = d3.scaleSequential;
          myScaleText = "linear";
          break;
        case "log":
          myScaleFunction = d3.scaleLog;
          myScaleSeqFunction = d3.scaleSequentialLog;
          myScaleText = "log";
          break;
        case "power":
          myScaleFunction = d3.scalePow;
          myScaleSeqFunction = d3.scaleSequentialPow;
          myScaleText = "power";
          break;
        case "sqrt":
          myScaleFunction = d3.scaleSqrt;
          myScaleSeqFunction = d3.scaleSequentialSqrt;
          myScaleText = "square root";
          break;
        default:
          return;
      }
      this.setState(
        {
          scale: scaleOption,
          scaleFunction: myScaleFunction,
          scaleSeqFunction: myScaleSeqFunction,
          scaleText: myScaleText,
        },
        () => {
          this.drawViz();
        }
      );
    }
  };

  fetchData() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/server/${this.state.pathway.value}/${this.state.subPathway.value}/${this.state.batchName.value}`
      )
      .then((dataObj) => {
        // console.log(dataObj.data);
        let dataset;
        let fullDataSet;
        let metabolicNamesArray = this.getOrder(dataObj.data.data);
        this.setState(
          {
            originalData: dataObj.data,
            metabolicNames: metabolicNamesArray,
            metabolicName:
              metabolicNamesArray.length > 0 ? metabolicNamesArray[0] : "",
          },
          () => {
            dataset = this.createDataDict(dataObj.data.data);
            fullDataSet = this.createDataDictFullSet(dataObj.data.data);
            this.mounted &&
              this.setState(
                { dataset: dataset, fullDataSet: fullDataSet },
                () => {
                  this.drawViz();
                }
              );
          }
        );
      })
      .catch((error) => {
        alert("Couldn't access the database. Try again.");
        console.log(error);
      });
  }

  getOrder(arrOfObjs) {
    let numberDict = new Map();
    arrOfObjs.forEach((obj) => {
      numberDict.set(obj["order"], obj["biochemicalName"]);
    });
    var sortNumberDict = new Map([...numberDict].sort((a, b) => a[0] - b[0]));
    let metabolicNames = Array.from(sortNumberDict.values());
    return metabolicNames;
  }

  createDataDict(arrOfObjs) {
    // console.log(this.state.patients, 776);
    var patientsNames = this.state.patients.map((obj) => obj.value);
    if (patientsNames.includes("All")) {
      patientsNames = patients.slice(1).map((obj) => obj.value);
    }

    var controlsNames = this.state.controls.map((obj) => obj.value);
    if (controlsNames.includes("All")) {
      controlsNames = controls.slice(1).map((obj) => obj.value);
    }

    // console.log(787, {patientsNames, controlsNames});

    if (this.state.patients.length !== 0 && this.state.controls.length !== 0) {
      // key by biochemical name
      let metabolicNameDict = {};
      let metabolicDict = {};
      let bioName2Id = {};
      let tempData = [];

      arrOfObjs.forEach((obj) => {
        let key_ = obj.biochemicalName;
        if (metabolicNameDict[key_] === undefined) {
          metabolicNameDict[key_] = {};
        }
        metabolicNameDict[key_][obj.clientName] = obj.value;
        bioName2Id[obj.biochemicalName] = obj.biochemicalId;
      });
      /* STORE THIS TO BE MORE EFFICIENT */
      Object.keys(metabolicNameDict).forEach((key_) => {
        // const metabolicVals = metabolicNameDict[key_];
        // console.log(815, metabolicNameDict[key_]);
        let patientVals = patientsNames.map(
          (patient) => metabolicNameDict[key_][patient]
        );
        let controlsVals = controlsNames.map(
          (control) => metabolicNameDict[key_][control]
        );
        metabolicDict[key_] = {};
        metabolicDict[key_]["patient"] = patientVals;
        metabolicDict[key_]["control"] = controlsVals;
      });

      let metabolicNames = this.state.metabolicNames || new Map();
      var patientData = [];
      var controlData = [];
      for (var i = 0; i < metabolicNames.length; i++) {
        var r1 = metabolicNames[i];
        let r1Id = bioName2Id[r1];
        var m1Dict = metabolicDict[r1];
        if (!m1Dict || !m1Dict["patient"] || !m1Dict["control"]) {
          continue;
        }
        for (var j = i; j < metabolicNames.length; j++) {
          var r2 = metabolicNames[j];
          let r2Id = bioName2Id[r2];
          var m2Dict = metabolicDict[r2];
          if (!m2Dict || !m2Dict["patient"] || !m2Dict["control"]) {
            continue;
          }
          let patientValue = this.getRatio(
            m1Dict["patient"],
            m2Dict["patient"]
          );
          let patientDataDict = {
            type: "patient",
            r1,
            r2,
            r1Id: r1Id,
            r2Id: r2Id,
            value: patientValue,
          };

          let controlValue = this.getRatio(
            m1Dict["control"],
            m2Dict["control"]
          );
          let controlDataDict = {
            type: "control",
            r1,
            r2,
            r1Id: r1Id,
            r2Id: r2Id,
            value: controlValue,
          };
          patientData.push(patientDataDict);
          controlData.push(controlDataDict);
        }
      }
      tempData = patientData.concat(controlData);
      // console.log(tempData);
      return tempData;
    } else {
      // else keep the old value
      return this.state.dataset;
    }
  }

  createDataDictFullSet(arrOfObjs) {
    // console.log(this.state.patients, 776);
    var patientsNames = this.state.patients.map((obj) => obj.value);
    if (patientsNames.includes("All")) {
      patientsNames = patients.slice(1).map((obj) => obj.value);
    }

    var controlsNames = this.state.controls.map((obj) => obj.value);
    if (controlsNames.includes("All")) {
      controlsNames = controls.slice(1).map((obj) => obj.value);
    }

    // console.log(787, {patientsNames, controlsNames});

    if (this.state.patients.length !== 0 && this.state.controls.length !== 0) {
      // key by biochemical name
      let metabolicNameDict = {};
      let metabolicDict = {};
      let bioName2Id = {};
      let tempData = [];

      arrOfObjs.forEach((obj) => {
        let key_ = obj.biochemicalName;
        if (metabolicNameDict[key_] === undefined) {
          metabolicNameDict[key_] = {};
        }
        metabolicNameDict[key_][obj.clientName] = obj.value;
        bioName2Id[obj.biochemicalName] = obj.biochemicalId;
      });
      /* STORE THIS TO BE MORE EFFICIENT */
      Object.keys(metabolicNameDict).forEach((key_) => {
        // const metabolicVals = metabolicNameDict[key_];
        // console.log(815, metabolicNameDict[key_]);
        let patientVals = patientsNames.map(
          (patient) => metabolicNameDict[key_][patient]
        );
        let controlsVals = controlsNames.map(
          (control) => metabolicNameDict[key_][control]
        );
        metabolicDict[key_] = {};
        metabolicDict[key_]["patient"] = patientVals;
        metabolicDict[key_]["control"] = controlsVals;
      });

      let metabolicNames = this.state.metabolicNames || new Map();
      var patientData = [];
      var controlData = [];
      for (var i = 0; i < metabolicNames.length; i++) {
        var r1 = metabolicNames[i];
        let r1Id = bioName2Id[r1];
        var m1Dict = metabolicDict[r1];
        if (!m1Dict || !m1Dict["patient"] || !m1Dict["control"]) {
          continue;
        }
        for (var j = 0; j < metabolicNames.length; j++) {
          var r2 = metabolicNames[j];
          let r2Id = bioName2Id[r2];
          var m2Dict = metabolicDict[r2];
          if (!m2Dict || !m2Dict["patient"] || !m2Dict["control"]) {
            continue;
          }
          let patientValue = this.getRatio(
            m1Dict["patient"],
            m2Dict["patient"]
          );
          let patientDataDict = {
            type: "patient",
            r1,
            r2,
            r1Id: r1Id,
            r2Id: r2Id,
            value: patientValue,
          };

          let controlValue = this.getRatio(
            m1Dict["control"],
            m2Dict["control"]
          );
          let controlDataDict = {
            type: "control",
            r1,
            r2,
            r1Id: r1Id,
            r2Id: r2Id,
            value: controlValue,
          };
          patientData.push(patientDataDict);
          controlData.push(controlDataDict);
        }
      }
      tempData = patientData.concat(controlData);
      // console.log(tempData);
      return tempData;
    } else {
      // else keep the old value
      return this.state.dataset;
    }
  }

  /* CURRENTLY averaging ratios */
  getRatio(arr1, arr2) {
    // console.log({arr1, arr2})
    var allRatios = [];
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== undefined && arr2[i] !== undefined) {
        allRatios.push(arr1[i] / arr2[i]);
      }
    }
    // console.log({allRatios});
    return this.sum(allRatios) / allRatios.length;
    // var allRatios = []
    // for (let i = 0; i < arr1.len; i++) {
    //   if (arr1[i] && arr2[i]) {
    //     allRatios.push(arr1[i]/arr2[i]);
    //   }
    // }
    // return this.sum(allRatios);
  }

  sum(arr) {
    const totalSum = arr.reduce((partialSum, a) => partialSum + a, 0);
    return totalSum;
  }

  getGlobals() {
    const globals = {
      plotCircleRadius: 50,
      mapWidth: 600,
      mapHeight: 600,
      legendHeight: 100,
      xTranslate: 300,
    };
    globals.margin = { top: 0, right: 400, bottom: 50, left: 400 };
    return globals;
  }

  drawViz() {
    const container = this.drawContainer();
    var viz = d3.select("#" + this.id);
    viz.selectAll("*").remove();
    viz.append(() => container.node());
    const info = this.createScaleFunctions(this.state.dataset);
    this.setState(info, () => {
      this.drawAxes();
      this.drawData(this.state.dataset);
      this.drawLegend();
    });
  }

  drawContainer() {
    // set the dimensions and margins of the graph
    const margin = this.globals.margin,
      width = this.globals.mapWidth,
      height = this.globals.mapHeight + this.globals.legendHeight;
    // append the svg object to the body of the page
    const svg = d3
      .create("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    svg
      .append("g")
      .attr("id", "container-" + this.id)
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    return svg;
  }

  getOrderedLabels(data) {
    // TODO: set does not preserve ordering so need to find a better way to get ordered labels; this seems to work for now
    const set = new Set(data.map((entry) => entry.r1));
    return [...set];
  }

  createScaleFunctions(data) {
    let currentStateInfo = {};
    const axisLabels = this.getOrderedLabels(data);
    let scaleX = d3
      .scalePoint()
      .domain(axisLabels)
      .range([100, this.globals.mapWidth - 100]);
    currentStateInfo.scaleX = scaleX;
    let scaleY = d3
      .scalePoint()
      .domain(axisLabels)
      .range([100, this.globals.mapHeight - 100]);
    currentStateInfo.scaleY = scaleY;
    currentStateInfo.axisLabels = axisLabels;

    // ** color scale **
    const maxValue = d3.max(data, (d) => d.value),
      minValue = d3.min(data, (d) => d.value);

    let color = d3.interpolateRdBu;
    let valuesRange = [minValue, maxValue];
    let helperScale = this.state
      .scaleFunction()
      .domain(valuesRange)
      .range([1, 0]);
    let colorScale = (val) => {
      //in: value; out: color
      return color(helperScale(val));
    };
    currentStateInfo.valuesRange = valuesRange;
    currentStateInfo.colorScale = colorScale;

    // ** size scale **
    let maxRadius = (this.globals.mapWidth - 200) / axisLabels.length / 2 - 5;
    let minRadius = maxRadius / 2;
    let radiusBounds = [minRadius, maxRadius];
    //console.log(radiusBounds);
    let sizeScale = this.state
      .scaleFunction()
      .domain(valuesRange)
      .range(radiusBounds);
    currentStateInfo.sizeScale = sizeScale;
    return currentStateInfo;
  }

  drawAxes() {
    // TODO: pass in container as arg
    let container = d3.select("#container-" + this.id);
    // container.append("text")
    //   .attr('x', this.state.scaleX(this.state.axisLabels[0]))
    //   .attr('y', this.state.scaleY(this.state.axisLabels[0]))
    //   .attr("transform", `translate(${-1 * this.globals.xTranslate-10}, -35)`)
    //   .style("font", "20px times")
    //   .text("Patient(s)")
    // container.append("text")
    //   .attr('x', this.state.scaleX(this.state.axisLabels[0]))
    //   .attr('y', this.state.scaleY(this.state.axisLabels[0]))
    //   .attr("transform", `translate(${1 * this.globals.xTranslate-10}, -35)`)
    //   .style("font", "20px times")
    //   .text("Control(s)")
    this.state.axisLabels.forEach((label) => {
      container
        .append("text") //simple way to add a new line
        .attr("x", this.state.scaleX(label))
        .attr("y", this.state.scaleY(label))
        .attr("transform", `translate(${this.calculateLabelOffset(true)}, 0)`)
        .attr("text-anchor", "left")
        .style("font", "15px times")
        .text(`${label}`);
    });

    this.state.axisLabels.forEach((label) => {
      container
        .append("text") //simple way to add a new line
        .attr("x", this.state.scaleX(label))
        .attr("y", this.state.scaleY(label))
        .attr("text-anchor", "left")
        .attr("transform", `translate(${this.calculateLabelOffset(false)}, 0)`)
        .style("font", "15px times")
        .text(`${label}`);
    });
  }

  calculateLabelOffset(onTheLeft) {
    let offset = onTheLeft ? -1 : 1;
    offset *= this.globals.xTranslate;

    let maxValue = this.state.valuesRange[1];
    let maxRadius = this.state.sizeScale(maxValue);
    offset += maxRadius;

    let numAxisLabels = this.state.axisLabels.length;
    offset += Math.floor(20 / numAxisLabels); // farther away if less circles to show
    return offset;
  }

  drawData(data) {
    let container = d3.select("#container-" + this.id);
    // patient
    container
      .append("g")
      .attr("id", "dots-patient-" + this.id)
      .attr("class", "dotsGroup-" + this.id)
      .attr("transform", `translate(${-1 * this.globals.xTranslate}, 0)`)
      .selectAll("dot")
      .data(data.filter((d) => d.type == "patient"))
      .join("circle")
      .attr("class", "patient")
      .attr("id", (d) => this.getCircleId(d, true))
      .attr("cx", (d) => this.state.scaleX(d.r1))
      .attr("cy", (d) => this.state.scaleY(d.r2))
      .attr("r", (d) => this.state.sizeScale(d.value))
      // .style("opacity", 0.7)
      .style("fill", (d) => this.state.colorScale(d.value))
      .style("stroke", "black")
      .style("stroke-width", "1px")
      .on("mouseover", (event, d) => this.handleMouseOver(event, d))
      .on("mouseout", (event, d) => this.handleMouseOut(event, d));
    // .on('click', handleTrendline);
    // control
    container
      .append("g")
      .attr("id", "dots-control-" + this.id)
      .attr("class", "dotsGroup-" + this.id)
      .attr("transform", `translate(${this.globals.xTranslate}, 0)`)
      .selectAll("dot")
      .data(data.filter((d) => d.type == "control"))
      .join("circle")
      .attr("class", "control")
      .attr("id", (d) => this.getCircleId(d, true))
      .attr("cx", (d) => this.state.scaleX(d.r1))
      .attr("cy", (d) => this.state.scaleY(d.r2))
      .attr("r", (d) => this.state.sizeScale(d.value))
      // .style("opacity", 0.7)
      .style("fill", (d) => this.state.colorScale(d.value))
      .style("stroke", "black")
      .style("stroke-width", "1px")
      .on("mouseover", (event, d) => this.handleMouseOver(event, d))
      .on("mouseout", (event, d) => this.handleMouseOut(event, d));
    // .on('click', handleTrendline);
  }

  handleMouseOver(event, d) {
    const thisElem = event.currentTarget; //d3.select(this);
    thisElem.style.stroke = "DeepPink";
    thisElem.style.strokeWidth = 5;

    const corrElem = d3.select("#" + this.getCircleId(d, false));
    corrElem.style("stroke", "DeepPink");
    corrElem.style("stroke-width", 5);

    this.addPopUp(event, d, true);
    this.addPopUp(event, d, false);
  }

  addPopUp(event, d, ofThisType) {
    let elem = d3.select("#" + this.getCircleId(d, ofThisType));
    //let transf = d3.select("#viz1DotsGroup").attr("transform");
    const cx = elem.attr("cx");
    //console.log("cx", cx);
    const cy = elem.attr("cy");
    //console.log(ofThisType, transf);

    let bar = d3
      .select("#" + this.getCircleGroupId(d, ofThisType))
      .append("g")
      .attr("id", this.getCirclePopupId(d, ofThisType));

    let header = this.getType(d, ofThisType);
    const value = ofThisType ? d.value : this.getCorrespondingValue(d);
    const roundTo3 = (num) => Number.parseFloat(num).toFixed(3);
    let body = `${d.r1} / ${d.r2} = ${roundTo3(value)}`;
    createPopUp(bar, cx, cy, header, body, this.globals.mapWidth);
  }

  getCorrespondingValue(d) {
    const type = this.getType(d, false); // finding a correspond value of d's type so ofThisType is false
    return this.state.dataset.filter(
      (obj) => obj.type == type && obj.r1 == d.r1 && obj.r2 == d.r2
    )[0].value;
  }

  handleMouseOut(event, d) {
    //console.log("mouseout event currTarget:", event.currentTarget)
    const thisElem = event.currentTarget; //d3.select(this);
    thisElem.style.stroke = "black";
    thisElem.style.strokeWidth = "1px";

    const corrElem = d3.select("#" + this.getCircleId(d, false));
    corrElem.style("stroke", "black");
    corrElem.style("stroke-width", "1px");

    d3.select("#" + this.getCirclePopupId(d, true)).remove();
    d3.select("#" + this.getCirclePopupId(d, false)).remove();
  }

  // ID functions
  getType(d, ofThisType) {
    let type;
    if (ofThisType) {
      type = d.type;
    } else {
      type = d.type == "patient" ? "control" : "patient";
    }
    return type;
  }

  getCircleGroupId(d, ofThisType) {
    let type = this.getType(d, ofThisType);
    return `dots-${type}-${this.id}`;
  }

  getCircleId(d, ofThisType) {
    let type = this.getType(d, ofThisType);
    return `id-${type}-${d.r1Id}-${d.r2Id}`;
  }

  getCirclePopupId(d, ofThisType) {
    return `popup-${this.getCircleId(d, ofThisType)}`;
  }

  drawLegend() {
    let container = d3.select("#container-" + this.id);

    // mirror interpolator
    const scale = this.state.scaleSeqFunction(
      this.state.valuesRange,
      d3.interpolateRdBu
    ); // same as t1
    const interpolator = scale.interpolator(); // read its interpolator
    const mirror = (t) => interpolator(1 - t); // creates a mirror image of the interpolator
    scale.interpolator(mirror); // updates the scale’s interpolator

    let legend = LegendD3(scale, {
      title: `Ratios (${this.state.scaleText} scale)`,
      ticks: 10,
      width: this.globals.mapWidth + 400,
      height: 65,
    });

    container
      .append("g")
      .attr("class", "legend-" + this.id)
      .attr("transform", `translate(-200, ${this.globals.mapHeight})`)
      .append(() => legend);
  }
}

function createPopUp(bar, cx, cy, header, body, mapWidth) {
  var background = bar.append("rect");
  if (header !== null) {
    bar
      .append("text")
      .attr("x", cx)
      .attr("y", cy - 45)
      .attr("text-anchor", "start")
      .style("font", "16px times")
      .text(header);
  }
  if (body !== null) {
    bar
      .append("text") //simple way to add a new line
      .attr("x", cx)
      .attr("y", cy - 30)
      .attr("text-anchor", "start")
      .style("font", "14px times")
      .text(body);
  }

  const rectWithDims = bar.node().getBBox();
  const xPadding = 5;
  const x = rectWithDims.x - xPadding;
  const width = rectWithDims.width + 2 * xPadding;
  background
    .attr("x", x) //some padding
    .attr("y", rectWithDims.y)
    .attr("width", width) //some padding
    .attr("height", rectWithDims.height)
    .attr("fill", "white")
    .style("stroke", "black")
    .style("stroke-dasharray", "1,2");

  if (x + width > mapWidth) {
    //if out of bounds on the right
    bar.attr("transform", `translate(${mapWidth - (x + width) - 10}, 0)`);
  }
}

// Copyright 2021, Observable Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/color-legend
function LegendD3(
  color,
  {
    title,
    tickSize = 6,
    width = 320,
    height = 44 + tickSize,
    marginTop = 18,
    marginRight = 0,
    marginBottom = 16 + tickSize,
    marginLeft = 0,
    ticks = width / 64,
    tickFormat,
    tickValues,
  } = {}
) {
  function ramp(color, n = 256) {
    const canvas = document.createElement("canvas");
    canvas.width = n;
    canvas.height = 1;
    const context = canvas.getContext("2d");
    for (let i = 0; i < n; ++i) {
      context.fillStyle = color(i / (n - 1));
      context.fillRect(i, 0, 1, 1);
    }
    return canvas;
  }

  const svg = d3
    .create("svg")
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [0, 0, width, height])
    .style("overflow", "visible")
    .style("display", "block");

  let tickAdjust = (g) =>
    g.selectAll(".tick line").attr("y1", marginTop + marginBottom - height);
  let x;

  // Continuous
  if (color.interpolate) {
    const n = Math.min(color.domain().length, color.range().length);

    x = color
      .copy()
      .rangeRound(
        d3.quantize(d3.interpolate(marginLeft, width - marginRight), n)
      );

    svg
      .append("image")
      .attr("x", marginLeft)
      .attr("y", marginTop)
      .attr("width", width - marginLeft - marginRight)
      .attr("height", height - marginTop - marginBottom)
      .attr("preserveAspectRatio", "none")
      .attr(
        "xlink:href",
        ramp(
          color.copy().domain(d3.quantize(d3.interpolate(0, 1), n))
        ).toDataURL()
      );
  }

  // Sequential
  else if (color.interpolator) {
    x = Object.assign(
      color
        .copy()
        .interpolator(d3.interpolateRound(marginLeft, width - marginRight)),
      {
        range() {
          return [marginLeft, width - marginRight];
        },
      }
    );

    svg
      .append("image")
      .attr("x", marginLeft)
      .attr("y", marginTop)
      .attr("width", width - marginLeft - marginRight)
      .attr("height", height - marginTop - marginBottom)
      .attr("preserveAspectRatio", "none")
      .attr("xlink:href", ramp(color.interpolator()).toDataURL());

    // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
    if (!x.ticks) {
      if (tickValues === undefined) {
        const n = Math.round(ticks + 1);
        tickValues = d3
          .range(n)
          .map((i) => d3.quantile(color.domain(), i / (n - 1)));
      }
      if (typeof tickFormat !== "function") {
        tickFormat = d3.format(tickFormat === undefined ? ",f" : tickFormat);
      }
    }
  }

  // Threshold
  else if (color.invertExtent) {
    const thresholds = color.thresholds
      ? color.thresholds() // scaleQuantize
      : color.quantiles
      ? color.quantiles() // scaleQuantile
      : color.domain(); // scaleThreshold

    const thresholdFormat =
      tickFormat === undefined
        ? (d) => d
        : typeof tickFormat === "string"
        ? d3.format(tickFormat)
        : tickFormat;

    x = this.state
      .scaleFunction()
      .domain([-1, color.range().length - 1])
      .rangeRound([marginLeft, width - marginRight]);

    svg
      .append("g")
      .selectAll("rect")
      .data(color.range())
      .join("rect")
      .attr("x", (d, i) => x(i - 1))
      .attr("y", marginTop)
      .attr("width", (d, i) => x(i) - x(i - 1))
      .attr("height", height - marginTop - marginBottom)
      .attr("fill", (d) => d);

    tickValues = d3.range(thresholds.length);
    tickFormat = (i) => thresholdFormat(thresholds[i], i);
  }

  // Ordinal
  else {
    x = d3
      .scaleBand()
      .domain(color.domain())
      .rangeRound([marginLeft, width - marginRight]);

    svg
      .append("g")
      .selectAll("rect")
      .data(color.domain())
      .join("rect")
      .attr("x", x)
      .attr("y", marginTop)
      .attr("width", Math.max(0, x.bandwidth() - 1))
      .attr("height", height - marginTop - marginBottom)
      .attr("fill", color);

    tickAdjust = () => {};
  }

  svg
    .append("g")
    .attr("transform", `translate(0,${height - marginBottom})`)
    .call(
      d3
        .axisBottom(x)
        .ticks(ticks, typeof tickFormat === "string" ? tickFormat : undefined)
        .tickFormat(typeof tickFormat === "function" ? tickFormat : undefined)
        .tickSize(tickSize)
        .tickValues(tickValues)
    )
    .call(tickAdjust)
    .call((g) => g.select(".domain").remove())
    .call((g) =>
      g
        .append("text")
        .attr("x", marginLeft)
        .attr("y", marginTop + marginBottom - height - 6)
        .attr("fill", "currentColor")
        .attr("text-anchor", "start")
        .attr("font-weight", "bold")
        .attr("class", "title")
        .text(title)
    );

  return svg.node();
}

export default MetabolomicsPage;
