import React, { useCallback, useState } from 'react';

import { findFavorite } from '../../services/nutrient';
import NutrientSearch from './FoodSearch';

const FavoriteFetchForm = (props) => {
  const { onItemsLoaded } = props;
  const [loading, setLoading] = useState(false);

  const load = useCallback(async (mask) => {
    setLoading(true);
    try {
      const items = await findFavorite(mask);
      onItemsLoaded(items);
      setLoading(false);
    }
    catch (ex) {
      setLoading(false);
      console.log(ex);
    }
  }, [onItemsLoaded]);

  const onSearchRequested = useCallback((mask) => {
    load(mask);
  }, [load]);
  
  return (
    <NutrientSearch loading={loading} className="my-05"
      onSearchRequested={onSearchRequested} />
  );
};

export default FavoriteFetchForm;