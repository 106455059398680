import React, { useCallback, useEffect } from 'react';
import { 
  Box, 
  Grid, 
  Divider,
  Button,
} from '@mui/material';

import { useNavigate } from 'react-router';

import { STORE } from '../../store';
import { useAsyncAppState  } from '../../hooks/useAsyncAppState';
import { resolveKeggs, loadConsole, loadResult } from '../../services/metaGenetics';

import Layout from '../layout/Layout';

const MetaGenePage = () => {
  const navigate = useNavigate();

  const [, resolveKegssAsync] = useAsyncAppState(
    STORE.areas.metaGene.resolve,
    resolveKeggs
  );

  const processConsole = useCallback(async () => {
    let complete = false;
    let error = false;

    try {
      const buffer = await loadConsole();
      complete = buffer === null;

      if (buffer && buffer.length) {
        buffer.forEach((line) => {
          console.log(line);
          complete = line === 'Complete';
          error = line === 'Error';
        });

        if (complete) {
          const geneLinks = await loadResult();
          console.log(geneLinks);
        }
      }
    }
    catch (ex) {
      console.log(ex);
      error = true;
    }

    if (!complete && !error) {
      setTimeout(processConsole, 2000);
    }
  }, []);

  const onResolve = useCallback(async () => {
    await resolveKegssAsync();
    processConsole();
  }, [resolveKegssAsync, processConsole]);

  const onExit = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  useEffect(() => {
    processConsole();
  }, [processConsole]);

  return (
    <Layout>
      
      <Box className="vertical-flex h-100">
        <h3>
          Resolve Meta Gene 
        </h3>
        <Box className="vertical-flex-grow">
          <Grid container className="justify-content-center pb-1">  
            <Grid item xs={12} md={5} lg={2}>
              <Button color="primary" size="large"
                variant="contained"
                className="w-100"
                onClick={() => onResolve()}>
                Resolve Kegg records
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box className="buttons-footer">
          <Divider />
            <Grid container className="justify-content-center pb-1">
              <Grid item xs={12} md={5} lg={2}>
                <Button color="primary" size="large"
                  variant="contained"
                  className="w-100"
                  onClick={onExit}>
                  Back to Home Page
                </Button>
              </Grid>
            </Grid>
        </Box>
      </Box>
    </Layout>
  );
}

export default MetaGenePage;
