import React, { useEffect, useRef, useCallback } from "react";
import moment from 'moment';
import { Timeline, DataSet } from "vis-timeline/standalone";
import 'vis-timeline/styles/vis-timeline-graph2d.css';

import { STORE } from '../../store';
import { useDialog } from '../../hooks/useDialog';
import { useAppState } from '../../hooks/useAppState';
import { useAsyncAppState, getAsyncDefault } from '../../hooks/useAsyncAppState';
import { saveSymptom, removeSymptom, loadSymptoms } from '../../services/symptom';
import { translateSymbolToMin, maxIterationCount, daysRange } from '../dialogs/editSymptomData';
import LoaderWrapper from '../common/LoaderWrapper';

let timeline = null;

const startDisplayDate = moment().add(-7, 'days');
const endDate = moment().add(1, 'days');

const baseOptions = {
  height: '100%',
  width: '100%',
  editable: true,
  selectable: true,
  max: endDate,
  zoomMin: 1000 * 60 * 60, //  1 hour
  zoomMax: 1000 * 60 * 60 * 24 * 30 * 12 * 100,
  horizontalScroll: false,
  verticalScroll: false,
  showTooltips: true,
  margin: {
    item: 5,
    axis: 40,
  },
  stack: true,
};

let items = null;

const mapItems = ((symptoms) => {
  const itemsRaw = [];
  symptoms.forEach((symptom) => {
    if (symptom.period) {
      const countPerDay = maxIterationCount(symptom.duration, symptom.period);
      const countDays = daysRange(symptom.start, symptom.periodEnd) + 1;
      const periodMins = translateSymbolToMin(symptom.period)
      const durationMins = translateSymbolToMin(symptom.duration);
      const count = countDays * (countPerDay > 10 ? 10 : countPerDay);

      for (let index = 0; index < count; index++) {
        const start = moment(symptom.start).add(index * (durationMins + periodMins), 'minutes');

        itemsRaw.push({
          id: `|${symptom._id}|${index}`,
          content: symptom.content,
          title: symptom.description,
          className: 'vis-period',
          start: start,
        });
      }
    }
    else {
      itemsRaw.push({
        id: symptom._id,
        content: symptom.content,
        title: symptom.description,
        start: symptom.start,
      });
    }
  });

  items = new DataSet(itemsRaw);
  return items;
});

const SymptomsTimeline = () => {
  const [profile] = useAppState(STORE.common.profile);
  const [symptomsCommon, setSymptomsCommon] = useAppState(STORE.common.symptoms);
  const [symptomsLoading, loadSymptomsAsync] = useAsyncAppState(STORE.areas.symptom.load, loadSymptoms);
  const [symptomLoading, saveSymptomAsync] = useAsyncAppState(STORE.areas.symptom.save, saveSymptom);
  const [, removeSymptomAsync] = useAsyncAppState(STORE.areas.symptom.save, removeSymptom);

  const [openSymptomDialog] = useDialog(STORE.areas.symptom.dialog);
  const timelineRef = useRef(null);
  const symptoms = useRef(symptomsCommon.data);

  const refreshSymptoms = useCallback(async() => {
    const newSymptoms = await loadSymptomsAsync();
    symptoms.current = newSymptoms;
    setSymptomsCommon({...getAsyncDefault(), loaded: true, data: newSymptoms});

    const newItems = mapItems(newSymptoms);
    timeline.setItems(newItems);
    console.log('Refreshing symptoms');
  }, [setSymptomsCommon, loadSymptomsAsync]);

  useEffect(() => {
    const options = {
      ...baseOptions,
      min: profile?.data?.DOB ? moment(profile.data.DOB) : moment().add(-100, 'years'),
      onUpdate: async (item, callback) => {
        let id = item.id;
        if (id.startsWith('|')) {
          const ids = id.split('|');
          id = ids[1];
        }
        const symptom = await openSymptomDialog({
          symptomId: id,
          extended: true
        });

        if (symptom) {
          await saveSymptomAsync(symptom);
          await refreshSymptoms();
        }
      },
      onMove: async (item, callback) => {
        if (item.start && moment(item.start).isAfter()) {
          callback(null);
          return;
        }
        if (item.id.startsWith('|')) {
          callback(null);
          return;
        }
        const symptomToUpdate = symptoms.current.find((symptom) => symptom._id === item.id);
        try {
          if (symptomToUpdate) {
            await saveSymptomAsync({ ...symptomToUpdate, start: item.start });
            await refreshSymptoms();
            callback(item);
          }
          else {
            throw new Error(`Symptom id:${item.id} not found`);
          }
        }
        catch(ex) {
          callback(null);
        }
      },
      onAdd: async (item, callback) => {
        if (item.start && moment(item.start).isAfter()) {
          callback(null);
          return;
        }
        const symptom = await openSymptomDialog({
          symptomId: null,
          symptomDate: item.start,
          extended: true
        });

        if (symptom) {
          await saveSymptomAsync(symptom);
          await refreshSymptoms();
        }
      },
      onRemove: async (item, callback) => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Delete symptom?')) {
          callback(false);
          return;
        }
        let id = item.id;
        if (id.startsWith('|')) {
          const ids = id.split('|');
          id = ids[1];
        }
        const symptomToDelete = symptoms.current.find((symptom) => symptom._id === id);
        try {
          if (symptomToDelete) {
            await removeSymptomAsync(symptomToDelete._id)
            await refreshSymptoms();
          }
          else {
            throw new Error(`Symptom id:${id} not found`);
          }
        }
        catch(ex) {
          callback(false);
        }
      },
    };

    timeline = new Timeline(timelineRef.current, mapItems(symptoms.current), options);
    timeline.setWindow(startDisplayDate, endDate);

    const currentTimeMarker = document.createElement("div");
    currentTimeMarker.className = "current-time-marker";
    timelineRef.current.appendChild(currentTimeMarker);

    refreshSymptoms();

    return () => {
      timeline.destroy();
    };
  }, [
    profile,
    refreshSymptoms,
    openSymptomDialog,
    saveSymptomAsync,
    removeSymptomAsync
  ]);

  return (
    <LoaderWrapper items={[symptomLoading, symptomsLoading]} show>
      <div ref={timelineRef} className="h-100" />
    </LoaderWrapper>
  );
};

export default SymptomsTimeline;
