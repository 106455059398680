import axios from "axios";

export const loadSymptoms = async() => {
  const url = `${process.env.REACT_APP_API_URL}/server/getAllSymptoms`;
  const response = await axios.get(url);
  
  return (response.data || []).filter((symptom) => symptom.start !== null);
};

export const loadById = async(id) => {
  const url = `${process.env.REACT_APP_API_URL}/server/getSymptom/${id}`;
  const response = await axios.get(url);
  return response.data && response.data.length ? response.data[0] : null;
};

export const loadByAnswerId = async(answerId) => {
  const url = `${process.env.REACT_APP_API_URL}/server/getSymptomByAnswer/${answerId}`;
  const response = await axios.get(url);
  return response.data && response.data.length ? response.data[0] : null;
};

export const saveSymptom = async(symptom) => {
  let response = null;

  if (symptom._id) {
    const url = `${process.env.REACT_APP_API_URL}/server/updateSymptom/${symptom._id}`;
    response = await axios.put(url, symptom);
  }
  else {
    const url = `${process.env.REACT_APP_API_URL}/server/createSymptom`;
    response = await axios.post(url, symptom);
  }

  return response.data;
};

export const removeSymptom = async(id) => {
  const url = `${process.env.REACT_APP_API_URL}/server/deleteSymptom/${id}`;
  await axios.delete(url);

  return null;
};