import * as d3 from 'd3';

let tree = d3.tree;
let hierarchy = d3.hierarchy;
let select = d3.select;

const getNetDataRecursive = (parent, resultParent) => {
  if (parent.node) {
    resultParent.id = parent.node.ID;
    resultParent.name = parent.node.TEXT.length ? parent.node.TEXT.trim() : 'No name';

    if (parent.node.LINK) {
      resultParent.link = parent.node.LINK;
    }

    if (parent.node.children) {
      parent.node.children.forEach((child) => {
        if (child.node) {
          const resultChild = {};
          getNetDataRecursive(child, resultChild);

          if (!resultParent.children) {
            resultParent.children = [];
          };
          resultParent.children.push(resultChild);
        }
      });
    }
  }
};

const getNetData = (mapData) => {
  const resultNode = {};
  if (mapData?.map?.children?.length) {
    getNetDataRecursive(mapData.map.children[0], resultNode);
  }
  return resultNode;
};

class MyTree {
  margin;
  width;
  height;
  barHeight;
  barWidth;
  i;
  duration;
  tree;
  root;
  svg;

  $onInit(mapData, index, onLink, onLinkClose) {
    const data = getNetData(mapData);
    this.margin = { top: 20, right: 10, bottom: 20, left: 10 };
    this.width = 1400 - this.margin.right - this.margin.left;
    this.height = 250 - this.margin.top - this.margin.bottom;
    this.barHeight = 40;
    this.barWidth = this.width *.8;
    this.i = 0;
    this.duration = 550;
    this.tree = tree().nodeSize([0, 30]); 
    this.root = this.tree(hierarchy(data));
    this.root.each((d)=> {
      d.name = d.id; //transferring name to a name variable
      d.id = this.i; //Assigning numerical Ids
      this.i++;
    });
    this.root.x0 = this.root.x;
    this.root.y0 = this.root.y
    this.index = index;
    this.onLink = onLink;
    this.onLinkClose = onLinkClose;
    
    const container = document.createElement('div');
    container.id = `hierarchy-container${this.index}`;

    const divider = document.createElement('div');
    divider.className = 'gradient-divider w-100';

    const main = document.querySelector('.hierarchy-container');
    main.append(container);
    main.append(divider);

    this.svg = select(`#hierarchy-container${this.index}`).append('svg')
      .attr('width', '100%') // this.width + this.margin.right + this.margin.left)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    this.root.children.forEach(this.collapse);
    this.update(this.root);
  }
  
  connector = function(d) {
    //straight
    return 'M' + d.parent.y + ',' + d.parent.x + 'V' + d.x + 'H' + d.y;      
  };
  
  collapse = (d) => {
    if (d.children) {
      d._children = d.children;
      d._children.forEach(this.collapse);
      d.children = null;
    }
  };

  click = (e) => {
    const data = select(e.target).data();
    const node = data?.length ? data[0] : null;
    if (!node) {
      return;
    }

    const url = node.data.link;
    const open = Boolean(node.children);
    if (url) {
      if (open) {
        this.onLinkClose();
        this.currentLink = null;
      }
      else if (url !== this.currentLink) {
        this.onLink(url);
        this.currentLink = url;
      }
    }

    if (node.children) {
      node._children = node.children;
      node.children = null;
    } else {
      node.children = node._children;
      node._children = null;
    }
    this.update(node);
  };

  update = (source) => {
    this.width = 800;
    // Compute the new tree layout.
    let nodes = this.tree(this.root);
    let nodesSort = [];

    nodes.eachBefore(function (n) {
      nodesSort.push(n);
    });

    this.height = Math.max(300, nodesSort.length * this.barHeight + this.margin.top + this.margin.bottom);
    
    let links = nodesSort.slice(1);
    // Compute the 'layout'.
    nodesSort.forEach ((n, i)=> {
      n.x = i * this.barHeight;
    });

    select(`#hierarchy-container${this.index} svg`)
      .transition()
      .duration(this.duration)
      .attr('height', this.height);

    // Update the nodes…
    let node = this.svg.selectAll('g.node')
      .data(nodesSort, function (d) {
        return d.id || (d.id = ++this.i);
      });

    // Enter any new nodes at the parent's previous position.
    var nodeEnter = node.enter().append('g')
      .attr('class', (d) => d.data.link ? 'node url-link' : 'node')
      .attr('transform', () => 'translate(' + source.y0 + ',' + source.x0 + ')')
      .on('click', this.click);

    nodeEnter.append('circle')
      .attr('r', 1e-6)
      .style('fill', function (d) {
        return d._children ? 'lightsteelblue' : '#fff';
      });
    
    nodeEnter
      .append('text')
      .attr('x', 10)
      .attr('dy', '.35em')
      .attr('text-anchor', 'start')
      .text((d) => d.data.name)
      .style('fill-opacity', 1e-6)
      .style('font-weight', (d) => {
        console.log(d.id, source.id);
        return d.id === source.id ? 'bold' : 'normal';
      })

    nodeEnter.append('svg:title').text(function (d) {
      return d.data.name;
    });

    // Transition nodes to their new position.
    let nodeUpdate = node.merge(nodeEnter)
      .transition()
      .duration(this.duration);
    
    nodeUpdate.attr('transform', function (d) {
      return 'translate(' + d.y + ',' + d.x + ')';
    });

    nodeUpdate.select('circle')
      .attr('r', 6.5)
      .style('fill', function (d) {
        return d._children ? 'lightsteelblue' : '#fff';
      });
    
    nodeUpdate.select('text')
      .style('fill-opacity', 1);

    // Transition exiting nodes to the parent's new position (and remove the nodes)
    var nodeExit = node.exit().transition().duration(this.duration);
    
    nodeExit
      .attr('transform', function (d) {
        return 'translate(' + source.y + ',' + source.x + ')';
      })
      .remove();

    nodeExit.select('circle')
      .attr('r', 1e-6);

    nodeExit.select('text')
      .style('fill-opacity', 1e-6);
    
    // Update the links…
    var link = this.svg.selectAll('path.link')
      .data(links, function (d) {
        // return d.target.id;
        var id = d.id + '->' + d.parent.id;
        return id;
      });

    // Enter any new links at the parent's previous position.
    let linkEnter = link.enter().insert('path', 'g')
      .attr('class', 'link')
      .attr('d', (d) => {
        var o = { x: source.x0, y: source.y0, parent: { x: source.x0, y: source.y0 }};
        return this.connector(o);
      });
    
    // Transition links to their new position.
    link.merge(linkEnter).transition()
      .duration(this.duration)
      .attr('d', this.connector);

    // // Transition exiting nodes to the parent's new position.
    link.exit().transition()
      .duration(this.duration)
      .attr('d', (d) => {
      var o = { x: source.x, y: source.y, parent: { x: source.x, y: source.y }};
      return this.connector(o);
    })
    .remove();

    // Stash the old positions for transition.
    nodesSort.forEach(function (d) {
      d.x0 = d.x;
      d.y0 = d.y;
    });
  }
};
    
export default MyTree;