export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm';

export const SESSION_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  USER_EMAIL: 'userEmail'
}

export const ROLES = {
  PATIENT: 'patient',
  RESEARCHER: 'researcher'
}