export const MED_TYPE = {
  CUSTOM: 'custom',
  DAILYMED: 'dailymed',
};

export const MED_ACTIONS = {
  INIT: 'init_med',
  ADD: 'add_med',
  UPDATE: 'update_med',
  REMOVE: 'remove_med'
};

export const compareNames = (left, right) => {
  return (left || '').trim().toLocaleLowerCase() === (right || '').trim().toLocaleLowerCase();
};

const createDailyResult = (medItems, iteration) => {
  return {
    medItems: [...medItems],
    iteration,
  }
};

export const initDaily = (medItems) => {
  return createDailyResult(medItems, 0);
};

export const addDailyRecord = (medItem, data) => {
  const iteration = data.iteration + 1;
  const medItems = data.medItems;
  medItems.push({...medItem });

  return createDailyResult(medItems, iteration);
};

export const updateDailyRecord = (medItem, index, data) => {
  const iteration = data.iteration + 1;
  const medItems = data.medItems;
  medItems[index] = medItem;
  
  return createDailyResult(medItems, iteration);
};

export const removeDailyRecord = (index, data) => {
  const iteration = data.iteration + 1;
  const medItems = data.medItems;
  medItems.splice(index, 1);

  return createDailyResult(medItems, iteration);
};

export const refreshDailyRecord = (data) => {
  const medItems = data.medItems;
  
  return createDailyResult(medItems, data.iteration);
};

export const getSaveMedItems = (medItems) => medItems;

export const QUANTITY_LIST = [...Array(6).keys()].map((index) => {
  const quantity = index === 0 ? 0.5 : index;
  let text = 'None';
  if (quantity === 0.5) {
    text = 'Half doze';
  }
  else if (quantity === 1) {
    text = '1 doze';
  }
  else {
    text = `${quantity} dozes`;
  }
  return { value: quantity, text };
});

export const dataReducer = (state, action) => {
  if (action.type === MED_ACTIONS.INIT) {
    const medItems = action.medItems;
    const medData = initDaily(medItems);
    return { ...state, ...medData };
  }
  else if (action.type === MED_ACTIONS.ADD) {
    const medItem = action.medItem;
    const medData = addDailyRecord(medItem, state);
    return { ...state, ...medData };
  }
  else if (action.type === MED_ACTIONS.UPDATE) {
    const medItem = action.medItem;
    const index = action.index;
    const medData = updateDailyRecord(medItem, index, state);
    return { ...state, ...medData };
  }
  else if (action.type === MED_ACTIONS.REMOVE) {
    const index = action.index;
    const medData = removeDailyRecord(index, state);
    return { ...state, ...medData };
  }

  throw Error('Unknown action.');
};