import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  Button,
  Tabs,
  Tab,
}
from '@mui/material';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

import { Network } from 'vis-network'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const getRandomColor = (opacity = 0.8, max = 255, min = 0) => {
  const r = Math.random() * (max - min) + min;
  const g = Math.random() * (max - min) + min;
  const b = Math.random() * (max - min) + min;
  return `rgba(${r},${g},${b},${opacity})`;
};

const getChartBarData = ({ geneData, geneCode, geneName }) => {
  const data = {
    labels: [],
    datasets: []
  };

  if (geneData.length) {
    geneData.forEach((link) => {
      if (!data.labels.find((label) => label === link.gene)) {
        data.labels.push(link.gene);
      }

      if (!data.datasets.find((dataset) => dataset.label === link.name)) {
        data.datasets.push({
          data: [],
          label: link.name,
          yAxisID: 'links',
          backgroundColor: getRandomColor()
        });
      }
    });
      
    data.labels.forEach((gene) => {
      data.datasets.forEach((dataset) => {
        const scope = geneData.find((link) => link.name === dataset.label && link.gene === gene)?.score || 0;
        dataset.data.push(scope);
      });
    });
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Gene Code:',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${context.dataset.data[context.dataIndex]}`;
          }
        }
      }
    },
    scales: {
      links: {
        min: 0,
        display: true
      }
    },
    plugins: {
      title: {
        text: `Gene Code: ${geneCode} (${geneName})`
      }
    }
  };

  if (!data.labels.length) {
    data.datasets.push({
      data: [],
      label: 'No Data Defined',
      yAxisID: 'links',
      backgroundColor: getRandomColor()
    })
    data.labels.push('No Data Defined');
  }

  return { 
    data, 
    options 
  };
};

const getNetChartData = ({ geneData, geneCode, geneName }) => {
  const netNodes = [];
  const roots = [];
  
  geneData.forEach((link) => {
    if (!roots.find((root) => root === link.name)) {
      roots.push(link.name);

      const rootId = `${link.name}_${geneCode}`;
      netNodes.push({
        id: rootId, 
        value: 100, 
        label: geneCode, 
        title: `${link.name}: ${geneCode} (${geneName})`,
        color: getRandomColor(1)    
      });
    }
  });
  
  const netEdges = [];
  roots.forEach((name) => {
    geneData.filter((link) => link.name === name).forEach((link) => {
      const rootId = `${link.name}_${geneCode}`;
      const root = netNodes.find((node) => node.id === rootId);

      const toId = `${link.name}_${link.gene}`;
      netNodes.push({ 
        id: toId, 
        value: 100, 
        label: link.gene,
        title: `${link.gene}: ${link.score}`,
        color: geneData.filter((linkCheck) => linkCheck.gene === link.gene).length > 1 ? 'black' : root.color
      });
      
      netEdges.push({ 
        from: rootId, to: toId, 
        value: link.score / 100,
        length: 50000 / link.score,
        title: link.score
      });
    });
  });

  const data = {
    nodes: netNodes,
    edges: netEdges,
  };

  const options = {
    nodes: {
      shape: 'dot'
    },
  };

  return {
    data,
    options
  }
};

const GeneCrudDialog = (props) => {
  const {
    visible,
    handleClose,
    geneCode,
    geneName,
    geneLinks
  } = props;

  const divNetworkRef = useRef();
  const [data, setData] = useState(null);
  const [barData, setBarData] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const onTabSelect = useCallback((e, newTabIndex) => {
    setTabIndex(newTabIndex);
  }, [setTabIndex]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (tabIndex === 0) {
      const barData = getChartBarData(data);
      setBarData(barData);
    }
    else if (tabIndex === 1) {
      const netData = getNetChartData(data);      
      new Network(divNetworkRef.current, netData.data, netData.options);
    }
  }, [data, tabIndex]);
  
  useEffect(() => {
    if (visible && geneCode && geneName && geneLinks) {
      const geneData = geneLinks
        .filter((link) => link.left === geneCode || link.right === geneCode)
        .map((link) => ({
          name: link.name,
          gene: link.left === geneCode ? link.right : link.left,
          score: link.score
        }));

      setData({
        geneData,
        geneCode,
        geneName
      });
    }
  }, [visible, geneCode, geneName, geneLinks, setData]);

  return (
    <Dialog open={visible} fullWidth maxWidth="xl" 
      onClose={() => handleClose(null)}>
      <DialogTitle>
        Gene Charts
        <Divider />
      </DialogTitle>
      
      <DialogContent>
        <Tabs value={tabIndex} onChange={onTabSelect} className="pb-1">
          <Tab label="Bar Chart"></Tab>
          <Tab label="Graph Chart"></Tab>
        </Tabs>

        {tabIndex === 0 && barData && <Box>
          <Bar options={barData.options} data={barData.data} />
        </Box>}

        {tabIndex === 1 && <Box>
          <div ref={divNetworkRef} style={{ width: '100%', height: '60vh' }}></div>
        </Box>}
        
      </DialogContent>
      
      <DialogActions>
        <Button onClick={() => handleClose(null)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GeneCrudDialog;