import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  IconButton
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const FoodGroup = ({ foodTime, items, totals, showEnergy, onEditFood, onDeleteFood }) => {
  return (
    <TableContainer component={Paper} sx={{ marginBottom: '1rem' }}>
      <Table sx={{ minWidth: '20rem' }} size="small" className="food-table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '70%' }}>
              {foodTime}
            </TableCell>
            <TableCell colSpan={2}>{showEnergy ? 'Energy, kcal' : 'Weight, g'}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {items.map((foodItem, index) => (
            <TableRow key={`${foodItem.foodData._id}_${index}`}>
              <TableCell className="p-0">
                <Button className="food-description" 
                  onClick={() => onEditFood(foodItem)}>
                  {foodItem.foodData.description}
                </Button>
              </TableCell>
              <TableCell>{showEnergy ? foodItem.energy : foodItem.weight}</TableCell>
              <TableCell className="p-0" sx={{ textAlign: 'right' }}>
                <IconButton className="food-delete-button" 
                  onClick={() => onDeleteFood(foodItem)}>
                  <CloseIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}

          {items.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>
                No Selection
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter className="food-totals" >
          <TableRow>
            <TableCell>
              {foodTime} Total:
            </TableCell>
            <TableCell colSpan={2}>
              {showEnergy ? `${totals.energy} kсal` : `${totals.weight} g`}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default FoodGroup;