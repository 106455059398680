import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  IconButton
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { MED_TYPE } from './medicinesData';

const MedicinesDailyList = ({ items, onEditMedicine, onDeleteMedicine }) => {
  return (
    <TableContainer component={Paper} sx={{ marginBottom: '1rem' }}>
      <Table sx={{ minWidth: '20rem' }} size="small" className="food-table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '80%' }}>Medicine</TableCell>
            <TableCell className="text-right">Dosage</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {items.map((medItem, index) => (
            <TableRow key={`${medItem._id}_${index}`}>
              <TableCell className="p-0">
                <Button className="food-description" 
                  onClick={() => onEditMedicine(medItem, index)}>
                  {medItem.medData.name} {medItem.medType === MED_TYPE.CUSTOM && '(custom)'}
                </Button>
              </TableCell>
              <TableCell className="text-right">
                {medItem.quantity}
              </TableCell>
              <TableCell className="p-0" sx={{ textAlign: 'right' }}>
                <IconButton className="food-delete-button" 
                  onClick={() => onDeleteMedicine(index)}>
                  <CloseIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}

          {items.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>
                No Selection
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter className="food-totals" >
          <TableRow>
            <TableCell colSpan={3}>
              Total Items: {items.length}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default MedicinesDailyList;