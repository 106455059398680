import React, { useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router';

import { STORE } from '../../store';
import { loadSurvey } from '../../services/survey';
import { useAsyncAppState } from '../../hooks/useAsyncAppState';
import { useAppState } from '../../hooks/useAppState';
import Layout from '../layout/Layout';
import LoaderWrapper from '../common/LoaderWrapper';

import { TYPE_MULTI, TYPE_YESNO, calculateResult } from './surveyData';
import SurveyYesNo from './SurveyYesNo';
import SurveyMulti from './SurveyMulti';
import './survey.css';

const SurveyPage = () => {
  const navigate = useNavigate();
  const [survey, loadSurveyAsync] = useAsyncAppState(
    STORE.areas.survey.items.load,
    loadSurvey
  );

  const [selectedCategory, setSelectedCategory] = useAppState(
    STORE.areas.survey.category.select
  );

  const [, setServeyComplete] = useAppState(
    STORE.areas.survey.items.complete
  );

  useEffect(() => {
    if (selectedCategory) {
      loadSurveyAsync(selectedCategory.name);
    }
    else {
      navigate('/home');
    }
  }, [selectedCategory, loadSurveyAsync, navigate]);

  const onComplete = useCallback(async (result, type) => {
    calculateResult(selectedCategory, result);
    setServeyComplete(result);
    navigate('/survey-complete');
  }, [selectedCategory, setServeyComplete, navigate]);

  return (
    <Layout>
      <LoaderWrapper items={[survey]}>
        {selectedCategory && (
          <Box className="vertical-flex h-100">
            <h3>
              {selectedCategory.name}
            </h3>
            <Box className="vertical-flex-grow">
              {selectedCategory.type === TYPE_YESNO && <SurveyYesNo
                category={selectedCategory}
                data={survey.data}
                onComplete={onComplete} />}

              {selectedCategory.type === TYPE_MULTI && <SurveyMulti
                category={selectedCategory}
                data={survey.data}
                onComplete={onComplete} />}  
            </Box>
          </Box>
        )}
      </LoaderWrapper>
    </Layout>
  );
}

export default SurveyPage;
