import axios from 'axios';

export const loadPatients = async () => {
  const url = `${process.env.REACT_APP_API_URL}/metaGene/patients`;
  const response = await axios.get(url);

  return response.data;
};

export const resolveKeggs = async () => {
  const url = `${process.env.REACT_APP_API_URL}/metaGene/resolve`;
  const response = await axios.get(url);

  return response.data;
};

export const loadConsole = async () => {
  const url = `${process.env.REACT_APP_API_URL}/metaGene/readConsole`;
  const response = await axios.get(url);
  if (response.name === 'AxiosError') {
     throw new Error(response.message);
  }

  return response.data;
};

export const loadResult = async () => {
  const url = `${process.env.REACT_APP_API_URL}/metaGene/readResult`;
  const response = await axios.get(url);

  return response.data;
};

