import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { Button } from 'rsuite';

const MAX_VISIBLE = 6;
const Genetics = ({ data }) => {
  const { genetics } = data;

  const items = useMemo(() => genetics?.geneMap || [], [genetics]);
  const moreMax = items.length > MAX_VISIBLE;
  const [allVisible, setAllVisible] = useState(!moreMax);

  return (
    <div>
      <h2>
        Genetics
      </h2>

      <div className="container">
        <div className="row d-none d-lg-flex">
          <div className="col-lg-2 cell-header">SNP</div>
          <div className="col-lg-3 cell-header">Gene Name</div>
          <div className="col-lg-2 cell-header">Significance</div>
          <div className="col-lg-2 cell-header">Variants</div>
          <div className="col-lg-1 cell-header">Frequency</div>
          <div className="col-lg-1 cell-header">CADD</div>
          <div className="col-lg-1 cell-header">Inherit</div>
        </div>
        
        {(allVisible ? items : items.slice(0, MAX_VISIBLE)).map((item) => (
          <div key={item.snippetCode} className="row">
            <div className="col-4 d-block d-lg-none cell-header">SNP</div>
            <div className="col-8 col-lg-2 cell-content">{item.snippetCode}</div>

            <div className="col-4 d-block d-lg-none cell-header">Gene Name</div>
            <div className="col-8 col-lg-3 cell-panel"><b>{item.geneName}</b></div>

            <div className="col-4 d-block d-lg-none cell-header">Significance</div>
            <div className="col-8 col-lg-2 cell-content">{item.significants}</div>

            <div className="col-4 d-block d-lg-none cell-header">Variants</div>
            <div className="col-8 col-lg-2 cell-content">{item.variants}</div>

            <div className="col-4 d-block d-lg-none cell-header">Frequency</div>
            <div className="col-8 col-lg-1 cell-content"><b>{item.frequency}</b></div>

            <div className="col-4 d-block d-lg-none cell-header">CADD</div>
            <div className="col-8 col-lg-1 cell-content"><b>{item.cadd}</b></div>

            <div className="col-4 d-block d-lg-none cell-header">Inherit</div>
            <div className={classNames('col-8 col-lg-1', item.inheritance === 'Homo' ? 'cell-homo' : 'cell-content')}>
              {item.inheritance}
            </div>

            <div className="col-12 row-divider" />
          </div>
        ))}
      </div>

      {moreMax && <Button 
        appearance="default" 
        className="btn-gadget-2 mt-3" 
        onClick={() => setAllVisible(!allVisible)}>
        {allVisible ? 'Hide all' : 'View all'}
      </Button>}
    </div>
  );
};

export default Genetics;