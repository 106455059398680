import { useCallback, useRef } from 'react';
import { useAppState } from './useAppState';

export const getAsyncDefault = () => ({
  data: null,
  loading: false,
  loaded: false,
  error: null
});

export const useAsyncAppState = (storeKey, loadAsync) => {
  const [asyncData, setAsyncData] = useAppState(storeKey, getAsyncDefault());
  const dataRef = useRef(asyncData.data);

  dataRef.current = asyncData.data;

  const loadWrapperAsync = useCallback(async(args) => {
    setAsyncData({
      data: dataRef.current,
      loading: true,
      loaded: false,
      error: null,
    });

    try {
      const data = await loadAsync(args);
      setAsyncData({
        data: data || null,
        loading: false,
        loaded: true,
        error: null,
      });

      return data;
    }
    catch (ex) {
      console.log(ex);
      setAsyncData({
        data: null,
        loading: false,
        loaded: false,
        error: 'Something went wrong',
      });

      throw ex;
    }
  }, [loadAsync, setAsyncData]);

  return [asyncData, loadWrapperAsync];
};

