import React from 'react';
import 'leaflet/dist/leaflet.css';

import { Grid } from '@mui/material';

import Layout from '../layout/Layout';
import SymptomsTimeline from './SymptomsTimeline';
import '../../styles/timeline.css';

const SymptomsTimelinePage = () => {
  return (
    <Layout>
      <Grid container className="timeline-page">
        <Grid item className="timeline-page-header">
          <h3>Symptoms Timeline</h3>
        </Grid>
        <Grid item className="timeline-page-content">
          <SymptomsTimeline />
        </Grid>
      </Grid>
    </Layout>
  );
}

export default SymptomsTimelinePage;
