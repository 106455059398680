export const zscore_prob = {
        "-4.90": "0.00000",
        "-4.9": "0.00000",
        "-4.91": "0.00000",
        "-4.92": "0.00000",
        "-4.93": "0.00000",
        "-4.94": "0.00000",
        "-4.95": "0.00000",
        "-4.96": "0.00000",
        "-4.97": "0.00000",
        "-4.98": "0.00000",
        "-4.99": "0.00000",
        "-4.80": "0.00000",
        "-4.8": "0.00000",
        "-4.81": "0.00000",
        "-4.82": "0.00000",
        "-4.83": "0.00000",
        "-4.84": "0.00000",
        "-4.85": "0.00000",
        "-4.86": "0.00000",
        "-4.87": "0.00000",
        "-4.88": "0.00000",
        "-4.89": "0.00000",
        "-4.70": "0.00000",
        "-4.7": "0.00000",
        "-4.71": "0.00000",
        "-4.72": "0.00000",
        "-4.73": "0.00000",
        "-4.74": "0.00000",
        "-4.75": "0.00000",
        "-4.76": "0.00000",
        "-4.77": "0.00000",
        "-4.78": "0.00000",
        "-4.79": "0.00000",
        "-4.60": "0.00000",
        "-4.6": "0.00000",
        "-4.61": "0.00000",
        "-4.62": "0.00000",
        "-4.63": "0.00000",
        "-4.64": "0.00000",
        "-4.65": "0.00000",
        "-4.66": "0.00000",
        "-4.67": "0.00000",
        "-4.68": "0.00000",
        "-4.69": "0.00000",
        "-4.50": "0.00000",
        "-4.5": "0.00000",
        "-4.51": "0.00000",
        "-4.52": "0.00000",
        "-4.53": "0.00000",
        "-4.54": "0.00000",
        "-4.55": "0.00000",
        "-4.56": "0.00000",
        "-4.57": "0.00000",
        "-4.58": "0.00000",
        "-4.59": "0.00000",
        "-4.40": "0.00001",
        "-4.4": "0.00001",
        "-4.41": "0.00001",
        "-4.42": "0.00000",
        "-4.43": "0.00000",
        "-4.44": "0.00000",
        "-4.45": "0.00000",
        "-4.46": "0.00000",
        "-4.47": "0.00000",
        "-4.48": "0.00000",
        "-4.49": "0.00000",
        "-4.30": "0.00001",
        "-4.3": "0.00001",
        "-4.31": "0.00001",
        "-4.32": "0.00001",
        "-4.33": "0.00001",
        "-4.34": "0.00001",
        "-4.35": "0.00001",
        "-4.36": "0.00001",
        "-4.37": "0.00001",
        "-4.38": "0.00001",
        "-4.39": "0.00001",
        "-4.20": "0.00001",
        "-4.2": "0.00001",
        "-4.21": "0.00001",
        "-4.22": "0.00001",
        "-4.23": "0.00001",
        "-4.24": "0.00001",
        "-4.25": "0.00001",
        "-4.26": "0.00001",
        "-4.27": "0.00001",
        "-4.28": "0.00001",
        "-4.29": "0.00001",
        "-4.10": "0.00002",
        "-4.1": "0.00002",
        "-4.11": "0.00002",
        "-4.12": "0.00002",
        "-4.13": "0.00002",
        "-4.14": "0.00002",
        "-4.15": "0.00002",
        "-4.16": "0.00002",
        "-4.17": "0.00002",
        "-4.18": "0.00001",
        "-4.19": "0.00001",
        "-4.00": "0.00003",
        "-4.0": "0.00003",
        "-4": "0.00003",
        "-4.01": "0.00003",
        "-4.02": "0.00003",
        "-4.03": "0.00003",
        "-4.04": "0.00003",
        "-4.05": "0.00003",
        "-4.06": "0.00002",
        "-4.07": "0.00002",
        "-4.08": "0.00002",
        "-4.09": "0.00002",
        "-3.90": "0.00005",
        "-3.9": "0.00005",
        "-3.91": "0.00005",
        "-3.92": "0.00004",
        "-3.93": "0.00004",
        "-3.94": "0.00004",
        "-3.95": "0.00004",
        "-3.96": "0.00004",
        "-3.97": "0.00004",
        "-3.98": "0.00003",
        "-3.99": "0.00003",
        "-3.80": "0.00007",
        "-3.8": "0.00007",
        "-3.81": "0.00007",
        "-3.82": "0.00007",
        "-3.83": "0.00006",
        "-3.84": "0.00006",
        "-3.85": "0.00006",
        "-3.86": "0.00006",
        "-3.87": "0.00005",
        "-3.88": "0.00005",
        "-3.89": "0.00005",
        "-3.70": "0.00011",
        "-3.7": "0.00011",
        "-3.71": "0.00010",
        "-3.72": "0.00010",
        "-3.73": "0.00010",
        "-3.74": "0.00009",
        "-3.75": "0.00009",
        "-3.76": "0.00008",
        "-3.77": "0.00008",
        "-3.78": "0.00008",
        "-3.79": "0.00008",
        "-3.60": "0.00016",
        "-3.6": "0.00016",
        "-3.61": "0.00015",
        "-3.62": "0.00015",
        "-3.63": "0.00014",
        "-3.64": "0.00014",
        "-3.65": "0.00013",
        "-3.66": "0.00013",
        "-3.67": "0.00012",
        "-3.68": "0.00012",
        "-3.69": "0.00011",
        "-3.50": "0.00023",
        "-3.5": "0.00023",
        "-3.51": "0.00022",
        "-3.52": "0.00022",
        "-3.53": "0.00021",
        "-3.54": "0.00020",
        "-3.55": "0.00019",
        "-3.56": "0.00019",
        "-3.57": "0.00018",
        "-3.58": "0.00017",
        "-3.59": "0.00017",
        "-3.40": "0.00034",
        "-3.4": "0.00034",
        "-3.41": "0.00032",
        "-3.42": "0.00031",
        "-3.43": "0.00030",
        "-3.44": "0.00029",
        "-3.45": "0.00028",
        "-3.46": "0.00027",
        "-3.47": "0.00026",
        "-3.48": "0.00025",
        "-3.49": "0.00024",
        "-3.30": "0.00048",
        "-3.3": "0.00048",
        "-3.31": "0.00047",
        "-3.32": "0.00045",
        "-3.33": "0.00043",
        "-3.34": "0.00042",
        "-3.35": "0.00040",
        "-3.36": "0.00039",
        "-3.37": "0.00038",
        "-3.38": "0.00036",
        "-3.39": "0.00035",
        "-3.20": "0.00069",
        "-3.2": "0.00069",
        "-3.21": "0.00066",
        "-3.22": "0.00064",
        "-3.23": "0.00062",
        "-3.24": "0.00060",
        "-3.25": "0.00058",
        "-3.26": "0.00056",
        "-3.27": "0.00054",
        "-3.28": "0.00052",
        "-3.29": "0.00050",
        "-3.10": "0.00097",
        "-3.1": "0.00097",
        "-3.11": "0.00094",
        "-3.12": "0.00090",
        "-3.13": "0.00087",
        "-3.14": "0.00084",
        "-3.15": "0.00082",
        "-3.16": "0.00079",
        "-3.17": "0.00076",
        "-3.18": "0.00074",
        "-3.19": "0.00071",
        "-3.00": "0.00135",
        "-3.0": "0.00135",
        "-3": "0.00135",
        "-3.01": "0.00131",
        "-3.02": "0.00126",
        "-3.03": "0.00122",
        "-3.04": "0.00118",
        "-3.05": "0.00114",
        "-3.06": "0.00111",
        "-3.07": "0.00107",
        "-3.08": "0.00104",
        "-3.09": "0.00100",
        "-2.90": "0.00187",
        "-2.9": "0.00187",
        "-2.91": "0.00181",
        "-2.92": "0.00175",
        "-2.93": "0.00169",
        "-2.94": "0.00164",
        "-2.95": "0.00159",
        "-2.96": "0.00154",
        "-2.97": "0.00149",
        "-2.98": "0.00144",
        "-2.99": "0.00139",
        "-2.80": "0.00256",
        "-2.8": "0.00256",
        "-2.81": "0.00248",
        "-2.82": "0.00240",
        "-2.83": "0.00233",
        "-2.84": "0.00226",
        "-2.85": "0.00219",
        "-2.86": "0.00212",
        "-2.87": "0.00205",
        "-2.88": "0.00199",
        "-2.89": "0.00193",
        "-2.70": "0.00347",
        "-2.7": "0.00347",
        "-2.71": "0.00336",
        "-2.72": "0.00326",
        "-2.73": "0.00317",
        "-2.74": "0.00307",
        "-2.75": "0.00298",
        "-2.76": "0.00289",
        "-2.77": "0.00280",
        "-2.78": "0.00272",
        "-2.79": "0.00264",
        "-2.60": "0.00466",
        "-2.6": "0.00466",
        "-2.61": "0.00453",
        "-2.62": "0.00440",
        "-2.63": "0.00427",
        "-2.64": "0.00415",
        "-2.65": "0.00402",
        "-2.66": "0.00391",
        "-2.67": "0.00379",
        "-2.68": "0.00368",
        "-2.69": "0.00357",
        "-2.50": "0.00621",
        "-2.5": "0.00621",
        "-2.51": "0.00604",
        "-2.52": "0.00587",
        "-2.53": "0.00570",
        "-2.54": "0.00554",
        "-2.55": "0.00539",
        "-2.56": "0.00523",
        "-2.57": "0.00508",
        "-2.58": "0.00494",
        "-2.59": "0.00480",
        "-2.40": "0.00820",
        "-2.4": "0.00820",
        "-2.41": "0.00798",
        "-2.42": "0.00776",
        "-2.43": "0.00755",
        "-2.44": "0.00734",
        "-2.45": "0.00714",
        "-2.46": "0.00695",
        "-2.47": "0.00676",
        "-2.48": "0.00657",
        "-2.49": "0.00639",
        "-2.30": "0.01072",
        "-2.3": "0.01072",
        "-2.31": "0.01044",
        "-2.32": "0.01017",
        "-2.33": "0.00990",
        "-2.34": "0.00964",
        "-2.35": "0.00939",
        "-2.36": "0.00914",
        "-2.37": "0.00889",
        "-2.38": "0.00866",
        "-2.39": "0.00842",
        "-2.20": "0.01390",
        "-2.2": "0.01390",
        "-2.21": "0.01355",
        "-2.22": "0.01321",
        "-2.23": "0.01287",
        "-2.24": "0.01255",
        "-2.25": "0.01222",
        "-2.26": "0.01191",
        "-2.27": "0.01160",
        "-2.28": "0.01130",
        "-2.29": "0.01101",
        "-2.10": "0.01786",
        "-2.1": "0.01786",
        "-2.11": "0.01743",
        "-2.12": "0.01700",
        "-2.13": "0.01659",
        "-2.14": "0.01618",
        "-2.15": "0.01578",
        "-2.16": "0.01539",
        "-2.17": "0.01500",
        "-2.18": "0.01463",
        "-2.19": "0.01426",
        "-2.00": "0.02275",
        "-2.0": "0.02275",
        "-2": "0.02275",
        "-2.01": "0.02222",
        "-2.02": "0.02169",
        "-2.03": "0.02118",
        "-2.04": "0.02068",
        "-2.05": "0.02018",
        "-2.06": "0.01970",
        "-2.07": "0.01923",
        "-2.08": "0.01876",
        "-2.09": "0.01831",
        "-1.90": "0.02872",
        "-1.9": "0.02872",
        "-1.91": "0.02807",
        "-1.92": "0.02743",
        "-1.93": "0.02680",
        "-1.94": "0.02619",
        "-1.95": "0.02559",
        "-1.96": "0.02500",
        "-1.97": "0.02442",
        "-1.98": "0.02385",
        "-1.99": "0.02330",
        "-1.80": "0.03593",
        "-1.8": "0.03593",
        "-1.81": "0.03515",
        "-1.82": "0.03438",
        "-1.83": "0.03362",
        "-1.84": "0.03288",
        "-1.85": "0.03216",
        "-1.86": "0.03144",
        "-1.87": "0.03074",
        "-1.88": "0.03005",
        "-1.89": "0.02938",
        "-1.70": "0.04457",
        "-1.7": "0.04457",
        "-1.71": "0.04363",
        "-1.72": "0.04272",
        "-1.73": "0.04182",
        "-1.74": "0.04093",
        "-1.75": "0.04006",
        "-1.76": "0.03920",
        "-1.77": "0.03836",
        "-1.78": "0.03754",
        "-1.79": "0.03673",
        "-1.60": "0.05480",
        "-1.6": "0.05480",
        "-1.61": "0.05370",
        "-1.62": "0.05262",
        "-1.63": "0.05155",
        "-1.64": "0.05050",
        "-1.65": "0.04947",
        "-1.66": "0.04846",
        "-1.67": "0.04746",
        "-1.68": "0.04648",
        "-1.69": "0.04551",
        "-1.50": "0.06681",
        "-1.5": "0.06681",
        "-1.51": "0.06552",
        "-1.52": "0.06426",
        "-1.53": "0.06301",
        "-1.54": "0.06178",
        "-1.55": "0.06057",
        "-1.56": "0.05938",
        "-1.57": "0.05821",
        "-1.58": "0.05705",
        "-1.59": "0.05592",
        "-1.40": "0.08076",
        "-1.4": "0.08076",
        "-1.41": "0.07927",
        "-1.42": "0.07780",
        "-1.43": "0.07636",
        "-1.44": "0.07493",
        "-1.45": "0.07353",
        "-1.46": "0.07215",
        "-1.47": "0.07078",
        "-1.48": "0.06944",
        "-1.49": "0.06811",
        "-1.30": "0.09680",
        "-1.3": "0.09680",
        "-1.31": "0.09510",
        "-1.32": "0.09342",
        "-1.33": "0.09176",
        "-1.34": "0.09012",
        "-1.35": "0.08851",
        "-1.36": "0.08691",
        "-1.37": "0.08534",
        "-1.38": "0.08379",
        "-1.39": "0.08226",
        "-1.20": "0.11507",
        "-1.2": "0.11507",
        "-1.21": "0.11314",
        "-1.22": "0.11123",
        "-1.23": "0.10935",
        "-1.24": "0.10749",
        "-1.25": "0.10565",
        "-1.26": "0.10383",
        "-1.27": "0.10204",
        "-1.28": "0.10027",
        "-1.29": "0.09853",
        "-1.10": "0.13567",
        "-1.1": "0.13567",
        "-1.11": "0.13350",
        "-1.12": "0.13136",
        "-1.13": "0.12924",
        "-1.14": "0.12714",
        "-1.15": "0.12507",
        "-1.16": "0.12302",
        "-1.17": "0.12100",
        "-1.18": "0.11900",
        "-1.19": "0.11702",
        "-1.00": "0.15866",
        "-1.0": "0.15866",
        "-1": "0.15866",
        "-1.01": "0.15625",
        "-1.02": "0.15386",
        "-1.03": "0.15151",
        "-1.04": "0.14917",
        "-1.05": "0.14686",
        "-1.06": "0.14457",
        "-1.07": "0.14231",
        "-1.08": "0.14007",
        "-1.09": "0.13786",
        "-0.90": "0.18406",
        "-0.9": "0.18406",
        "-0.91": "0.18141",
        "-0.92": "0.17879",
        "-0.93": "0.17619",
        "-0.94": "0.17361",
        "-0.95": "0.17106",
        "-0.96": "0.16853",
        "-0.97": "0.16602",
        "-0.98": "0.16354",
        "-0.99": "0.16109",
        "-0.80": "0.21186",
        "-0.8": "0.21186",
        "-0.81": "0.20897",
        "-0.82": "0.20611",
        "-0.83": "0.20327",
        "-0.84": "0.20045",
        "-0.85": "0.19766",
        "-0.86": "0.19489",
        "-0.87": "0.19215",
        "-0.88": "0.18943",
        "-0.89": "0.18673",
        "-0.70": "0.24196",
        "-0.7": "0.24196",
        "-0.71": "0.23885",
        "-0.72": "0.23576",
        "-0.73": "0.23270",
        "-0.74": "0.22965",
        "-0.75": "0.22663",
        "-0.76": "0.22363",
        "-0.77": "0.22065",
        "-0.78": "0.21770",
        "-0.79": "0.21476",
        "-0.60": "0.27425",
        "-0.6": "0.27425",
        "-0.61": "0.27093",
        "-0.62": "0.26763",
        "-0.63": "0.26435",
        "-0.64": "0.26109",
        "-0.65": "0.25785",
        "-0.66": "0.25463",
        "-0.67": "0.25143",
        "-0.68": "0.24825",
        "-0.69": "0.24510",
        "-0.50": "0.30854",
        "-0.5": "0.30854",
        "-0.51": "0.30503",
        "-0.52": "0.30153",
        "-0.53": "0.29806",
        "-0.54": "0.29460",
        "-0.55": "0.29116",
        "-0.56": "0.28774",
        "-0.57": "0.28434",
        "-0.58": "0.28096",
        "-0.59": "0.27760",
        "-0.40": "0.34458",
        "-0.4": "0.34458",
        "-0.41": "0.34090",
        "-0.42": "0.33724",
        "-0.43": "0.33360",
        "-0.44": "0.32997",
        "-0.45": "0.32636",
        "-0.46": "0.32276",
        "-0.47": "0.31918",
        "-0.48": "0.31561",
        "-0.49": "0.31207",
        "-0.30": "0.38209",
        "-0.3": "0.38209",
        "-0.31": "0.37828",
        "-0.32": "0.37448",
        "-0.33": "0.37070",
        "-0.34": "0.36693",
        "-0.35": "0.36317",
        "-0.36": "0.35942",
        "-0.37": "0.35569",
        "-0.38": "0.35197",
        "-0.39": "0.34827",
        "-0.20": "0.42074",
        "-0.2": "0.42074",
        "-0.21": "0.41683",
        "-0.22": "0.41294",
        "-0.23": "0.40905",
        "-0.24": "0.40517",
        "-0.25": "0.40129",
        "-0.26": "0.39743",
        "-0.27": "0.39358",
        "-0.28": "0.38974",
        "-0.29": "0.38591",
        "-0.10": "0.46017",
        "-0.1": "0.46017",
        "-0.11": "0.45620",
        "-0.12": "0.45224",
        "-0.13": "0.44828",
        "-0.14": "0.44433",
        "-0.15": "0.44038",
        "-0.16": "0.43644",
        "-0.17": "0.43251",
        "-0.18": "0.42858",
        "-0.19": "0.42465",
        "-0.00": "0.50000",
        "-0.0": "0.50000",
        "-0": "0.50000",
        "-0.01": "0.49601",
        "-0.02": "0.49202",
        "-0.03": "0.48803",
        "-0.04": "0.48405",
        "-0.05": "0.48006",
        "-0.06": "0.47608",
        "-0.07": "0.47210",
        "-0.08": "0.46812",
        "-0.09": "0.46414",
        "0.00": "0.50000",
        "0.0": "0.50000",
        "0": "0.50000",
        "0.01": "0.50000",
        "0.02": "0.50000",
        "0.03": "0.50000",
        "0.04": "0.50000",
        "0.05": "0.50000",
        "0.06": "0.50000",
        "0.07": "0.50000",
        "0.08": "0.50000",
        "0.09": "0.50000",
        "0.10": "0.53983",
        "0.1": "0.53983",
        "0.11": "0.53983",
        "0.12": "0.53983",
        "0.13": "0.53983",
        "0.14": "0.53983",
        "0.15": "0.53983",
        "0.16": "0.53983",
        "0.17": "0.53983",
        "0.18": "0.53983",
        "0.19": "0.53983",
        "0.20": "0.57926",
        "0.2": "0.57926",
        "0.21": "0.57926",
        "0.22": "0.57926",
        "0.23": "0.57926",
        "0.24": "0.57926",
        "0.25": "0.57926",
        "0.26": "0.57926",
        "0.27": "0.57926",
        "0.28": "0.57926",
        "0.29": "0.57926",
        "0,30": "0.61791",
        "0,3": "0.61791",
        "0.31": "0.61791",
        "0.32": "0.61791",
        "0.33": "0.61791",
        "0.34": "0.61791",
        "0.35": "0.61791",
        "0.36": "0.61791",
        "0.37": "0.61791",
        "0.38": "0.61791",
        "0.39": "0.61791",
        "0.40": "0.65542",
        "0.4": "0.65542",
        "0.41": "0.65542",
        "0.42": "0.65542",
        "0.43": "0.65542",
        "0.44": "0.65542",
        "0.45": "0.65542",
        "0.46": "0.65542",
        "0.47": "0.65542",
        "0.48": "0.65542",
        "0.49": "0.65542",
        "0.50": "0.69146",
        "0.5": "0.69146",
        "0.51": "0.69146",
        "0.52": "0.69146",
        "0.53": "0.69146",
        "0.54": "0.69146",
        "0.55": "0.69146",
        "0.56": "0.69146",
        "0.57": "0.69146",
        "0.58": "0.69146",
        "0.59": "0.69146",
        "0.60": "0.72575",
        "0.6": "0.72575",
        "0.61": "0.72575",
        "0.62": "0.72575",
        "0.63": "0.72575",
        "0.64": "0.72575",
        "0.65": "0.72575",
        "0.66": "0.72575",
        "0.67": "0.72575",
        "0.68": "0.72575",
        "0.69": "0.72575",
        "0.70": "0.75804",
        "0.7": "0.75804",
        "0.71": "0.75804",
        "0.72": "0.75804",
        "0.73": "0.75804",
        "0.74": "0.75804",
        "0.75": "0.75804",
        "0.76": "0.75804",
        "0.77": "0.75804",
        "0.78": "0.75804",
        "0.79": "0.75804",
        "0.80": "0.78814",
        "0.8": "0.78814",
        "0.81": "0.78814",
        "0.82": "0.78814",
        "0.83": "0.78814",
        "0.84": "0.78814",
        "0.85": "0.78814",
        "0.86": "0.78814",
        "0.87": "0.78814",
        "0.88": "0.78814",
        "0.89": "0.78814",
        "0.90": "0.81594",
        "0.9": "0.81594",
        "0.91": "0.81594",
        "0.92": "0.81594",
        "0.93": "0.81594",
        "0.94": "0.81594",
        "0.95": "0.81594",
        "0.96": "0.81594",
        "0.97": "0.81594",
        "0.98": "0.81594",
        "0.99": "0.81594",
        "1.00": "0.84134",
        "1.0": "0.84134",
        "1": "0.84134",
        "1.01": "0.84134",
        "1.02": "0.84134",
        "1.03": "0.84134",
        "1.04": "0.84134",
        "1.05": "0.84134",
        "1.06": "0.84134",
        "1.07": "0.84134",
        "1.08": "0.84134",
        "1.09": "0.84134",
        "1.10": "0.86433",
        "1.1": "0.86433",
        "1.11": "0.86433",
        "1.12": "0.86433",
        "1.13": "0.86433",
        "1.14": "0.86433",
        "1.15": "0.86433",
        "1.16": "0.86433",
        "1.17": "0.86433",
        "1.18": "0.86433",
        "1.19": "0.86433",
        "1.20": "0.88493",
        "1.2": "0.88493",
        "1.21": "0.88493",
        "1.22": "0.88493",
        "1.23": "0.88493",
        "1.24": "0.88493",
        "1.25": "0.88493",
        "1.26": "0.88493",
        "1.27": "0.88493",
        "1.28": "0.88493",
        "1.29": "0.88493",
        "1.30": "0.90320",
        "1.3": "0.90320",
        "1.31": "0.90320",
        "1.32": "0.90320",
        "1.33": "0.90320",
        "1.34": "0.90320",
        "1.35": "0.90320",
        "1.36": "0.90320",
        "1.37": "0.90320",
        "1.38": "0.90320",
        "1.39": "0.90320",
        "1.40": "0.91924",
        "1.4": "0.91924",
        "1.41": "0.91924",
        "1.42": "0.91924",
        "1.43": "0.91924",
        "1.44": "0.91924",
        "1.45": "0.91924",
        "1.46": "0.91924",
        "1.47": "0.91924",
        "1.48": "0.91924",
        "1.49": "0.91924",
        "1.50": "0.93319",
        "1.5": "0.93319",
        "1.51": "0.93319",
        "1.52": "0.93319",
        "1.53": "0.93319",
        "1.54": "0.93319",
        "1.55": "0.93319",
        "1.56": "0.93319",
        "1.57": "0.93319",
        "1.58": "0.93319",
        "1.59": "0.93319",
        "1.60": "0.94520",
        "1.6": "0.94520",
        "1.61": "0.94520",
        "1.62": "0.94520",
        "1.63": "0.94520",
        "1.64": "0.94520",
        "1.65": "0.94520",
        "1.66": "0.94520",
        "1.67": "0.94520",
        "1.68": "0.94520",
        "1.69": "0.94520",
        "1.70": "0.95543",
        "1.7": "0.95543",
        "1.71": "0.95543",
        "1.72": "0.95543",
        "1.73": "0.95543",
        "1.74": "0.95543",
        "1.75": "0.95543",
        "1.76": "0.95543",
        "1.77": "0.95543",
        "1.78": "0.95543",
        "1.79": "0.95543",
        "1.80": "0.96407",
        "1.8": "0.96407",
        "1.81": "0.96407",
        "1.82": "0.96407",
        "1.83": "0.96407",
        "1.84": "0.96407",
        "1.85": "0.96407",
        "1.86": "0.96407",
        "1.87": "0.96407",
        "1.88": "0.96407",
        "1.89": "0.96407",
        "1.90": "0.97128",
        "1.9": "0.97128",
        "1.91": "0.97128",
        "1.92": "0.97128",
        "1.93": "0.97128",
        "1.94": "0.97128",
        "1.95": "0.97128",
        "1.96": "0.97128",
        "1.97": "0.97128",
        "1.98": "0.97128",
        "1.99": "0.97128",
        "2.00": "0.97725",
        "2.0": "0.97725",
        "2": "0.97725",
        "2.01": "0.97725",
        "2.02": "0.97725",
        "2.03": "0.97725",
        "2.04": "0.97725",
        "2.05": "0.97725",
        "2.06": "0.97725",
        "2.07": "0.97725",
        "2.08": "0.97725",
        "2.09": "0.97725",
        "2.10": "0.98214",
        "2.1": "0.98214",
        "2.11": "0.98214",
        "2.12": "0.98214",
        "2.13": "0.98214",
        "2.14": "0.98214",
        "2.15": "0.98214",
        "2.16": "0.98214",
        "2.17": "0.98214",
        "2.18": "0.98214",
        "2.19": "0.98214",
        "2.20": "0.98610",
        "2.2": "0.98610",
        "2.21": "0.98610",
        "2.22": "0.98610",
        "2.23": "0.98610",
        "2.24": "0.98610",
        "2.25": "0.98610",
        "2.26": "0.98610",
        "2.27": "0.98610",
        "2.28": "0.98610",
        "2.29": "0.98610",
        "2.30": "0.98928",
        "2.3": "0.98928",
        "2.31": "0.98928",
        "2.32": "0.98928",
        "2.33": "0.98928",
        "2.34": "0.98928",
        "2.35": "0.98928",
        "2.36": "0.98928",
        "2.37": "0.98928",
        "2.38": "0.98928",
        "2.39": "0.98928",
        "2.40": "0.99180",
        "2.4": "0.99180",
        "2.41": "0.99180",
        "2.42": "0.99180",
        "2.43": "0.99180",
        "2.44": "0.99180",
        "2.45": "0.99180",
        "2.46": "0.99180",
        "2.47": "0.99180",
        "2.48": "0.99180",
        "2.49": "0.99180",
        "2.50": "0.99379",
        "2.5": "0.99379",
        "2.51": "0.99379",
        "2.52": "0.99379",
        "2.53": "0.99379",
        "2.54": "0.99379",
        "2.55": "0.99379",
        "2.56": "0.99379",
        "2.57": "0.99379",
        "2.58": "0.99379",
        "2.59": "0.99379",
        "2.60": "0.99534",
        "2.6": "0.99534",
        "2.61": "0.99534",
        "2.62": "0.99534",
        "2.63": "0.99534",
        "2.64": "0.99534",
        "2.65": "0.99534",
        "2.66": "0.99534",
        "2.67": "0.99534",
        "2.68": "0.99534",
        "2.69": "0.99534",
        "2.70": "0.99653",
        "2.7": "0.99653",
        "2.71": "0.99653",
        "2.72": "0.99653",
        "2.73": "0.99653",
        "2.74": "0.99653",
        "2.75": "0.99653",
        "2.76": "0.99653",
        "2.77": "0.99653",
        "2.78": "0.99653",
        "2.79": "0.99653",
        "2.80": "0.99744",
        "2.8": "0.99744",
        "2.81": "0.99744",
        "2.82": "0.99744",
        "2.83": "0.99744",
        "2.84": "0.99744",
        "2.85": "0.99744",
        "2.86": "0.99744",
        "2.87": "0.99744",
        "2.88": "0.99744",
        "2.89": "0.99744",
        "2.90": "0.99813",
        "2.9": "0.99813",
        "2.91": "0.99813",
        "2.92": "0.99813",
        "2.93": "0.99813",
        "2.94": "0.99813",
        "2.95": "0.99813",
        "2.96": "0.99813",
        "2.97": "0.99813",
        "2.98": "0.99813",
        "2.99": "0.99813",
        "3.00": "0.99865",
        "3.0": "0.99865",
        "3": "0.99865",
        "3.01": "0.99865",
        "3.02": "0.99865",
        "3.03": "0.99865",
        "3.04": "0.99865",
        "3.05": "0.99865",
        "3.06": "0.99865",
        "3.07": "0.99865",
        "3.08": "0.99865",
        "3.09": "0.99865",
        "3.10": "0.99903",
        "3.1": "0.99903",
        "3.11": "0.99903",
        "3.12": "0.99903",
        "3.13": "0.99903",
        "3.14": "0.99903",
        "3.15": "0.99903",
        "3.16": "0.99903",
        "3.17": "0.99903",
        "3.18": "0.99903",
        "3.19": "0.99903",
        "3.20": "0.99931",
        "3.2": "0.99931",
        "3.21": "0.99931",
        "3.22": "0.99931",
        "3.23": "0.99931",
        "3.24": "0.99931",
        "3.25": "0.99931",
        "3.26": "0.99931",
        "3.27": "0.99931",
        "3.28": "0.99931",
        "3.29": "0.99931",
        "3.30": "0.99952",
        "3.3": "0.99952",
        "3.31": "0.99952",
        "3.32": "0.99952",
        "3.33": "0.99952",
        "3.34": "0.99952",
        "3.35": "0.99952",
        "3.36": "0.99952",
        "3.37": "0.99952",
        "3.38": "0.99952",
        "3.39": "0.99952",
        "3.40": "0.99966",
        "3.4": "0.99966",
        "3.41": "0.99966",
        "3.42": "0.99966",
        "3.43": "0.99966",
        "3.44": "0.99966",
        "3.45": "0.99966",
        "3.46": "0.99966",
        "3.47": "0.99966",
        "3.48": "0.99966",
        "3.49": "0.99966",
        "3.50": "0.99977",
        "3.5": "0.99977",
        "3.51": "0.99977",
        "3.52": "0.99977",
        "3.53": "0.99977",
        "3.54": "0.99977",
        "3.55": "0.99977",
        "3.56": "0.99977",
        "3.57": "0.99977",
        "3.58": "0.99977",
        "3.59": "0.99977",
        "3.60": "0.99984",
        "3.6": "0.99984",
        "3.61": "0.99984",
        "3.62": "0.99984",
        "3.63": "0.99984",
        "3.64": "0.99984",
        "3.65": "0.99984",
        "3.66": "0.99984",
        "3.67": "0.99984",
        "3.68": "0.99984",
        "3.69": "0.99984",
        "3.70": "0.99989",
        "3.7": "0.99989",
        "3.71": "0.99989",
        "3.72": "0.99989",
        "3.73": "0.99989",
        "3.74": "0.99989",
        "3.75": "0.99989",
        "3.76": "0.99989",
        "3.77": "0.99989",
        "3.78": "0.99989",
        "3.79": "0.99989",
        "3.80": "0.99993",
        "3.8": "0.99993",
        "3.81": "0.99993",
        "3.82": "0.99993",
        "3.83": "0.99993",
        "3.84": "0.99993",
        "3.85": "0.99993",
        "3.86": "0.99993",
        "3.87": "0.99993",
        "3.88": "0.99993",
        "3.89": "0.99993",
        "3.90": "0.99995",
        "3.9": "0.99995",
        "3.91": "0.99995",
        "3.92": "0.99995",
        "3.93": "0.99995",
        "3.94": "0.99995",
        "3.95": "0.99995",
        "3.96": "0.99995",
        "3.97": "0.99995",
        "3.98": "0.99995",
        "3.99": "0.99995",
        "4.00": "0.99997",
        "4.0": "0.99997",
        "4": "0.99997",
        "4.01": "0.99997",
        "4.02": "0.99997",
        "4.03": "0.99997",
        "4.04": "0.99997",
        "4.05": "0.99997",
        "4.06": "0.99997",
        "4.07": "0.99997",
        "4.08": "0.99997",
        "4.09": "0.99997",
        "4.10": "0.99998",
        "4.1": "0.99998",
        "4.11": "0.99998",
        "4.12": "0.99998",
        "4.13": "0.99998",
        "4.14": "0.99998",
        "4.15": "0.99998",
        "4.16": "0.99998",
        "4.17": "0.99998",
        "4.18": "0.99998",
        "4.19": "0.99998",
        "4.20": "0.99999",
        "4.2": "0.99999",
        "4.21": "0.99999",
        "4.22": "0.99999",
        "4.23": "0.99999",
        "4.24": "0.99999",
        "4.25": "0.99999",
        "4.26": "0.99999",
        "4.27": "0.99999",
        "4.28": "0.99999",
        "4.29": "0.99999",
        "4.30": "0.99999",
        "4.3": "0.99999",
        "4.31": "0.99999",
        "4.32": "0.99999",
        "4.33": "0.99999",
        "4.34": "0.99999",
        "4.35": "0.99999",
        "4.36": "0.99999",
        "4.37": "0.99999",
        "4.38": "0.99999",
        "4.39": "0.99999",
        "4.40": "0.99999",
        "4.4": "0.99999",
        "4.41": "0.99999",
        "4.42": "0.99999",
        "4.43": "0.99999",
        "4.44": "0.99999",
        "4.45": "0.99999",
        "4.46": "0.99999",
        "4.47": "0.99999",
        "4.48": "0.99999",
        "4.49": "0.99999",
        "4.50": "1.00000",
        "4.5": "1.00000",
        "4.51": "1.00000",
        "4.52": "1.00000",
        "4.53": "1.00000",
        "4.54": "1.00000",
        "4.55": "1.00000",
        "4.56": "1.00000",
        "4.57": "1.00000",
        "4.58": "1.00000",
        "4.59": "1.00000",
        "4.60": "1.00000",
        "4.6": "1.00000",
        "4.61": "1.00000",
        "4.62": "1.00000",
        "4.63": "1.00000",
        "4.64": "1.00000",
        "4.65": "1.00000",
        "4.66": "1.00000",
        "4.67": "1.00000",
        "4.68": "1.00000",
        "4.69": "1.00000",
        "4.70": "1.00000",
        "4.7": "1.00000",
        "4.71": "1.00000",
        "4.72": "1.00000",
        "4.73": "1.00000",
        "4.74": "1.00000",
        "4.75": "1.00000",
        "4.76": "1.00000",
        "4.77": "1.00000",
        "4.78": "1.00000",
        "4.79": "1.00000",
        "4.80": "1.00000",
        "4.8": "1.00000",
        "4.81": "1.00000",
        "4.82": "1.00000",
        "4.83": "1.00000",
        "4.84": "1.00000",
        "4.85": "1.00000",
        "4.86": "1.00000",
        "4.87": "1.00000",
        "4.88": "1.00000",
        "4.89": "1.00000",
        "4.90": "1.00000",
        "4.9": "1.00000",
        "4.91": "1.00000",
        "4.92": "1.00000",
        "4.93": "1.00000",
        "4.94": "1.00000",
        "4.95": "1.00000",
        "4.96": "1.00000",
        "4.97": "1.00000",
        "4.98": "1.00000",
        "4.99": "1.00000"
    }

