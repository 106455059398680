import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Grid, Paper, Divider, Button, ButtonGroup } from '@mui/material';

import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ViewListIcon from '@mui/icons-material/ViewList';
import QuizIcon from '@mui/icons-material/Quiz';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid';

import { STORE } from '../../store';
import { saveSymptom, loadSymptoms } from '../../services/symptom';
import { getCapability } from '../../services/session';
import { useAsyncAppState, getAsyncDefault } from '../../hooks/useAsyncAppState';
import { useAppState } from '../../hooks/useAppState';
import { useDialog } from '../../hooks/useDialog';
import Layout from '../layout/Layout';
import LoaderWrapper from '../common/LoaderWrapper';
import './home.css';

const Home = () => {
  const navigate = useNavigate();
  const [user] = useAppState(STORE.common.user, getAsyncDefault());
  const [profile] = useAppState(STORE.common.profile, getAsyncDefault());
  const [openSymptomDialog] = useDialog(STORE.areas.symptom.dialog);

  const [symptom, saveSymptomAsync] = useAsyncAppState(
    STORE.areas.symptom.save,
    saveSymptom
  );

  const [symptoms, loadSymptomsAsync] = useAsyncAppState(
    STORE.common.symptoms,
    loadSymptoms
  );

  const onLogSymptom = useCallback(async () => {
    const symptom = await openSymptomDialog({ symptomId: null, symptomDate: null });
    if (symptom) {
      await saveSymptomAsync(symptom);
      await loadSymptomsAsync();
    }
  }, [openSymptomDialog, saveSymptomAsync, loadSymptomsAsync]);

  return (
    <LoaderWrapper items={[symptom, symptoms]} show>
      <Layout noPadding>
        <Grid container className="nurse">
          <Grid item xs={0} md={3} lg={5} className="h-100">
          </Grid>
          <Grid item xs={12} md={9} lg={7} className="h-100">
            <Grid container className="home h-100">
              <Grid item xs={12} className="home-header">
                <Paper className="home-paper" elevation={0} variant="outlined">
                  <h3 className="home-paper-nested">
                      How can I help you today
                      {profile.data && profile.data.fullName ? (', ' + profile.data.fullName) : null}?
                  </h3>
                </Paper>
              </Grid>
              <Grid item xs={12} className="home-content">
                <Paper className="home-paper h-100" elevation={0} variant="outlined">
                  <Grid container className="home-paper-nested">
                    <Grid item xs={12} sm={6}>
                      <ButtonGroup orientation="vertical" fullWidth className="home-menu">
                        <Button variant="contained" onClick={onLogSymptom}>
                          <HistoryEduIcon />
                          Log a Symptom
                        </Button>
                        <Button variant="contained" onClick={() => navigate('/survey-category')}>
                          <QuizIcon />
                          Take a Survey
                        </Button>
                        <Button variant="contained" onClick={() => navigate('/nutrients')}>
                          <DinnerDiningIcon />
                          Track your nutrition
                        </Button>
                        <Button variant="contained" onClick={() => navigate('/medicines')}>
                          <MedicationLiquidIcon />
                          Track your medicines
                        </Button>
                        <Button variant="contained" onClick={() => navigate('/nutritions-averages')}>
                          <ViewListIcon />
                          Nutrition Averages
                        </Button>
                        <Button variant="contained" onClick={() => navigate('/survey-result')}>
                          <BubbleChartIcon />
                          View Survey Result
                        </Button>
                        <Button variant="contained" onClick={() => navigate('/construction')}>
                          <ViewListIcon />
                          View my Health History
                        </Button>
                        <Button variant="contained" onClick={() => navigate('/construction')}>
                          <BarChartIcon />
                          View Test Results
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                  <Divider />

                  {getCapability(user, 'useUpload') && (
                    <Grid container className="home-paper-nested">
                      <Grid item xs={12} sm={6}>
                        <ButtonGroup orientation="vertical" fullWidth className="home-menu">
                          <Button variant="contained" onClick={() => navigate('/document-upload')}>
                            <DriveFolderUploadIcon />
                            Upload Documents
                          </Button>
                        </ButtonGroup>
                      </Grid>
                    </Grid>)}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    </LoaderWrapper>
  );
}

export default Home;
