import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  Button,
  Tabs,
  Tab,
}
from '@mui/material';

import { loadBranded, loadSupplement, loadCommon, loadFavorite } from '../../services/nutrient';

import { STORE } from '../../store';
import { useDialog } from '../../hooks/useDialog';

import { FOOD_TYPE, calcWeight, calcEnergy } from './nutrientsData';
import LoaderWrapper from '../common/LoaderWrapper';
import HistoryFetchForm from './HistoryFetchForm';
import HistoryResultsList from './HistoryResultsList';
import FavoriteFetchForm from './FavoriteFetchForm';
import FavoriteResultsList from './FavoriteResultsList';
import CommonFetchForm from './CommonFetchForm';
import CommonResultsList from './CommonResultsList';
import BrandedFetchForm from './BrandedFetchForm';
import BrandedResultsList from './BrandedResultsList';
import FoodDetailsDialog from './FoodDetailsDialog';

const FoodSelectDialog = (props) => {
  const {
    foodTime, 
    visible,
    handleClose 
  } = props;

  const [
    openFoodDetailsDialog, 
    closeFoodDetailsDialog, 
    foodDetailsDialogData
  ] = useDialog(STORE.areas.nutrient.dialogDetails);

  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [history, setHistory] = useState(null);

  const onTabSelect = useCallback((e, newTabIndex) => {
    setTabIndex(newTabIndex);
    setItems([]);
  }, []);

  const onSearchLoaded = useCallback((items) => {
    setItems(items);
  }, [setItems]);

  const onHistoryLoaded = useCallback((history) => {
    setHistory(history);
  }, [setHistory]);

  const onSelect = useCallback(async(_id, foodType, load) => {
    setLoading(true);
    try {
      const foodData = await load(_id, foodType);
      setLoading(false);

      const result = await openFoodDetailsDialog({ foodData, foodType, foodTime });
      if (result) {
        handleClose(result);
      }
    }
    catch (ex) {
      setLoading(false);
    }
  }, [foodTime, setLoading, handleClose, openFoodDetailsDialog]);

  const onBrandedSelect = useCallback(async (foodId) => {
    await onSelect(foodId, FOOD_TYPE.BRANDED, loadBranded);  
  }, [onSelect]);

  const onSupplementSelect = useCallback(async (foodId) => {
    await onSelect(foodId, FOOD_TYPE.SUPPLEMENT, loadSupplement);  
  }, [onSelect]);

  const onCommonSelect = useCallback(async (foodId) => {
    await onSelect(foodId, FOOD_TYPE.COMMON, loadCommon);
  }, [onSelect]);

  const onFavoriteSelect = useCallback(async (foodId, foodType) => {
    await onSelect(foodId, foodType, loadFavorite);
  }, [onSelect]);

  const onHistorySelect = useCallback(async (foodItem) => {
    let load = null;
    if (foodItem.foodType === FOOD_TYPE.BRANDED) {
      load = loadBranded;
    }
    else if (foodItem.foodType === FOOD_TYPE.COMMON) {
      load = loadCommon;
    }
    else if (foodItem.foodType === FOOD_TYPE.SUPPLEMENT) {
      load = loadSupplement;
    }

    setLoading(true);
    try {
      const foodData = await load(foodItem.foodId, foodItem.foodType);
      setLoading(false);

      const portion = foodData.portions && foodItem.portionId ?
        foodData.portions.find((portion) => String(portion._id) === String(foodItem.portionId)) 
        : null;

      const weight = calcWeight(
        foodData, 
        foodItem.foodType, 
        foodItem.portionId, 
        foodItem.quantity);

      const result = {
        foodData,
        foodType: foodItem.foodType,
        foodTime,
        portion,
        quantity: foodItem.quantity,
        weight,
        energy: calcEnergy(foodData, weight)
      };

      handleClose(result);
    }
    catch (ex) {
      setLoading(false);
    }
  }, [foodTime, handleClose, setLoading]);

  return (
    <>
      <Dialog open={visible} fullWidth maxWidth="md" 
        keepMounted onClose={() => handleClose(null)}>
        <DialogTitle>
          Add Food to Diary ({foodTime})
          <Divider />
        </DialogTitle>
        
        <DialogContent>
          <LoaderWrapper loading={loading} show>
            {tabIndex === 0 && <CommonFetchForm onItemsLoaded={onSearchLoaded} />}
            {tabIndex === 1 && <BrandedFetchForm onItemsLoaded={onSearchLoaded} isSupplement={false} />}
            {tabIndex === 2 && <BrandedFetchForm onItemsLoaded={onSearchLoaded} isSupplement />}
            {tabIndex === 3 && <FavoriteFetchForm onItemsLoaded={onSearchLoaded} />}
            {tabIndex === 4 && <HistoryFetchForm onItemsLoaded={onHistoryLoaded} />}

            <Tabs value={tabIndex} 
              onChange={onTabSelect} 
              variant="scrollable"
              allowScrollButtonsMobile
              className="pb-1">
              <Tab label="Common"></Tab>
              <Tab label="Brands"></Tab>
              <Tab label="Supplement"></Tab>
              <Tab label="Favorites"></Tab>
              <Tab label="History"></Tab>
            </Tabs>

            {tabIndex === 0 && <CommonResultsList items={items} onSelect={onCommonSelect} />}
            {tabIndex === 1 && <BrandedResultsList items={items} onSelect={onBrandedSelect} />}
            {tabIndex === 2 && <BrandedResultsList items={items} onSelect={onSupplementSelect} />}
            {tabIndex === 3 && <FavoriteResultsList items={items} onSelect={onFavoriteSelect} />}
            {tabIndex === 4 && <HistoryResultsList items={history} onSelect={onHistorySelect} />}
          </LoaderWrapper>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={() => handleClose(null)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <FoodDetailsDialog 
        handleClose={closeFoodDetailsDialog}
        foodData={foodDetailsDialogData.data?.foodData}
        foodType={foodDetailsDialogData.data?.foodType}
        foodTime={foodDetailsDialogData.data?.foodTime}
        portionId={foodDetailsDialogData.data?.portionId}
        quantity={foodDetailsDialogData.data?.quantity}
        visible={foodDetailsDialogData.visible} />
    </>
  );
};

export default FoodSelectDialog;