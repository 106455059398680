import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { IconButton } from '@mui/material';

import { styled } from '@mui/material/styles';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

export const ExpandMore = styled((props) => {
  const { expanded, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expanded }) => ({
  transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const DiaryDay = ((props) => {
  const { 
    diaryDays = [], 
    outsideCurrentMonth,
    day,
    ...other 
  } = props;

  const formatDate = moment(props.day).format('YYYY-MM-DD');
  const isSelected = !props.outsideCurrentMonth && diaryDays.indexOf(formatDate) >= 0;
  return (
    <PickersDay 
      {...other} 
      className={classNames({ 'diary-selected': isSelected })}
      outsideCurrentMonth={outsideCurrentMonth} 
      day={day} />
  );
});