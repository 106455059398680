export const validateString = (value, message, regex) => {
  if (!value) {
    return message;
  }

  if (regex && !regex.test(value)) {
    return message;
  }

  return value.trim() === '' ? message : null;
};

export const validateNumber = (value, message, regex) => {
  if (!value) {
    return message;
  }

  const numberRegex = regex || /^[0-9]+$/;
  const stringValue = String(value);

  if (stringValue.trim() === '' || !numberRegex.test(stringValue)) {
    return message;
  }

  return null;
};
