import React from 'react';

import '../../css/index.scss';
import logo from '../../assets/img/albright-logo.svg';

const Layout = (props) => {
  const { children, className } = props;
  return (
    <div className={className}>
      <nav className="navbar">
        <div className="container">
          <a className="navbar-brand" href="/home">
            <img src={logo} alt="AlBright"></img>
          </a>
        </div>
      </nav>
      <main>
        <div className="container py-5">
          {children}
        </div>  
      </main>
    </div>
  );
}

export default Layout;
