import React from 'react';

import {
  Box,
} from '@mui/material';

import Layout from '../layout/Layout';
import { useCapability } from '../../hooks/useCapability';
import { useResearcher } from '../../hooks/useRole';
import ProfileDocsResearcher from './ProfileDocsResearcher';
import ProfileDocsPatient from './ProfileDocsPatient';
import './upload.css';

const ProfileDocs = () => {
  useCapability('useUpload');
  const isExtended = useResearcher();
  
  return (
    <Layout>
      <Box className="vertical-flex h-100">
        <h3>
          Profile Documents
        </h3>
        {isExtended ? <ProfileDocsResearcher /> : <ProfileDocsPatient />}
      </Box>
    </Layout>
  );
}

export default ProfileDocs;
