import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  Button,
  Tabs,
  Tab
}
from '@mui/material';

import { findMedicines, loadCustomMedicines, loadHistoryMedicines } from '../../services/medicines';

import { STORE } from '../../store';
import { useDialog } from '../../hooks/useDialog';

import LoaderWrapper from '../common/LoaderWrapper';
import MedicineDetailsDialog from './MedicineDetailsDialog';
import MedicinesAutocomplete from './MedicinesAutocomplete';
import ResultsList from './ResultsList';
import CustomForm from './CustomForm';
import ResultsCustomList from './ResultsCustomList';
import { MED_TYPE } from './medicinesData';
import ResultsHistoryList from './ResultsHistoryList';

const MedicinesSelectDialog = (props) => {
  const {
    visible,
    handleClose 
  } = props;

  const [
    openMedDetailsDialog, 
    closeMedDetailsDialog, 
    medDetailsDialogData
  ] = useDialog(STORE.areas.medicines.dialogDetails);

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [customItems, setCustomItems] = useState([]);
  const [historyItems, setHistoryItems] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const onSearchRequested = useCallback(async (mask) => {
    if (mask?.length) {
      try {
        setLoading(true);
        const loadedItems = await findMedicines(mask);
        setItems(loadedItems || []);
      }
      finally {
        setLoading(false);
      }
    }
  }, [setItems, setLoading]);

  const onCustomRequested = useCallback(async () => {
    try {
      setLoading(true);
      const loadedItems = await loadCustomMedicines();
      setCustomItems(loadedItems || []);
    }
    finally {
      setLoading(false);
    }  
  }, [setCustomItems, setLoading]);

  const onHistoryRequested = useCallback(async () => {
    try {
      setLoading(true);
      const loadedItems = await loadHistoryMedicines();
      setHistoryItems(loadedItems || []);
    }
    finally {
      setLoading(false);
    }  
  }, [setHistoryItems, setLoading]);

  const onMedicineSelect = useCallback(async (medData, medType) => {
    const result = await openMedDetailsDialog({ 
      medType,
      medData
    });

    if (result) {
      handleClose(result);
    }
  }, [handleClose, openMedDetailsDialog]);

  const onTabSelect = useCallback((e, newTabIndex) => {
    setTabIndex(newTabIndex);
  }, [setTabIndex]);

  return (
    <>
      <Dialog open={visible} fullWidth maxWidth="md" 
        keepMounted onClose={() => handleClose(null)}>
        <DialogTitle>
          Search Medicine
          <Divider />
        </DialogTitle>
        
        <DialogContent>
          <LoaderWrapper loading={loading} show>
            <Tabs value={tabIndex} 
              onChange={onTabSelect} 
              variant="scrollable"
              allowScrollButtonsMobile
              className="pb-1">
              <Tab label="Search"></Tab>
              <Tab label="Add Custom"></Tab>
              <Tab label="History"></Tab>
            </Tabs>

            {tabIndex === 0 && (
              <>
                <MedicinesAutocomplete onLoadRequest={onSearchRequested} />
                <ResultsList 
                  items={items} 
                  onSelect={(medData) => onMedicineSelect(medData, MED_TYPE.DAILYMED)} />
              </>
            )}

            {tabIndex === 1 && (
              <>
                <CustomForm 
                  items={customItems}
                  onRequested={onCustomRequested}
                  onEntered={(medData) => onMedicineSelect(medData, MED_TYPE.CUSTOM)} />

                <ResultsCustomList 
                  items={customItems}
                  onSelect={(medData) => onMedicineSelect(medData, MED_TYPE.CUSTOM)} />
              </>
            )}

            {tabIndex === 2 && (
              <ResultsHistoryList
                items={historyItems}
                onRequested={onHistoryRequested}
                onSelect={(medData, medType) => onMedicineSelect(medData, medType)} />
            )}

          </LoaderWrapper>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={() => handleClose(null)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <MedicineDetailsDialog 
        handleClose={closeMedDetailsDialog}
        medType={medDetailsDialogData.data?.medType}
        medData={medDetailsDialogData.data?.medData}
        quantity={medDetailsDialogData.data?.quantity}
        visible={medDetailsDialogData.visible} />
    </>
  );
};

export default MedicinesSelectDialog;