import { useState, useMemo, useCallback } from 'react';
import moment from 'moment';

import LoaderWrapper from '../../../components/common/LoaderWrapper';
import { STORE } from '../../../store';
import { loadSymptomsData } from '../../../services/medReport';
import { useAsyncAppState  } from '../../../hooks/useAsyncAppState';
import { getRandomColor } from '../../../components/genetics/GeneChartDialog';
import { defineRange, mapTimeUnit } from './reportData';

import SymptomTiles from './SymptomTiles';
import SymptomChart from './SymptomChart';

const Symptoms = ({ data }) => {
  const { patient, symptoms } = data;
  const [options, categories] = useMemo(() => {
    const { options, ...categories } = symptoms;
    return [options, Object.keys(categories)];
  }, [symptoms]);

  const [symptomsData, loadSymptomsDataAsync] = useAsyncAppState(
    STORE.areas.medReport.symptoms,
    loadSymptomsData
  );

  const [chartOptions, setChartOptions] = useState(options);
  const [selected, setSelected] = useState(categories.slice(0, 5));

  const colors = useMemo(() => {
    const result = {};
    categories.forEach((category) => (result[category] = getRandomColor(1, 200, 100)))
    return result;
  }, [categories]);

  const onSelect = useCallback((category, currentSelected) => {
    const index = currentSelected.indexOf(category);
    const newSelected = [...currentSelected];
    if (index >= 0) {
      newSelected.splice(index, 1);
    }
    else {
      newSelected.push(category);
    }
    setSelected(newSelected);
  }, [setSelected]);

  const applyNewOptions = useCallback(async (newOptions) => {
    setChartOptions(newOptions);
    await loadSymptomsDataAsync({
      patientEmail: patient.userEmail, 
      options: newOptions
    });
  }, [patient, setChartOptions, loadSymptomsDataAsync]);

  const onSelectRangeType = useCallback((rangeType, prevOptions) => {
    const newOptions = { ...prevOptions, rangeType };
    defineRange(newOptions);
    applyNewOptions(newOptions)
  }, [applyNewOptions]);

  const onMoveRange = useCallback((prevOptions, step) => {
    const timeUnit = mapTimeUnit(prevOptions.rangeType, false);
    applyNewOptions({ 
      ...prevOptions, 
      from: moment(prevOptions.from).add(step, timeUnit).toDate(),
      to: moment(prevOptions.to).endOf(timeUnit).add(step, timeUnit).toDate(),
    });
  }, [applyNewOptions]);

  const onChangeDaily = useCallback((prevOptions, date) => {
    applyNewOptions({ 
      ...prevOptions, 
      from: moment(date).toDate(),
      to: moment(date).endOf('day').toDate(),
    });
  }, [applyNewOptions]);

  return (
    <LoaderWrapper items={[symptomsData]} show>
      <h2 className="title-section">
        Symptoms
      </h2>

      <div className="row">
        <div className="col-lg-4">
          <h3>
            Overview
          </h3>
          <p>
            Overview symptoms from patient analysis that outlines their basic 
            problems they`re facing. This can be as detailed as necessary.
          </p>

          <h3 className="primary">
            {patient.fullName}'s symptoms
            <SymptomTiles 
              categories={categories} 
              selected={selected} 
              onSelect={onSelect} 
              colors={colors} />
          </h3>
        </div>

        <div className="col-lg-8">
          <h3>
            Symptom Tracker
          </h3>

          <SymptomChart 
            selected={selected} 
            symptoms={symptomsData.data || symptoms} 
            options={chartOptions}
            colors={colors}
            onMoveLeft={() => onMoveRange(chartOptions, -1)}
            onMoveRight={() => onMoveRange(chartOptions, 1)}
            onChangeDaily={(date) => onChangeDaily(chartOptions, date)}
            onSelectRangeType={(rangeType) => onSelectRangeType(rangeType, chartOptions)}
          />
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default Symptoms;