import React, { useEffect, useMemo } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  Button,
  FormHelperText,
  FormControl
}
from '@mui/material';

import SwipeSelect from '../common/SwipeSelect';
import { withFormik } from 'formik';

import { validInt, calcWeight, calcEnergy, FOOD_TYPE } from './nutrientsData';

const INITIAL_VALUES = { quantity: 1, portionId: '' };
const QUANTITY_LIST = [...Array(10).keys()].map((index) => {
  const quantity = index + 1;
  let text = 'None';
  if (quantity === 1) {
    text = '1 portion';
  }
  else {
    text = `${quantity} portions`;
  }
  return { value: quantity, text };
});

const getSupplementQuantityList = (foodData) => {
  const min = foodData.servingSize.minDailyServings || foodData.servingSize.minQuantity || 1;
  const max = foodData.servingSize.maxDailyServings || foodData.servingSize.maxQuantity || 2;
  const unit = foodData.servingSize.unit;
  const result = [];
  for (let value = min; value <= max; value++) {
    result.push({ value, text: `${value} ${unit}` });
  }
  return result;
};

const FoodDetailsForm = (props) => {
  const {
    visible,
    foodData,
    foodType,
    foodTime,
    portionId,
    quantity,
    handleClose,
    
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm
  } = props;

  const isEdit = useMemo(() => validInt(quantity), [quantity]);

  useEffect(() => {
    const initialValues = { ...INITIAL_VALUES, foodType, foodTime };
    if (portionId) {
      initialValues.portionId = portionId;
    }
    else if (foodData && foodData.portions && foodData.portions.length > 0) {
      initialValues.portionId = foodData.portions[0]._id;
    }

    if (quantity) {
      initialValues.quantity = quantity;
    }
    resetForm({ values: initialValues });
  }, 
  [foodData, foodType, foodTime, portionId, quantity, resetForm]);

  const portions = useMemo(() => 
    (foodData && foodData.portions ? foodData.portions : [])
      .map((portion) => ({ 
        value: portion._id, 
        text: `${portion.description}, ${portion.gramWeight} g` 
      })), 
      [foodData]);
  
  const totalWeight = useMemo(() => {
    return calcWeight(foodData, foodType, values.portionId, values.quantity);
  }, [foodData, foodType, values]);

  const totalEnergy = useMemo(() => {
    return calcEnergy(foodData, totalWeight);
  }, [foodData, totalWeight]);

  return (
    <Dialog open={visible} fullWidth onClose={() => handleClose(null)}>
      <DialogTitle>
        Food Details ({foodType})
        <Divider />
      </DialogTitle>
      
      {foodData && <DialogContent>
        <div className="mb-1">
          <div className="label">Description</div>
          <div>{foodData.description}</div>
        </div>

        {foodData.brandOwner && <div className="mb-1">
          <div className="label">Brand</div>
          <div>{foodData.brandOwner}</div>
        </div>}

        {foodData.ingredients && <div className="mb-1">
          <div className="label">Ingredients</div>
          <div>{foodData.ingredients}</div>
        </div>}

        {foodData.note && <div className="mb-1">
          <div className="label">Note</div>
          <div>{foodData.note}</div>
        </div>}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth className="pb-05"
              error={errors.quantity && touched.quantity}>
              <FormHelperText className="label">
                Quantity
              </FormHelperText>
              <SwipeSelect
                name="quantity"
                data={foodType === FOOD_TYPE.SUPPLEMENT ? getSupplementQuantityList(foodData) : QUANTITY_LIST}
                textField="text"
                dataField="value"
                value={values.quantity}
                onChange={handleChange}
                onBlur={handleBlur}
                height="14rem"
                width="100%"
                slidesPerView={5}
              />
              <FormHelperText>{touched.quantity && errors.quantity}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {foodData.servingSize && <div className="mb-1">
              <div className="label">Serving Size</div>
              {foodType === FOOD_TYPE.BRANDED && 
                <div>{foodData.servingSize} {foodData.servingSizeUnit}</div>
              }
              {foodType === FOOD_TYPE.SUPPLEMENT && <>
                  <div>Min Daily Servings: <b>{foodData.servingSize.minDailyServings}</b></div>
                  <div>Max Daily Servings: <b>{foodData.servingSize.maxDailyServings}</b></div>
                  <div>Servings Unit: <b>{foodData.servingSize.unit}</b></div>
                </>
              }
            </div>}

            {portions && portions.length > 0 && (
              <FormControl variant="standard" fullWidth className="pb-05"
                error={errors.portionId && touched.portionId}>
                <FormHelperText className="label">Portions</FormHelperText>
                <SwipeSelect
                  name="portionId"
                  data={portions}
                  textField="text"
                  dataField="value"
                  value={values.portionId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  height="14rem"
                  width="100%"
                  slidesPerView={5}
                />
                <FormHelperText>{touched.portionId && errors.portionId}</FormHelperText>
              </FormControl>
            )}
          </Grid>
        </Grid>

        <div className="mb-1">
          <div className="label">
            Total Energy / Total Weight
          </div>
          <div>{totalEnergy} kkal / {totalWeight} g</div>
        </div>
      </DialogContent>}
      
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" onClick={handleSubmit}>
          {isEdit ? 'Update Food In Diary' : 'Add Food to Diary'}
        </Button>
        <Button onClick={() => handleClose(null)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FoodDetailsDialog = withFormik({
  mapPropsToValues: () => INITIAL_VALUES,
  validate: (values, props) => {
    const { foodData } = props;
    const errors = {};
    if (foodData.portions) {
      let valid = true;
      if (validInt(values.portionId)) {
        valid = Boolean(foodData.portions.find((portion) => 
          String(portion._id) === String(values.portionId)));
      }
      else {
        valid = false;
      }

      if (!valid) {
        errors.portionId = 'Please Select the Portion Size';
      }
    }
    
    if (!validInt(values.quantity)) {
      errors.quantity = 'Please the Quantity';
    }
    return errors;
  },

  handleSubmit: (values, formik) => {
    const { foodType, foodTime, quantity, portionId } = values;
    const { foodData, handleClose } = formik.props;
    const portion = foodData.portions && validInt(portionId) ?
      foodData.portions.find((portion) => String(portion._id) === String(portionId)) 
      : null;

    const weight = calcWeight(foodData, foodType, portionId, quantity);
    
    handleClose({
      foodData,
      foodType,
      foodTime,
      portion,
      weight,
      quantity: parseInt(quantity),
      energy: calcEnergy(foodData, weight)
    })
  },
})(FoodDetailsForm);

export default FoodDetailsDialog;