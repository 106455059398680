import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Autocomplete,
  Box,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  Select,
  IconButton,
  FormControl,
  MenuItem,
  Popover,
  Typography,
  FormHelperText,
  Tabs,
  Tab,
  InputLabel,
  InputAdornment
}
from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { withFormik } from 'formik';

import { DATE_FORMAT } from '../../constants/common';
import { STORE } from '../../store';
import {
  SEVERITY_DATA,
  DURATION_DATA,
  getInitialValues,
  mapValues,
  saveValues,
  getPeriodData,
  showEndDate,
  validate
}
from './editSymptomData';

import { loadById } from '../../services/symptom';
import { useAppState } from '../../hooks/useAppState';
import { useDialog } from '../../hooks/useDialog';
import SwipeSelect from '../common/SwipeSelect';
import './dialogs.css';

const EditSymptomForm = (props) => {
  const {
    visible,
    extended,

    answerId,
    name,
    description,

    symptomId,
    symptomDate,
    handleClose,

    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = props;

  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [surveyCategories] = useAppState(STORE.areas.survey.category.load);
  const [openSelectSymptomDialog] = useDialog(STORE.areas.survey.category.dialog);

  const categoryOptions = useMemo(() => {
    const categoryOptions = surveyCategories.data
      .filter(c => c.type === 'yesno')
      .map(c => c.name);
    return [...new Set(categoryOptions)];
  }, [surveyCategories]);

  useEffect(() => {
    let symptomDateValue = symptomDate;
    if (!symptomDateValue && !answerId) {
      symptomDateValue = new Date();
    }

    const load = async() => {
      let initialValues;

      if (symptomId) {
        const symptomData = await loadById(symptomId);
        initialValues = mapValues(symptomData);
      }
      else {
        initialValues = getInitialValues(symptomDateValue, answerId, name, description);
      }

      resetForm({ values: initialValues });
    };

    if (visible) {
      load();
    }
  }, [visible, symptomId, symptomDate, answerId, name, description, resetForm]);

  const onAutofillChangeEvent = useCallback((e) => {
    let value = (e.target.tagName.toLowerCase() === 'input' ?
      e.target.value :
      e.target.innerHTML).trim();

    if (surveyCategories.data) {
      const category = surveyCategories.data.find((category) => category.name === value);
      if (category || value.length === 0) {
        handleChange({ ...e, target: {
          name: 'name',
          value
        }});

        setFieldValue('description', '');  
      }
    }
  }, [surveyCategories, handleChange, setFieldValue]);

  const onDescriptionClick = useCallback(async () => {
    if (values.name && values.name.length) {
      const symptomText = await openSelectSymptomDialog({
        category: values.name
      });

      if (symptomText) {
        setFieldValue('description', symptomText);
      }
    } 
  }, [values.name, openSelectSymptomDialog, setFieldValue]);

  const onAutofillBlurEvent = useCallback((e) => {
    let value = e.target.tagName.toLowerCase() === 'input' ?
      e.target.value :
      e.target.innerHTML;

    handleBlur({ ...e, target: {
      name: 'name',
      value
    }});
  }, [handleBlur]);

  const onTabSelect = useCallback((e, newTabIndex) => {
    setTabIndex(newTabIndex);
  }, []);

  const onPeriodChange = useCallback((e) => {
    const period = e.target.value;
    setValues({
      ...values,
      period,
      showEndDate: showEndDate(values.duration, period)
    });
  }, [values, setValues]);

  const onDurationChange = useCallback((e) => {
    const duration = e.target.value;
    const periodData = getPeriodData(e.target.value);
    const periodFound = periodData.find((period) => period.value === values.period);
    setValues({
      ...values,
      duration,
      period: periodFound ? periodFound.value : '',
      periodData
    });
  }, [values, setValues]);

  const onClearStartDate = useCallback(() => {
    setFieldValue('start', null);
  }, [setFieldValue]);

  const handleSeverityInfoClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleSeverityInfoClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <Dialog open={visible} fullWidth onClose={() => handleClose(null)}>
      <DialogTitle>
        {symptomId === null ? 'New Symptom' : 'Edit Symptom Info'}
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '0' }}>
        {extended && (
            <Tabs value={tabIndex} onChange={onTabSelect} className="pb-1">
              <Tab label="Main"></Tab>
              <Tab label="Misc"></Tab>
            </Tabs>
          )
        }

        <Box className={classNames({'d-none': tabIndex !== 0})}>
          <Autocomplete
            fullWidth
            name="name"
            options={categoryOptions}
            value={values.name}
            disableClearable
            onChange={onAutofillChangeEvent}
            onKeyUp={onAutofillChangeEvent}
            onBlur={onAutofillBlurEvent}
            className="pb-05"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={extended ?
                  'Please label your symptom in a few words' :
                  'Please describe your symptom in 1-5 words'}
                error={errors.name && touched.name}
                helperText={touched.name && errors.name}
                onChange={onAutofillChangeEvent}
              />
            )}
          />

          <TextField
            fullWidth
            name="description"
            variant="standard"
            label="Please describe your symptom in detail"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.description && touched.description}
            helperText={touched.description && errors.description}
            className="pb-1"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {(values.name && values.name.length) && (
                  <IconButton
                    onClick={onDescriptionClick}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>)}
              </InputAdornment>,
            }}
          />

          {extended && <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth className="pb-05"
                  error={errors.severity && touched.severity}>
                  <FormHelperText className="label severity-label">
                    <span>
                      Severity
                    </span>
                    <IconButton onClick={handleSeverityInfoClick}>
                      <HelpIcon />
                    </IconButton>
                  </FormHelperText>
                  <SwipeSelect
                    name="severity"
                    data={SEVERITY_DATA}
                    textField="text"
                    dataField="value"
                    value={values.severity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    height="14rem"
                    width="100%"
                  />
                  <FormHelperText>{touched.severity && errors.severity}</FormHelperText>
                </FormControl>
                <Popover
                  open={anchorEl !== null}
                  anchorEl={anchorEl}
                  onClose={handleSeverityInfoClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}>
                  <Typography sx={{ p: 2 }}>Give a grade how strong is the feeling at it worst moment.</Typography>
                </Popover>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth className="pb-05"
                  error={errors.duration && touched.duration}>
                  <FormHelperText className="label">Duration</FormHelperText>
                  <SwipeSelect
                    name="duration"
                    data={DURATION_DATA}
                    textField="text"
                    dataField="value"
                    value={values.duration}
                    onChange={onDurationChange}
                    onBlur={handleBlur}
                    height="14rem"
                    width="100%" />
                  <FormHelperText>{touched.duration && errors.duration}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth className="pb-05"
                  error={errors.period && touched.period}>
                  <FormHelperText className="label">Frequency</FormHelperText>
                  <SwipeSelect
                    name="period"
                    data={values.periodData}
                    textField="text"
                    dataField="value"
                    value={values.period}
                    onChange={onPeriodChange}
                    onBlur={handleBlur}
                    height="14rem"
                    width="100%" />
                  <FormHelperText>{touched.period && errors.period}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  
                    <FormControl variant="standard" fullWidth className="pb-05"
                      error={Boolean(errors.start && touched.start)}>
                      <FormHelperText className="label">
                        When did you first observe this symptom
                      </FormHelperText>

                      <DatePicker
                        name="start"
                        value={values.start}
                        onChange={(momentDate) => {
                          setFieldValue('start', momentDate)
                        }}
                        format={DATE_FORMAT}
                        slotProps={{ textField: {
                          name: "start",
                          variant: 'standard',
                          fullWidth: true,
                          helperText: touched.start && errors.start,
                          error: Boolean(errors.start && touched.start),
                          onBlur: handleBlur
                        }}}
                      />

                      <MobileTimePicker name="start"
                        className={classNames('mt-05', {'d-none': !values.answerId})}
                        value={values.start}
                        onChange={(momentDate) => {
                          setFieldValue('start', momentDate)
                        }}
                        slotProps={{ textField: {
                          name: "start",
                          variant: 'standard',
                          fullWidth: true,
                          helperText: touched.start && errors.start,
                          error: Boolean(errors.start && touched.start),
                          onBlur: handleBlur
                        }}} />

                      <Button fullWidth 
                        onClick={onClearStartDate}
                        className={classNames('mt-05', {'d-none': !values.answerId})}>
                        Clear Date
                      </Button>
                    </FormControl>

                  <FormControl variant="standard" fullWidth
                    error={Boolean(errors.periodEnd && touched.periodEnd)}
                    className={classNames('pb-05', {'d-none': !values.showEndDate})}>
                    <FormHelperText className="label">Symptom End</FormHelperText>
                    <DatePicker
                      name="periodEnd"
                      value={values.periodEnd}
                      onChange={(momentDate) => {
                        setFieldValue('periodEnd', momentDate)
                      }}
                      format={DATE_FORMAT}
                      slotProps={{ textField: {
                        name: "periodEnd",
                        variant: 'standard',
                        fullWidth: true,
                        helperText: touched.periodEnd && errors.periodEnd,
                        error: Boolean(errors.periodEnd && touched.periodEnd),
                        onBlur: handleBlur
                      }}}
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </>}
        </Box>

        <Box className={classNames({'d-none': tabIndex !== 1})}>
          <TextField
            fullWidth
            name="trigger"
            variant="standard"
            label="What do you think triggered this symptom?"
            value={values.trigger}
            onChange={handleChange}
            onBlur={handleBlur}
            className="pb-05"
          />

          <TextField
            fullWidth
            name="managing"
            variant="standard"
            label="What makes this symptom better?"
            value={values.managing}
            onChange={handleChange}
            onBlur={handleBlur}
            className="pb-05"
          />

          <TextField
            fullWidth
            name="misc"
            variant="standard"
            label="What makes this symptom worse?"
            value={values.misc}
            onChange={handleChange}
            onBlur={handleBlur}
            className="pb-05"
          />

          <FormControl variant="standard" fullWidth className="pb-05"
            error={errors.amongRelatives && touched.amongRelatives}>
            <InputLabel id="amongRelativesLabel">Is this symptom present in your relatives?</InputLabel>
            <Select
              labelId="amongRelativesLabel"
              name="amongRelatives"
              value={values.amongRelatives}
              onChange={handleChange}
              onBlur={handleBlur}>
              <MenuItem value="Y">Y</MenuItem>
              <MenuItem value="N">N</MenuItem>
            </Select>
            <FormHelperText>{touched.amongRelatives && errors.amongRelatives}</FormHelperText>
          </FormControl>

          <FormControl variant="standard" fullWidth className="pb-05"
            error={errors.amongHousehold && touched.amongHousehold}>
            <InputLabel id="amongHouseholdLabel">Is this symptom present in unrelated members of your household? (e.g. spouse or roommate)</InputLabel>
            <Select
              labelId="amongHouseholdLabel"
              className="select-text-left"
              name="amongHousehold"
              value={values.amongHousehold}
              onChange={handleChange}
              onBlur={handleBlur}>
              <MenuItem value="Y">Y</MenuItem>
              <MenuItem value="N">N</MenuItem>
            </Select>
            <FormHelperText>{touched.amongHousehold && errors.amongHousehold}</FormHelperText>
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleClose(null)}>
          Cancel
        </Button>

        <Button onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const EditSymptomDialog = withFormik({
  mapPropsToValues: (props) => getInitialValues(),
  validate,
  handleSubmit: (values, formik) => {
    const { symptomId, symptomDate, handleClose } = formik.props;
    saveValues(values, { symptomId, symptomDate, handleClose });
  },
})(EditSymptomForm);

export default EditSymptomDialog;