import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
}
from '@mui/material';

const ResultsList = (props) => {
  const { items, onSelect } = props;
  return (
    <TableContainer component={Paper} className="search-result-paper">
      <Table sx={{ minWidth: '20rem' }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow hover key={item.setid}>
              <TableCell onClick={() => onSelect(item)}>
                <div className="nutrient-title">{item.packager}</div>
                <div className="nutrient-label">{item.name}</div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultsList;