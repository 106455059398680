import axios from 'axios';

export const resolveGene = async (codes) => {
  const url = `${process.env.REACT_APP_API_URL}/genetics/resolve`;
  const response = await axios.post(url, codes);

  return response.data;
};

export const resolveGeneFile = async (fileName) => {
  const url = `${process.env.REACT_APP_API_URL}/genetics/resolveFile?file=${fileName}`;
  const response = await axios.get(url);

  return response.data;
};

export const resolveGeneProfile = async (params) => {
  const { fileName, isFull } = params;
  const url = `${process.env.REACT_APP_API_URL}/genetics/resolveProfile?file=${fileName}&isFull=${isFull}`;
  const response = await axios.get(url);

  return response.status === 201;
};

export const getProgressGeneProfile = async () => {
  const url = `${process.env.REACT_APP_API_URL}/genetics/progressProfile`;
  const response = await axios.get(url);

  return response.data;
};

export const getGeneStats = async (geneCode) => {
  const url = `${process.env.REACT_APP_API_URL}/genetics/geneStats/${geneCode}`;
  const response = await axios.get(url);

  return response.data;
};

const mergeResults = (profiles) => {
  const result = {
    _id: profiles.map((profile) => profile._id),
    name: profiles.map((profile) => profile.name),
    keggs: profiles.length === 1 ? (profiles[0].keggs || null) : null,
    geneMap: profiles.reduce((result, profile) => result.concat(
      profile.geneMap.map((item) => ({...item, _id: profile._id, name: profile.name}))
    ), []),
    geneLinks: profiles.reduce((result, profile) => result.concat(
      profile.geneLinks.map((item) => ({...item, _id: profile._id, name: profile.name}))
    ), [])
  };
  
  return result;
};

export const getResultGeneProfile = async (ids = null) => {
  const isCompose = Boolean(ids);
  const url = isCompose ? 
    `${process.env.REACT_APP_API_URL}/genetics/loadGeneProfile?ids=${ids.join(',')}` :
    `${process.env.REACT_APP_API_URL}/genetics/resultProfile?rnd=${(new Date()).getTime()}`

  const response = await axios.get(url);
  const result = response.data.result ? response.data.result : response.data;
  const keggs = response.data.keggs ? response.data.keggs : null;
  
  return isCompose ? {
    result: mergeResults(result),
    keggs
  } : result;
};

export const loadGeneProfiles = async () => {
  const url = `${process.env.REACT_APP_API_URL}/genetics/loadGeneProfiles`;
  const response = await axios.get(url);

  return response.data;
};

export const saveGeneProfile = async (name) => {
  const url = `${process.env.REACT_APP_API_URL}/genetics/saveGeneProfile`;
  await axios.post(url, { name });
};

export const deleteGeneProfile = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/genetics/deleteGeneProfile/${id}`;
  await axios.delete(url);
};