import React, { useMemo, useEffect, useRef, useState } from 'react';
import searchTextHL from 'search-text-highlight'

import { 
  CircularProgress, 
  Divider, 
  Grid, 
  IconButton,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import { debounce } from '@mui/material/utils';

import { loadDiaryIngredients } from '../../../services/diary';

const IngredientSearch = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    mask: '',
    items: null,
  });
  const [inputValue, setInputValue] = useState('');

  const fetch = useMemo(() => debounce(async (mask, callback) => {
    if (mask && mask.length) {
      try {
        setLoading(true);
        callback(mask);
      }
      finally {
        setLoading(false);
      }
    }
  }, 400), [setLoading]);

  useEffect(() => {
    let active = true;

    fetch(inputValue.trim(), async (mask) => {
      const items = await loadDiaryIngredients(mask); 
      setData({
        mask,
        items
      });
    });
 
    return () => {
      active = false;
    };
  }, [inputValue, fetch, setData]);

  return (
    <>
      <Grid container className="justify-content-center pb-1">
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Start Type Here"
            name="search"
            value={inputValue}
            onChange={(event) => {
              setInputValue(event.target.value);
            }}
            onBlur={() => {}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              // endAdornment: (
              //   <InputAdornment position="end">
              //     {loading && <CircularProgress size="1.5rem" />}

              //     <Divider orientation="vertical" sx={{ height: '2rem', m: '0 0.2rem' }} />
              //     <IconButton
              //       onClick={() => {}}
              //       edge="end"
              //     >
              //       <ManageSearchIcon />
              //     </IconButton>
              //   </InputAdornment>
              // )
            }}
          />
        </Grid>
      </Grid>

      <Divider className="mb-1" />

      <Grid container>
        <Grid item>
          {Boolean(data.items?.length) && (
            <TableContainer component={Paper} fullWidth>
              <Table sx={{ minWidth: '20rem' }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.items.map((item) => (
                    <TableRow key={item.foodId} hover>
                      <TableCell className="nutrient-option">
                        <div className="nutrient-title" 
                          dangerouslySetInnerHTML={{__html: searchTextHL.highlight(item.description, data.mask)} } />
                        <div className="nutrient-label" 
                          dangerouslySetInnerHTML={{__html: searchTextHL.highlight(item.ingredients, data.mask)} } />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )} 
        </Grid>
      </Grid>
    </>
  );
};

export default IngredientSearch;