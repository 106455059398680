// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone p {
  color: #bdbdbd;
}

.icon-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon-item span {
  max-width: 40rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.find-form-buttons {
  display: flex;
  justify-content: space-between;
}

.file-card-header {
  background: var(--style-tile-primary2);
  padding: 0 0.25rem 0 0.5rem !important;
}

.file-card-header span {
  text-align: left;
  color: var(--style-tile-primary1);
}

.file-delete-button {
  color: var(--style-tile-primary1) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/uploadDocs/upload.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,oBAAoB;EACpB,yBAAyB;EACzB,aAAa;EACb,mCAAmC;AACrC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,sCAAsC;EACtC,sCAAsC;AACxC;;AAEA;EACE,gBAAgB;EAChB,iCAAiC;AACnC;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[".dropzone {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  border-width: 2px;\n  border-radius: 2px;\n  border-color: #eeeeee;\n  border-style: dashed;\n  background-color: #fafafa;\n  outline: none;\n  transition: border .24s ease-in-out;\n}\n\n.dropzone p {\n  color: #bdbdbd;\n}\n\n.icon-item {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.icon-item span {\n  max-width: 40rem;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.find-form-buttons {\n  display: flex;\n  justify-content: space-between;\n}\n\n.file-card-header {\n  background: var(--style-tile-primary2);\n  padding: 0 0.25rem 0 0.5rem !important;\n}\n\n.file-card-header span {\n  text-align: left;\n  color: var(--style-tile-primary1);\n}\n\n.file-delete-button {\n  color: var(--style-tile-primary1) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
