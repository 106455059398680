import classNames from 'classnames';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { Button } from 'rsuite';

import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import ArrowUpIcon from '@rsuite/icons/ArrowUp';

const MAX_VISIBLE = 6;

const getArrow = (title, column, sort, onSort) => {
  const icon = column !== sort.column ? null : (
    sort.asc ? <ArrowUpIcon /> : <ArrowDownIcon />
  );
  
  return (
    <Button 
      className="sort-button"
      onClick={() => onSort(column)}
      appearance="link">
      {title}
      {icon}
    </Button>
  )
};

const getItems = (showAll, allItems) => {
  return showAll ? allItems : allItems.slice(0, MAX_VISIBLE);
};

const Genetics = ({ data }) => {
  const { genetics } = data;
  const allItems = useMemo(() => genetics?.geneMap || [], [genetics]);

  const [items, setItems] = useState(getItems(false, allItems));
  const showExpand = allItems.length > MAX_VISIBLE;

  const [allVisible, setAllVisible] = useState(false);
  
  const [sort, setSort] = useState({
    column: 'snippetCode',
    asc: false
  });

  const onExpand = useCallback((showAll) => {
    setItems(getItems(showAll, allItems))
    setAllVisible(showAll);
  }, [allItems, setItems, setAllVisible]);

  const onSort = useCallback((column) => {
    setSort((currentSort) => {
      let asc = true;
      if (currentSort.column === column) {
        asc = !currentSort.asc;
      }

      return {
        column,
        asc
      }
    });
  }, [setSort]);

  useEffect(() => {
    setItems((currentItems) => {
      const newItems = [...allItems].sort(
        (left, right) => {
          if (left[sort.column] === right[sort.column]) {
            return 0;
          }
          if (left[sort.column] > right[sort.column]) {
            return sort.asc ? 1 : -1;
          }
          else {
            return sort.asc ? -1 : 1;
          }
        }
      );
      return getItems(allVisible, newItems);
    });
  }, [allVisible, allItems, sort, setItems]);

  return (
    <div>
      <h2>
        Genetics
      </h2>

      <div className="container">
        <div className="row d-none d-lg-flex">
          <div className="col-lg-3 cell-header">
            {getArrow('Gene Name', 'geneName', sort, onSort)}
          </div>
          <div className="col-lg-2 cell-header">
            {getArrow('SNP', 'snippetCode', sort, onSort)}
          </div>
          <div className="col-lg-2 cell-header">
            {getArrow('Significance', 'significants', sort, onSort)}
          </div>
          <div className="col-lg-2 cell-header">
            {getArrow('Variants', 'variants', sort, onSort)}
          </div>
          <div className="col-lg-1 cell-header">
            {getArrow('Freq, %', 'frequency', sort, onSort)}
          </div>
          <div className="col-lg-1 cell-header">
            {getArrow('CADD', 'cadd', sort, onSort)}
          </div>
          <div className="col-lg-1 cell-header">
            {getArrow('Inherit', 'inheritance', sort, onSort)}
          </div>
        </div>
        
        {items.map((item) => (
          <div key={item.snippetCode} className="row">
            <div className="col-4 d-block d-lg-none cell-header">Gene Name</div>
            <div className="col-8 col-lg-3 cell-panel"><b>{item.geneName}</b></div>

            <div className="col-4 d-block d-lg-none cell-header">SNP</div>
            <div className="col-8 col-lg-2 cell-content">{item.snippetCode}</div>

            <div className="col-4 d-block d-lg-none cell-header">Significance</div>
            <div className="col-8 col-lg-2 cell-content">{item.significants}</div>

            <div className="col-4 d-block d-lg-none cell-header">Variants</div>
            <div className="col-8 col-lg-2 cell-content">{item.variants}</div>

            <div className="col-4 d-block d-lg-none cell-header">Freq, %</div>
            <div className="col-8 col-lg-1 cell-content"><b>{item.frequency}</b></div>

            <div className="col-4 d-block d-lg-none cell-header">CADD</div>
            <div className="col-8 col-lg-1 cell-content"><b>{item.cadd}</b></div>

            <div className="col-4 d-block d-lg-none cell-header">Inherit</div>
            <div className={classNames('col-8 col-lg-1', item.inheritance === 'Homo' ? 'cell-homo' : 'cell-content')}>
              {item.inheritance}
            </div>

            <div className="col-12 row-divider" />
          </div>
        ))}
      </div>

      {showExpand && (
        <Button 
          appearance="default" 
          className="btn-gadget-2 mt-3" 
          onClick={() => onExpand(!allVisible)}>
          {allVisible ? 'Hide all' : 'View all'}
        </Button>
      )}
    </div>
  );
};

export default Genetics;