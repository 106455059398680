import React, { useState, useEffect } from 'react';
import axios from 'axios';

import L from 'leaflet'; // Import Leaflet library
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS

import markerIconImage from '../../images/marker-icon.png';
import markerIconImageShadow from '../../images/marker-shadow.png';

import Layout from '../layout/Layout';

const yourMarkerIcon = L.icon({
  iconUrl: markerIconImage,
  shadowUrl: markerIconImageShadow, // Add the shadow image URL
  // iconSize: ['10px', '12px'], // Set the size of your marker icon
  // shadowSize: ['10px', '12px'], // Set the size of your marker shadow
  // Add any additional options for your marker icon, such as iconAnchor, popupAnchor, etc.
});

const renderMap = (profiles) => {
  // Create map
  const map = L.map('map').setView([39.8283, -98.5795], 4.2); // Example initial coordinates

  // Add tile layer to map (e.g. OpenStreetMap)
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '© OpenStreetMap contributors'
  }).addTo(map);

  // Loop through profiles data and add markers to map based on locations
  profiles.forEach(profile => {
    const { location } = profile;
    const [city, state] = location.split(','); // Parse city and state from location string

    // Use a geocoding service (e.g. OpenStreetMap Nominatim) to get coordinates from city and state
    axios
      .get(`https://nominatim.openstreetmap.org/search?city=${city.trim()}&state=${state.trim()}&country=USA&format=json`)
      .then(response => {
        const { lat, lon } = response.data[0]; // Extract latitude and longitude from response
        // Add marker to map with coordinates from geocoding response, and city and state as popup content
        L.marker([lat, lon],  { icon: yourMarkerIcon })
          .bindPopup(`<b>${city}</b>, ${state.trim()}`) // Trim leading/trailing whitespace from state
          .addTo(map);
      })
      .catch(error => {
        // Handle error
        console.error('Error fetching coordinates:', error);
        // Handle error state or show error message
      });
  });
};

const ProfilesMapPage = () => {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/server/profiles`)
      .then((response) => {
        // Handle successful response
        setProfiles(response.data);
      })
      .catch((error) => {
        // Handle error
        console.error('Error fetching profiles:', error);
        // Handle error state or show error message
      });
  }, []);

  useEffect(() => {
    // Only call renderMap() if profiles data is available
    if (profiles.length > 0) {
      renderMap(profiles);
    }
  }, [profiles]);

  return (
    <Layout>
      <h3>Profiles Map</h3>
      <div id="map" style={{ height: "80vh", width: "100%" }}></div>
    </Layout>
  );
}

export default ProfilesMapPage;
