export const getInitialResult = (category, type) => ({ category, type, answers: [], total: -1, totalText: '', message: '' });

export const getSurveyAnswer = (ask, text, value) => ({ ask, text, value });

export const TYPE_YESNO = 'yesno';
export const TYPE_MULTI = 'multi';
export const DEPRESSION_INVENTORY = 'Depression Inventory';

const calculateYesNo = (result) => {
  result.total = result.answers.reduce((sum, answer) => sum + (answer.value > 0 ? answer.value : 0), 0);
};

const calculateDepressionInventory = (result) => {
  const total = result.answers.reduce((sum, answer) => sum + answer.value, 0);

  let message = 'This ups and downs are considered normal';
  let totalText = 'success';

  if (total > 40) {
    message = 'Extreme depression';
    totalText = 'error';
  }
  else if (total > 30) {
    message = 'Severe depression';
    totalText = 'error';
  }
  else if (total > 20) {
    message = 'Moderate depression';
    totalText = 'warning';
  }
  else if (total > 20) {
    message = 'Borderline clinical depression';
    totalText = 'warning';
  }
  else if (total > 10) {
    message = 'Mild mood disturbance';
    totalText = 'info';
  }

  result.total = total;
  result.totalText = totalText;
  result.message = message;  
};

export const calculateResult = (category, result) => {
  if (category.type === TYPE_YESNO) {
    calculateYesNo(result);
  }
  else if (category.code === DEPRESSION_INVENTORY) {
    calculateDepressionInventory(result);
  }
};