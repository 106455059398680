import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

const HEIGHT = 360;

const FoodPyramid = (props) => {
  const container = useRef();

  const { data } = props;
  const [ width, setWidth ] = useState(0);

  const onResize = useCallback(() => {
    if (container.current) {
      setWidth(container.current.clientWidth);
    }
  }, [setWidth]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
  }, [onResize]);

  const onMouseIn = useCallback((event, group) => {
    const item = document.querySelector(`.pyramid-${group}`);
    if (item) {
      item.classList.add('selected-bg');
    }

    event.target.classList.add('selected');
  }, []);

  const onMouseOut = useCallback((event, group) => {
    const item = document.querySelector(`.pyramid-${group}`);
    if (item) {
      item.classList.remove('selected-bg');
    }

    event.target.classList.remove('selected');
  }, []);

  return (
    <div ref={container} className="pyramid-container" style={{ height: HEIGHT }}>
      {width && data.map((levelRow, index) => {
        return (
          <div 
            key={index} 
            className={classNames('pyramid-row bordered', `h-${data.length}`)}>
            
            {levelRow.map((item) => 
              <div key={item.group} className={`item bordered pyramid-${item.group}`} />
            )}
          </div>
        );
      })}

      <div className="pyramid-edge" />

      <div className="pyramid-selection">
        {width && data.map((levelRow, index) => {
          return (
            <div 
              key={index} 
              className={classNames('pyramid-row', `h-${data.length}`)}>
              
              {levelRow.map((item, index) => 
                <div key={item.group} className={classNames('item', index > 0 && 'right w-50')} 
                  onMouseOver={(event) => onMouseIn(event, item.group)}
                  onMouseOut={(event) => onMouseOut(event, item.group)} 
                >
                  <div className="title">
                    {item.name}
                    <hr />
                    <span>{`Serving(s): ${item.quantity}`}</span>
                  </div>
                </div>
              )}
            </div>
          )
        })}  
      </div>
    </div>
  );
};

export default FoodPyramid;